import Http from '../utils/Http';
import * as notificationActions from '../store/notification/action';
import Transformer from '../utils/Transformer';
import * as constant from '../../config/constant';


// Notification unread count api
export function getUnreadCountNotification() {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/notification/get-unread-notification-count`)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            dispatch(notificationActions.getUnreadCountNotification(data));
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}


// Notification mark read count api
export function markReadNotificationCount() {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/notification/mark-read-notification-count`)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            dispatch(notificationActions.markReadNotificationCount(data));
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// Notification mark individual api
export function markReadNotification(id: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/notification/mark-read-notification`, id)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            dispatch(notificationActions.markReadNotification(data));
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

//get all notifications with pagination
export function getAllNotifications(data: any, flag: any = null) {
  console.log('Profile Access Token: ');
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    // console.log('Profile Access Token: ', constant.default.accessToken);
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/notification`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);


          if (res.status === 200) {

            if (constant.default.platform == 'web') {
              dispatch(notificationActions.getAllNotification(data));
              return resolve(res);
            } else {

              return resolve(res);

            }
          }
          // dispatch(notificationActions.getAllNotification(data));




          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}