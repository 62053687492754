/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import {Dispatch} from 'react';
import {RootState} from '../reducers';
import {
  AUTH_CHECK,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_USER,
  AUTH_INITIAL,
  GET_USER,
  USER_NAME,
  SET_RTL,
  USER_TYPE,
  AUTH_LOGIN_SUCCESS,
  AUTH_INITIAL_TYPES,
  CURRENTUSER_CHANGE,
  IS_LOCAL_STORAGE_SET_SUCCESS
} from './action-types';
import {MiddleWare, PayLoad} from '../../interfaces/auth';

export function authInitialState(middleware: MiddleWare): AUTH_INITIAL_TYPES {
  return {
    type: AUTH_INITIAL,
    middleware,
  };
}

export function initial(middleware: MiddleWare) {
  return (
    dispatch: Dispatch<AUTH_INITIAL_TYPES>,
    getState: () => RootState,
  ) => {
    dispatch(authInitialState(middleware));
  };
}

export function authLogin(payload: PayLoad): AUTH_INITIAL_TYPES {
  return {
    type: AUTH_LOGIN,
    payload,
  };
}

export function authLoginInitial(payload: PayLoad) {
  return (
    dispatch: Dispatch<AUTH_INITIAL_TYPES>,
    getState: () => RootState,
  ) => {
    dispatch(authLogin(payload));
  };
}

export function updateRTL(payload: PayLoad): AUTH_INITIAL_TYPES {
  return {
    type: SET_RTL,
    payload,
  };
}

export function setUserType(payload: PayLoad): AUTH_INITIAL_TYPES {
  return {
    type: USER_TYPE,
    payload,
  };
}

export function authLoginSuccess(payload: PayLoad): AUTH_INITIAL_TYPES {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload,
  };
}

export function authLoginSuccessIntial(payload: PayLoad) {
  return (
    dispatch: Dispatch<AUTH_INITIAL_TYPES>,
    getState: () => RootState,
  ) => {
    dispatch(authLoginSuccess(payload));
  };
}
export function isLocalStorageSet(payload: PayLoad): AUTH_INITIAL_TYPES {
  return {
    type: IS_LOCAL_STORAGE_SET_SUCCESS,
    payload,
  };
}

export function isLocalStorageSetIntial(payload: PayLoad) {
  return (
    dispatch: Dispatch<AUTH_INITIAL_TYPES>,
    getState: () => RootState,
  ) => {
    dispatch(isLocalStorageSet(payload));
  };
}

export function authLogout(payload: PayLoad): AUTH_INITIAL_TYPES {
  return {
    type: AUTH_LOGOUT,
    payload,
  };
}

export function authLogoutIntial(payload: PayLoad) {
  return (
    dispatch: Dispatch<AUTH_INITIAL_TYPES>,
    getState: () => RootState,
  ) => {
    dispatch(authLogout(payload));
  };
}

export function currentUserChange(payload: PayLoad): AUTH_INITIAL_TYPES {
  return {
    type: CURRENTUSER_CHANGE,
    payload,
  };
}

export function currentUserChangeIntial(payload: PayLoad) {
  return (
    dispatch: Dispatch<AUTH_INITIAL_TYPES>,
    getState: () => RootState,
  ) => {
    dispatch(currentUserChange(payload));
  };
}