export default {
    platform: 'web',
    isRTL: 0,
    accessToken:'',
    tempLang:0
}
 
export const alertColor = {
    "PRIMARY":"primary",
    "SUCCESS":"success",
    "SECONDARY":"secondary",
    "DARK":"dark",
    "DANGER":"danger",
    "WARNING":"warning",
    "LIGHT":"light",
    "INFO":"info",
}
