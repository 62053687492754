import {
  APPOINTMENT_TYPES
  } from './action-types';
  
  import { MiddleWare } from '../../interfaces/auth';
  
  const initialState: MiddleWare [] = [];
  
  const reducer = (state = initialState,action: APPOINTMENT_TYPES) : MiddleWare[] => {
    console.log("action.type",action.type);
    switch (action.type) {
      case "GET_APPOINTMENT_HISTORY":
        return getAppointmentHistory(state,action.payload)
      case "SHOW_ALERT":
        return showAlert(state,action.payload)
      case "DOCTOR_NOTES":
        return doctorNotes(state,action.payload)
      case "DOCTOR_PRESCRIPTION":
        return prescription(state,action.payload)
      case "DOCTOR_LAB_REQUEST":
        return labRequest(state,action.payload)
      case "DOCTOR_IMAGE_REQUEST":
        return imageRequest(state,action.payload)
      case "REPORTS_IMAGES":
        return reportsImages(state,action.payload)
      case "CHECK_ROUTE":
        return checkRoute(state,action.payload)
      default:
        return state;
    }
  };
  
  export default reducer;
  
  function getAppointmentHistory(state:any, payload:any) {
    return {
      ...state,
      appointmentHistory:payload,
    }
    
  }
  function showAlert(state:any, payload:any) {
    console.log(payload,'payload')
    return {
      ...state,
      alert:payload,
    }
    
  }
  function doctorNotes(state:any, payload:any) {
    return {
      ...state,
      doctorNotes:payload,
    }
    
  }
  function prescription(state:any, payload:any) {
    return {
      ...state,
      prescription:payload,
    }
    
  }
  function labRequest(state:any, payload:any) {
    return {
      ...state,
      labRequest:payload,
    }
    
  }
  function imageRequest(state:any, payload:any) {
    return {
      ...state,
      imageRequest:payload,
    }
  }

  function reportsImages(state:any, payload:any) {
    return {
      ...state,
      reportsImages:payload,
    }
    
  }

  function checkRoute(state:any, payload:any) {
    return {
      ...state,
      checkRoute:payload,
    }
    
  }
  