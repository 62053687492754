import {
    HOWITWORKS_TYPES
  } from './action-types';
  
  import { MiddleWare } from '../../interfaces/auth';
  
  const initialState: MiddleWare [] = [];
  
  const reducer = (state = initialState,action: HOWITWORKS_TYPES) : MiddleWare[] => {
    switch (action.type) {
      case "HOWITWORKS":
        return howitWorks(state,action.payload)
      case "HOWITWORKS_LOADING":
        return howitWorksLoading(state)
      default:
        return state;
    }
  };
  
  export default reducer;
  
  function howitWorks(state:any, payload:any) {
    return {
      ...state,
      howitWorks:payload,
      loading:false,
    };
  }
  function howitWorksLoading(state:any) {
    return {
      ...state,
      loading:true,
    };
  }