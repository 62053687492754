import Http from '../utils/Http';
import * as appointmentActions from '../store/appointments/action';
import Transformer from '../utils/Transformer';
import * as constant from '../../config/constant';

// get patient appointment history
export function getPatientAppointmentHistory(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-patient-past-appointment`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            dispatch(appointmentActions.getPatientAppointmentHistory(data));
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// get patient appointment history with notes
export function getAppointmentNotes(id: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-appointment-notes`, id)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(appointmentActions.getPatientAppointmentHistory(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// get patient appointment history with prescription
export function getAppointmentPrescription(id: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-appointment-prescription`, id)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(appointmentActions.getPatientAppointmentHistory(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// get patient appointment history with lab request
export function getAppointmentLabRequest(id: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-appointment-labrequest`, id)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(appointmentActions.getPatientAppointmentHistory(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// get patient appointment history with image request
export function getAppointmentImageRequest(id: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-appointment-imagerequest`, id)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(appointmentActions.getPatientAppointmentHistory(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// get patient appointment notes with pagination
export function getMyAppointmentNotes(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-my-appointment-notes`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            dispatch(appointmentActions.myNotes(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// get patient appointment prescription with prescription
export function getMyAppointmentPrescription(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-my-appointment-prescription`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            dispatch(appointmentActions.myPrescription(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// get patient appointment lab request with paginaiton
export function getMyAppointmentLabRequest(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-my-appointment-labrequest`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            dispatch(appointmentActions.myLabRequest(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// get patient appointment imagerequest with pagination
export function getMyAppointmentImageRequest(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-my-appointment-imagerequest`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            dispatch(appointmentActions.myImageRequest(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// get reports and images with pagination
export function getMyReportsImages(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-my-reports-image`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            dispatch(appointmentActions.myReportsImages(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}


// get all prescription of single appointment
export function getAllPrescriptionOfSingleAppointment(id: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-all-prescriptions-of-single-appointment`, id)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(appointmentActions.getPatientAppointmentHistory(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}
// get all prescription of single appointment
export function getAllLabRequestOfSingleAppointment(id: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-all-labrequest-of-single-appointment`, id)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(appointmentActions.getPatientAppointmentHistory(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}
// get all image request of single appointment
export function getAllImageRequestOfSingleAppointment(id: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-all-imagerequest-of-single-appointment`, id)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(appointmentActions.getPatientAppointmentHistory(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}