import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {IMAGE_URL} from '../lib/config/constant';

export const PrivateRoutes = ({ component: Component, ...rest }) => (
    <>
        <Route {...rest} render={props => (
            localStorage.getItem('access_token')
                ?
                <Suspense fallback={
                    <div id="loading">
                        <div className="loader-content position-relative">
                            <img src={IMAGE_URL + 'static_images/stethoscope.gif'} className="loading-image" />
                        </div>
                    </div>}>
                    <Component {...props} />
                </Suspense>
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
    </>
)
export default PrivateRoutes;