import {VIDEO_INITIAL_TYPES} from './action-types';

import {MiddleWare} from '../../interfaces/auth';
// import { authLoginSuccess, authLogout } from './actions';

const initialState: MiddleWare[] = [];

const reducer = (
  state = initialState,
  action: VIDEO_INITIAL_TYPES,
): MiddleWare[] => {
  switch (action.type) {
    case 'IS_VIDEO_REF_SET_SUCCESS':
      return videoRefSetSuccess(state, action.payload);
      break;
    default:
      return state;
  }
};

function videoRefSetSuccess(state: any, payload: any) {
  return {
    ...state,
    videoRefSetSuccess: payload,
  };
}
export default reducer;
