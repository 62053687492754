import React, { Suspense, lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {IMAGE_URL} from '../lib/config/constant';

export const NewPublicRoutes = ({ component: Component, ...rest }) => (
    <>
        <Route {...rest} render={props => {
            if(props.location.search == '?mobile=true&lang=en' || props.location.search == '?mobile=true&lang=ar'){
                return (
                    <Component {...props} />
                )
            } else {
            return (
            <Suspense fallback={
                <div id="loading">
                    <div className="loader-content position-relative">
                        <img src={IMAGE_URL + 'static_images/stethoscope.gif'} className="loading-image" />
                    </div>
                </div>}>
                <Component {...props} />
            </Suspense>
            )
            }
        }} />
    </>
)
export default NewPublicRoutes;