import { combineReducers } from 'redux';

import auth from './auth/reducer';
import cms from './cms/reducer';
import faq from './faq/reducer';
import howItWorks from './howitworks/reducer';
import intl from './intl/reducer';
import global from './global/reducer';
import doctor from './doctor/reducer';
import searchdoctor from './searchdoctor/reducer';
import patient from './patient/reducer';
import dropdown from './dropdown/reducer';
import contact from './contact/reducer';
import career from './careers/reducer';
import blog from './blogs/reducer';
import media from './media_center/reducer';
import notificationList from './notification/reducer';
import appointments from './appointments/reducer';
import video from './video/reducer';

const rootReducers = combineReducers({ auth, cms, faq, howItWorks, intl, global, doctor, searchdoctor, dropdown, contact, career, blog, media, patient, notificationList, appointments, video });

export type RootState = ReturnType<typeof rootReducers>

export default rootReducers;
