/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */
import {Dispatch} from 'react';
import {RootState} from '../reducers';
import {
  VIDEO_INITIAL_TYPES,
  IS_VIDEO_REF_SET_SUCCESS
} from './action-types';
import {MiddleWare, PayLoad} from '../../interfaces/auth';

export function videoRefSet(payload: PayLoad): VIDEO_INITIAL_TYPES {
  return {
    type: IS_VIDEO_REF_SET_SUCCESS,
    payload,
  };
}

export function videoRefSetIntial(payload: PayLoad) {
  return (
    dispatch: Dispatch<VIDEO_INITIAL_TYPES>,
    getState: () => RootState,
  ) => {
    dispatch(videoRefSet(payload));
  };
}