import {
    DOCTOR_TYPES
  } from './action-types';
  
  import { MiddleWare } from '../../interfaces/auth';
  
  const initialState: MiddleWare [] = [];
  
  const reducer = (state = initialState,action: DOCTOR_TYPES) : MiddleWare[] => {
    switch (action.type) {
      case "DOCTOR_PROFILE_FORM":
        return doctorProfileForm(state,action.payload)
      case "DOCTOR_VIEW_PROFILE":
        return doctorViewProfile(state,action.payload)
      case "CHANGE_DOCTOR_VIEW_PROFILE":
        return changeDoctorViewProfile(state,action.payload)
      case "CHANGE_FAVOURITE_DATA":
        return changeFavoriteData(state,action.payload)
      default:
        return state;
    }
  };
  
  export default reducer;
  
  function doctorProfileForm(state:any, payload:any) {
    return {
      ...state,
      doctorProfile:payload,
      loading:false,
    };
  }

  function doctorViewProfile(state:any, payload:any) {
    return {
      ...state,
      doctorViewProfile:payload,
      loading:false,
    };
  }

  function changeDoctorViewProfile(state:any, payload:any){
    return {
      ...state,
      doctorViewProfile:payload,
    }
  }
  function changeFavoriteData(state:any, payload:any){
    return {
      ...state,
      doctorViewProfile:payload,
    }
  }
  