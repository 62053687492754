import {MiddleWare, PayLoad} from '../../interfaces/auth';

export const LANGUAGE = 'LANGUAGE';
export const ALERT_MESSAGE_SUCCESS = 'ALERT_MESSAGE_SUCCESS';
export const ALERT_MESSAGE_ERROR = 'ALERT_MESSAGE_ERROR';
export const ALERT_MESSAGE_CLEAR = 'ALERT_MESSAGE_CLEAR';

interface LANGUAGE_ACTIONS {
  type: typeof LANGUAGE;
  payload: PayLoad;
}

interface ALERT_MESSAGE_SUCESS_ACTIONS {
  type: typeof ALERT_MESSAGE_SUCCESS;
  payload: PayLoad;
}

interface ALERT_MESSAGE_ERROR_ACTIONS {
  type: typeof ALERT_MESSAGE_ERROR;
  payload: PayLoad;
}
interface ALERT_MESSAGE_CLEAR_ACTIONS {
  type: typeof ALERT_MESSAGE_CLEAR;
  payload: PayLoad;
}

export type GLOBAL_TYPES =
  | LANGUAGE_ACTIONS
  | ALERT_MESSAGE_SUCESS_ACTIONS
  | ALERT_MESSAGE_ERROR_ACTIONS
  | ALERT_MESSAGE_CLEAR_ACTIONS;
