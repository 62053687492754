import {AUTH_INITIAL_TYPES} from './action-types';

import {MiddleWare} from '../../interfaces/auth';
// import { authLoginSuccess, authLogout } from './actions';

const initialState: MiddleWare[] = [];

const reducer = (
  state = initialState,
  action: AUTH_INITIAL_TYPES,
): MiddleWare[] => {
  switch (action.type) {
    case 'AUTH_INITIAL':
      return [...state];
      break;
    case 'AUTH_LOGIN_SUCCESS':
      return fnAuthLoginSuccess(state, action.payload);
      break;
    case 'SET_RTL':
      return updateRTL(state, action.payload);
      break;
    case 'USER_TYPE':
      return setUserType(state, action.payload);
      break;
    case 'AUTH_LOGOUT':
      return authLogout(state, action.payload);
      break;
    case 'CURRENTUSER_CHANGE':
      return currentUserChange(state, action.payload);
      break;
    case 'IS_LOCAL_STORAGE_SET_SUCCESS':
      return isLocalStorageSetSuccess(state, action.payload);
      break;
    default:
      return state;
  }
};
//export const getAuth = (state:any) => state;

function fnAuthLoginSuccess(state: any, payload: any) {
  return {
    ...state,
    currentUser: payload.data.user,
    token: payload.data.token,
    isAuthenticated: true,
  };
}

function isLocalStorageSetSuccess(state: any, payload: any) {
  return {
    ...state,
    isLocalStorageSetSuccess: true,
  };
}

function authLogout(state: any, payload: any) {
  return {
    ...state,
    currentUser: [],
    token: '',
    isAuthenticated: false,
    isLocalStorageSetSuccess:false,
  };
}

function updateRTL(state: any, payload: any) {
  return {
    ...state,
    isRTL: payload,
  };
}

function setUserType(state: any, payload: any) {
  return {
    ...state,
    userType: payload,
  };
}

function currentUserChange(state: any, payload: any) {
  return {
    ...state,
    currentUser: payload,
  };
}

export default reducer;
// function cmsPage(state:any, payload:any) {
//   return {
//     ...state,
//     cmsContent: payload,
//   };
// }

// export const getAuth = state => state.auth.isAuthenticated;

// export default reducer;
