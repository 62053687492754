import Http from '../utils/Http'
import * as careerAction from '../store/careers/action';
import Transformer from '../utils/Transformer';
import * as constant from '../../config/constant';

/**
 * cms
 *
 * @param credentials
 * @returns {function(*)}
 */
export function getDisputeViewApi(disputeId: any) {
    if (constant.default.platform == 'web') {
      Http.defaults.headers.common['Accept-Language'] =
        localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
      if (
        localStorage.getItem('access_token') &&
        localStorage.getItem('access_token') != null &&
        localStorage.getItem('access_token') != undefined
      ) {
        Http.defaults.headers.common['auth'] = localStorage.getItem(
          'access_token',
        );
      }
    } else if (constant.default.platform == 'Mobile') {
      Http.defaults.headers.common['Accept-Language'] =
        constant.default.isRTL == 0 ? 'en' : 'ar';
      Http.defaults.headers.common['auth'] = constant.default.accessToken;
    }
    return (dispatch: any) =>
      new Promise((resolve, reject) => {
        Http.get(`api/dispute/view/${disputeId}`)
          // Http.get(`api/users/get-patient-profile`,data)
          .then(res => {
            console.log('dispute response: ', res.data);
            const data = Transformer.fetch(res.data);
            return resolve(data);
            // if (res.status === 200) {
            // }
            // return resolve()
          })
          .catch(err => {
            const statusCode = err.response.status;
            const responseData = err.response.data;
            const data = {
              error: err.response.data,
              statusCode,
            };
  
            // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
            //     data.error = err.response.data;
            // }
            return reject(data);
          });
      });
}

  export function getDisputeChatApi(data: any) {
    if (constant.default.platform == 'web') {
      Http.defaults.headers.common['Accept-Language'] =
        localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
      if (
        localStorage.getItem('access_token') &&
        localStorage.getItem('access_token') != null &&
        localStorage.getItem('access_token') != undefined
      ) {
        Http.defaults.headers.common['auth'] = localStorage.getItem(
          'access_token',
        );
      }
    } else if (constant.default.platform == 'Mobile') {
      Http.defaults.headers.common['Accept-Language'] =
        constant.default.isRTL == 0 ? 'en' : 'ar';
      Http.defaults.headers.common['auth'] = constant.default.accessToken;
    }
    return (dispatch: any) =>
      new Promise((resolve, reject) => {
        Http.post(`api/chats/dispute`, data)
          // Http.get(`api/users/get-patient-profile`,data)
          .then(res => {
            console.log('dispute response: ', res.data);
            const data = Transformer.fetch(res.data);
            return resolve(data);
            // if (res.status === 200) {
            // }
            // return resolve()
          })
          .catch(err => {
            const statusCode = err.response.status;
            const responseData = err.response.data;
            const data = {
              error: err.response.data,
              statusCode,
            };
  
            // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
            //     data.error = err.response.data;
            // }
            return reject(data);
          });
      });
  }

  export function addChatMessageApi(data: any) {
    if (constant.default.platform == 'web') {
      Http.defaults.headers.common['Accept-Language'] =
        localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
      if (
        localStorage.getItem('access_token') &&
        localStorage.getItem('access_token') != null &&
        localStorage.getItem('access_token') != undefined
      ) {
        Http.defaults.headers.common['auth'] = localStorage.getItem(
          'access_token',
        );
      }
    } else if (constant.default.platform == 'Mobile') {
      Http.defaults.headers.common['Accept-Language'] =
        constant.default.isRTL == 0 ? 'en' : 'ar';
      Http.defaults.headers.common['auth'] = constant.default.accessToken;
    }
    Http.defaults.headers.common['Content-Type'] = 'multipart/form-data';
  
    return (dispatch: any) =>
      new Promise((resolve, reject) => {
        Http.post(`api/chats`, data)
          .then(res => {
            const data = Transformer.fetch(res.data);
            return resolve(data);
          })
          .catch(err => {
            const statusCode = err.response.status;
            const responseData = err.response.data;
            const data = {
              error: err.response.data,
              statusCode,
            };
            return reject(data);
          });
      });
  }

  export function addAppointmentChatMessageApi(data: any) {
    if (constant.default.platform == 'web') {
      Http.defaults.headers.common['Accept-Language'] =
        localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
      if (
        localStorage.getItem('access_token') &&
        localStorage.getItem('access_token') != null &&
        localStorage.getItem('access_token') != undefined
      ) {
        Http.defaults.headers.common['auth'] = localStorage.getItem(
          'access_token',
        );
      }
    } else if (constant.default.platform == 'Mobile') {
      Http.defaults.headers.common['Accept-Language'] =
        constant.default.isRTL == 0 ? 'en' : 'ar';
      Http.defaults.headers.common['auth'] = constant.default.accessToken;
    }
    Http.defaults.headers.common['Content-Type'] = 'multipart/form-data';
  
    return (dispatch: any) =>
      new Promise((resolve, reject) => {
        Http.post(`api/chats/appointmentchat`, data)
          .then(res => {
            const data = Transformer.fetch(res.data);
            return resolve(data);
          })
          .catch(err => {
            const statusCode = err.response.status;
            const responseData = err.response.data;
            const data = {
              error: err.response.data,
              statusCode,
            };
            return reject(data);
          });
      });
  }

  export function getDisputeRefund(data: any) {
    if (constant.default.platform == 'web') {
      Http.defaults.headers.common['Accept-Language'] =
        localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
      if (
        localStorage.getItem('access_token') &&
        localStorage.getItem('access_token') != null &&
        localStorage.getItem('access_token') != undefined
      ) {
        Http.defaults.headers.common['auth'] = localStorage.getItem(
          'access_token',
        );
      }
    } else if (constant.default.platform == 'Mobile') {
      Http.defaults.headers.common['Accept-Language'] =
        constant.default.isRTL == 0 ? 'en' : 'ar';
      Http.defaults.headers.common['auth'] = constant.default.accessToken;
    }
    return (dispatch: any) =>
      new Promise((resolve, reject) => {
        Http.post(`api/dispute/refund`, data)
          // Http.get(`api/users/get-patient-profile`,data)
          .then(res => {
            console.log('dispute response: ', res.data);
            const data = Transformer.fetch(res.data);
            return resolve(data);
            // if (res.status === 200) {
            // }
            // return resolve()
          })
          .catch(err => {
            const statusCode = err.response.status;
            const responseData = err.response.data;
            const data = {
              error: err.response.data,
              statusCode,
            };
  
            // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
            //     data.error = err.response.data;
            // }
            return reject(data);
          });
      });
}