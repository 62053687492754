import { Dispatch } from 'react';
import { RootState } from '../reducers';
import {
  NOTIFICATION_TYPES,NOTIFICATION_UNREAD_COUNT,GET_ALL_NOTIFICATIONS, MARK_READ_NOTIFICATION_COUNT,MARK_READ_NOTIFICATION, GET_ALL_NOTIFICATION,CLEAR_NOTIFICATION
} from './action-types';
import { PayLoad } from '../../interfaces/auth';


export function getUnreadCountNotification(payload: PayLoad): NOTIFICATION_TYPES {
  return {
    type: NOTIFICATION_UNREAD_COUNT,
    payload,
  };
}

export function getUnreadCountNotificationIntial(payload: PayLoad) {
  return (dispatch: Dispatch<NOTIFICATION_TYPES>, getState: () => RootState) => {
    dispatch(getUnreadCountNotification(payload));
  }
}

export function markReadNotificationCount(payload: PayLoad): NOTIFICATION_TYPES {
  return {
    type: MARK_READ_NOTIFICATION_COUNT,
    payload,
  };
}

export function markReadNotificationCountIntial(payload: PayLoad) {
  return (dispatch: Dispatch<NOTIFICATION_TYPES>, getState: () => RootState) => {
    dispatch(markReadNotificationCount(payload));
  }
}

export function markReadNotification(payload: PayLoad): NOTIFICATION_TYPES {
  return {
    type: MARK_READ_NOTIFICATION,
    payload,
  };
}

export function markReadNotificationIntial(payload: PayLoad) {
  return (dispatch: Dispatch<NOTIFICATION_TYPES>, getState: () => RootState) => {
    dispatch(markReadNotificationCount(payload));
  }
}

export function getAllNotification(payload: PayLoad): NOTIFICATION_TYPES {
  return {
    type: GET_ALL_NOTIFICATION,
    payload,
  };
}

export function getAllNotificationIntial(payload: PayLoad) {
  return (dispatch: Dispatch<NOTIFICATION_TYPES>, getState: () => RootState) => {
    dispatch(getAllNotification(payload));
  }
}

export function clearNotification(){
  return {
    type: CLEAR_NOTIFICATION,
  };
}

export function getAllNotifications(payload: PayLoad): NOTIFICATION_TYPES {
  return {
    type: GET_ALL_NOTIFICATIONS,
    payload,
  };
}

export function getAllNotificationsIntial(payload: PayLoad) {
  return (dispatch: Dispatch<NOTIFICATION_TYPES>, getState: () => RootState) => {
    dispatch(getAllNotifications(payload));
  }
}