import {
    PATIENT_TYPES
  } from './action-types';
  
  import { MiddleWare } from '../../interfaces/auth';
  
  const initialState: MiddleWare [] = [];
  
  const reducer = (state = initialState,action: PATIENT_TYPES) : MiddleWare[] => {
    console.log("action.type",action.type);
    switch (action.type) {
      case "PATIENT_PROFILE_FORM":
        return patientProfileForm(state,action.payload)
        case "PATIENT_VIEW_PROFILE":
        return patientViewProfile(state,action.payload)
      default:
        return state;
    }
  };
  
  export default reducer;
  
  function patientProfileForm(state:any, payload:any) {
    return {
      ...state,
      patientProfile:payload,
      loading:false,
    };
  }

  function patientViewProfile(state:any, payload:any) {
    console.log("in reducer");
    console.log("avani===",payload);
    return {
      ...state,
      patientViewProfile:payload,
      loading:false,
    };
  }
  