import React, { useState,useEffect } from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { alertMessageClear } from '../lib/store/global/action';
import { bindActionCreators } from 'redux'; 

const AlertMessage = (props:any) => {
  const [visible, setVisible] = useState(true);
//   const [count, setCount] = useState(0);
//   const [countInTimeout, setCountInTimeout] = useState(0);


  useEffect(() => {
    setTimeout(() => {
      props.alertMessageClear() // count is 0 here
    }, 5000);
  }, []);


  const onDismiss = () => {
        // setVisible(false)
        props.alertMessageClear()
  }

  
  return (
      <>
      <div>
            {(props.alert != '') ?(
            <Alert color={props.alert.color} isOpen={visible} toggle={onDismiss}>
                {props.alert.message}
            </Alert>
            ) : null
            }
        </div>   
        </>   
  );
}

const mapStateToProps = (state:any) => {
    return {
        alertMessage:state.global.alertMessage,
    }
}

const mapStateToDispatch  = (dispatch:any) => {
    return bindActionCreators({alertMessageClear}, dispatch);
}

export default connect(mapStateToProps,mapStateToDispatch)(AlertMessage);