import React from "react";
// import {CircleArrow as ScrollUpButton} from "react-scroll-up-button"; //Add this line Here
import ScrollUpButton from "react-scroll-up-button"; //Add this line Here

import '../css/styles.css';

export default class ScrollButton extends React.Component {
  render() {
    return (
      <div>
        {/* <ScrollUpButton ShowAtPosition={185} className="scroll_up"/>

        <ScrollUpButton
          ClassName="scroll_up AnyClassForTransition"
        >
          <button id="myBtn" title="Go to top" style={{display: 'block'}}><i className="flaticon-chevron "></i></button>
        </ScrollUpButton> */}
        <ScrollUpButton
          ContainerClassName="scroll_up"
          TransitionClassName="AnyClassForTransition"
        />
         {/* <button id="myBtn" title="Go to top" style={{display: 'block'}}><i className="flaticon-chevron "></i></button> */}
        {/* </ScrollUpButton> */}
      </div>
    );
  }
}