import {
    CONTACT_TYPES
  } from './action-types';
  
  import { MiddleWare } from '../../interfaces/auth';
  
  const initialState: MiddleWare [] = [];
  
  const reducer = (state = initialState,action: CONTACT_TYPES) : MiddleWare[] => {
    switch (action.type) {
      case "CONTACT_US":
        return contactUs(state,action.payload)
      default:
        return state;
    }
  };
  
  export default reducer;
  
  function contactUs(state:any, payload:any) {
    return {
      ...state,
      contactUs:payload,
    };
  }
  