import Http from '../utils/Http'
import * as cmsActions from '../store/cms/action'
import Transformer from '../utils/Transformer'

/**
 * cms
 *
 * @param credentials
 * @returns {function(*)}
 */
export function cmsPageRender(id) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(cmsActions.cmsPagesPending());
            Http.get(`api/frontend/cms/${id}`)
                .then(res => {
                    const data = Transformer.fetch(res.data)
                    if (res.status === 200) {
                        if (id == 1) {
                            dispatch(cmsActions.headerfooter(data))
                        } else if (id == 2) {
                            dispatch(cmsActions.home(data))
                        } else if (id == 3) {
                            dispatch(cmsActions.signInSignUp(data))
                        } else if (id == 4) {
                            dispatch(cmsActions.forgotResend(data))
                        } else if (id == 5) {
                            dispatch(cmsActions.aboutUs(data))
                        } else if (id == 6) {
                            dispatch(cmsActions.termsConditions(data))
                        } else if (id == 7) {
                            dispatch(cmsActions.privacyPolicy(data))
                        } else if (id == 8) {
                            dispatch(cmsActions.rulesRegulations(data))
                        } else if (id == 9) {
                            dispatch(cmsActions.contact_us(data))
                        }
                    }
                    return resolve(data)
                })
                .catch((err) => {
                    console.log('err:',err);
                    
                    const statusCode = err.response.status;
                    const data = {
                        error: null,
                        statusCode,
                    };

                    if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
                        data.error = err.response.data;
                    }
                    return reject(data);
                })
        })
    )
}
