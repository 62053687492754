import {
    DROPDOWN_TYPES
  } from './action-types';
  
  import { MiddleWare } from '../../interfaces/auth';
  
  const initialState: MiddleWare [] = [];
  
  const reducer = (state = initialState,action: DROPDOWN_TYPES) : MiddleWare[] => {
    switch (action.type) {
      case "COUNTRY":
        return country(state,action.payload)
      case "COUNTRY_CODE":
        return countryCode(state,action.payload)
      case "LANGUAGES":
        return languages(state,action.payload)
      case "SPECIALIZATION":
        return specialization(state,action.payload)
      case "SUB_SPECIALIZATION":
        return subSpecialization(state,action.payload)
      case "NATIONALITY":
          return nationality(state,action.payload)
      case "ALLERGIES":
        return allergies(state,action.payload)
      case "MEDICINES":
        return medicines(state,action.payload)
      case "DISEASES":
        return diseases(state,action.payload)
      default:
        return state;
    }
  };
  
  export default reducer;
  
  function country(state:any, payload:any) {
    return {
      ...state,
      country:payload,
    };
  }
  function countryCode(state:any, payload:any) {
    return {
      ...state,
      countryCode:payload,
    };
  }
  function languages(state:any, payload:any) {
    return {
      ...state,
      languages:payload,
    };
  }
  function specialization(state:any, payload:any) {
    return {
      ...state,
      specialization:payload,
    };
  }
  function subSpecialization(state:any, payload:any) {
    return {
      ...state,
      subSpecialization:payload,
    };
  }
  function nationality(state:any, payload:any) {
    return {
      ...state,
      nationality:payload,
    };
  }
  function medicines(state:any, payload:any) {
    return {
      ...state,
      medicines:payload,
    };
  }
  function diseases(state:any, payload:any) {
    return {
      ...state,
      diseases:payload,
    };
  }
  function allergies(state:any, payload:any) {
    return {
      ...state,
      allergies:payload,
    };
  }
  
  // export const getAuth = state => state.auth.isAuthenticated;
  
  // export default reducer;
  