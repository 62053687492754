import { Dispatch } from 'react';
import { RootState } from '../reducers';
import {
  DOCTOR_PROFILE_FORM,DOCTOR_VIEW_PROFILE,DOCTOR_TYPES,CHANGE_INSTANT_AVAILABILITY,CHANGE_DOCTOR_VIEW_PROFILE,CHANGE_FAVOURITE_DATA,SUBSCRIBE_NOTIFICATION_AVAILABILITY
} from './action-types';
import { MiddleWare, PayLoad } from '../../interfaces/auth';


export function doctorProfileForm(payload:PayLoad) : DOCTOR_TYPES {
    return {
      type: DOCTOR_PROFILE_FORM,
      payload,
    };
  }
  
  export function doctorProfileFormIntial(payload:PayLoad) {
    return (dispatch:Dispatch<DOCTOR_TYPES>, getState: () => RootState) => {
        dispatch(doctorProfileForm(payload));
      }
  }
  /**
   * 
   * Doctor view profile
   */

export function doctorViewProfile(payload:PayLoad) : DOCTOR_TYPES {
    return {
      type: DOCTOR_VIEW_PROFILE,
      payload,
    };
  }
  
  export function doctorViewProfileIntial(payload:PayLoad) {
    return (dispatch:Dispatch<DOCTOR_TYPES>, getState: () => RootState) => {
        dispatch(doctorViewProfile(payload));
      }
  }

export function changeInstantAvailability(payload:PayLoad) : DOCTOR_TYPES {
    return {
      type: CHANGE_INSTANT_AVAILABILITY,
      payload,
    };
  }
  
  export function changeInstantAvailabilityIntial(payload:PayLoad) {
    return (dispatch:Dispatch<DOCTOR_TYPES>, getState: () => RootState) => {
        dispatch(changeInstantAvailability(payload));
      }
  }

  export function changeDoctorViewProfile(payload:PayLoad) : DOCTOR_TYPES {
    return {
      type: CHANGE_DOCTOR_VIEW_PROFILE,
      payload,
    };
  }
  
  export function changeDoctorViewProfileIntial(payload:PayLoad) {
    return (dispatch:Dispatch<DOCTOR_TYPES>, getState: () => RootState) => {
        dispatch(changeDoctorViewProfile(payload));
      }
  }
  export function changeFavoriteData(payload:PayLoad) : DOCTOR_TYPES {
    return {
      type: CHANGE_FAVOURITE_DATA,
      payload,
    };
  }
  
  export function changeFavoriteDataIntial(payload:PayLoad) {
    return (dispatch:Dispatch<DOCTOR_TYPES>, getState: () => RootState) => {
        dispatch(changeFavoriteData(payload));
      }
  }

  export function subscribeAvailabilityNotification(payload:PayLoad) : DOCTOR_TYPES {
    return {
      type: SUBSCRIBE_NOTIFICATION_AVAILABILITY,
      payload,
    };
  }
  
  export function subscribeAvailabilityNotificationIntial(payload:PayLoad) {
    return (dispatch:Dispatch<DOCTOR_TYPES>, getState: () => RootState) => {
        dispatch(subscribeAvailabilityNotification(payload));
      }
  }