// import {callbackify} from 'util';
// export const API_URL = 'https://prelive.doctoray.com/';
// export const API_URL = 'https://staging.doctoray.com/';
export const API_URL = 'https://doctoray.com/';
// export const API_URL = 'http://192.168.42.193:4000';

export const rpp = 10;

// export const IMAGE_URL = 'https://doctoray-s3.s3-eu-west-1.amazonaws.com/prelive/images/';
// export const IMAGE_URL = 'https://doctoray-s3.s3-eu-west-1.amazonaws.com/staging/images/';
export const IMAGE_URL = 'https://doctoray-s3.s3-eu-west-1.amazonaws.com/live/images/';

export const timeZone = 'Asia/Kuwait';
// export const timeZone = 'Asia/Kolkata';

export const reportRpp = 12;
export const chatMaxChar = 1000;

export const cmsRequestParams = {
  header_footer: 1,
  home: 2,
  signin_signup: 3,
  forgot_resend: 4,
  about_us: 5,
  terms_conditons: 6,
  privacy_policy: 7,
  rules_regulations: 8,
  contact_us: 9,
};

export const time = {
  AM: 'صباحا',
  PM: 'مساء',
};

export const days = {
  Mon: 'الإثنين',
  Tue: 'الثلاثاء',
  Wed: 'الأربعاء',
  Thu: 'الخميس',
  Fri: 'الجمعة',
  Sat: 'جلس',
  Sun: 'الأحد',
};


export const constantData = {
  profilePicSize: 2097152, //2 MB
  profilePicExtension: [
    'image/gif',
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/jpg',
  ],
  patientReportSize: 5242880, //5 MB
  patientReportExtension: ['jpeg', 'pjpeg', 'png', 'jpg', 'pdf', 'doc', 'docx'],

  disputeAttachementFileSIze: 20 * 1024 * 1024,

  disputeAttachementExtenion: [
    'webm',
    'mpg',
    'mp2',
    'mpeg',
    'mpe',
    'mpv',
    'mp4',
    'm4v',
    'avi',
    'wmv',
    'mp3',
    'ogg',
    '3gp',
    'aac',
    'wav',
    'jpg',
    'jpeg',
    'png',
  ],

  dropDownRPP: 50,
  profileImageUrl: IMAGE_URL + 'documents/',
  chatImageUrl: IMAGE_URL + 'chats/',
  saticimages: IMAGE_URL + 'static_images/',
  disputeImageUrl: IMAGE_URL + 'dispute/',
};
//MARK: latest function keep this function in new push
export function addSigns(type: any) {
  switch (type) {
    case 1:
      return '+';
      break;
    case 2:
      return '-';
      break;
    case 3:
      return '-';
      break;
    case 4:
      return '+';
      break;
    case 6:
      return '+';
      break;
    case 7:
      return '+';
      break;
    case 8:
      return '+';
      break;
    case 9:
      return '+';
      break;
    case 10:
      return '+';
      break;
    case 11:
      return '-';
      break;
    case 12:
      return '+';
      break;
    case 13:
      return '-';
      break;
  }
}

export var apiKey = '';
export var apiToken = '';
export var sessionId = '';
