import {
  UPDATE_LANGUAGE,
  UPDATE_LANGUAGE_INITIAL_ACTION,
  LANGUAGE_INITIAL_TYPES,
} from './action-types';
import * as constant from '../../../config/constant';
//import AsyncStorage from '@react-native-community/async-storage';
const AsyncStorage: any = require('../../abstractComponents/libfile');

const setLanguage = (language: string) => {
  let messages = {};
  switch (language) {
    case 'ar':
      messages = Object.assign(messages, require(`../../i18n/ar.json`));
      break;
    default:
    case 'en':
      messages = Object.assign(messages, require(`../../i18n/en.json`));
      break;
  }
  return messages;
};

var language = '';
if (constant.default.platform == 'web') {
  language = localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
} else {
  /**
   * set async storage
   */
  AsyncStorage.default.default.getItem('language').then((value: any) => {
    if (value != null) {
      language = value == 'ar' ? 'ar' : 'en';
    }
  });
}

const initialState = {
  locale: language,
  messages: setLanguage(language),
};

const reducer = (state = initialState, action: LANGUAGE_INITIAL_TYPES) => {
  if (action === undefined) return state;
  switch (action.type) {
    case 'UPDATE_LANGUAGE':
      return {
        locale: action.language,
        messages: setLanguage(action.language),
      };
      break;
    default:
      return state;
  }
};

export default reducer;
