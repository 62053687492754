import {
    BLOG_TYPES
  } from './action-types';
  
  import { MiddleWare } from '../../interfaces/auth';
  
  const initialState: MiddleWare [] = [];
  
  const reducer = (state = initialState,action: BLOG_TYPES) : MiddleWare[] => {
    switch (action.type) {
      case "BLOG_LISTING":
        return blogListing(state,action.payload)
      case "BLOG_DETAIL":
        return blogDetail(state,action.payload)
      default:
        return state;
    }
  };
  
  export default reducer;
  
  function blogListing(state:any, payload:any) {
    return {
      ...state,
      blogListing:payload,
    };
  }
  function blogDetail(state:any, payload:any) {
    return {
      ...state,
      blogDetail:payload,
    };
  }
  