import React from 'react';
import Header from '../../global/Header';
import Footer from '../../global/Footer';
import ScrollButton from '../../global/ScrollButton';
import { connect } from 'react-redux';

const PublicLayout = (props:any) => {

    const {
        children
    } = props;
    // if(props.loading){
    //     return(
    //         <h2>Loading.....</h2>
    //     )
    // }
    return (
        <>
            {/* <Header {...props}/> */}
            <ScrollButton />
            {children}
            {/* <Footer /> */}
        </>
    );
};

const mapStateToProps = (state:any) => {
    return  {
        loading:state.cms.loading,
    }
}

export default connect(mapStateToProps)(PublicLayout);