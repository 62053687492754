import React,{Component} from 'react';
import '../css/doctoray-header.css';
import '../css/doctoray-custom.css';
import AppStore from '../images/app-store.png';
import PlayStore from '../images/google-play.png';
import { BrowserRouter as Router, Route, Link , Redirect,withRouter } from "react-router-dom";
import { cmsRequestParams , IMAGE_URL } from '../lib/config/constant';
import { connect } from 'react-redux';
import { cmsPageRender } from '../lib/apis/cmsPage';
import { socialLinksRender } from '../lib/apis/social';

class Footer extends Component<any,any>{
    constructor(props:any){
        super(props);
        this.state = {
        param:cmsRequestParams.header_footer,
        content:'',
        language:localStorage.getItem('language'),
        }
    }

        componentDidMount(){
            this.props.dispatch(cmsPageRender(this.state.param)).then((response: any) => {
            })
            .catch((error:any, statusCode:any, message:any, e:any) => {
            })
            this.props.dispatch(socialLinksRender()).then((response: any) => {
            })
            .catch((error:any, statusCode:any, message:any, e:any) => {
            })
        }

        componentDidUpdate(prevProps:any){
            if(prevProps.cmsContent == undefined && this.props.cmsContent != undefined && this.props.cmsContent != prevProps.cmsContent){
            const content = this.props.cmsContent.result.contents
                this.setState({
                    content:JSON.parse(content),
                })
            }
        }

        testIfValidURL(str:any) {
            const pattern = new RegExp('^https?:\\/\\/' + // protocol
              '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
              '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
              '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
              '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
              '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
          
            return !!pattern.test(str);
          }
    render(){
        return(
            <div>
                <div id="footer" className="footer bg-color-blue text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <ul className="footer-menu d-inline-block pb-3 w-100">
                                {this.props.currentUser !== undefined && this.props.currentUser.type == 2 ? (
                                    <>
                                           <li><Link to="/photo-gallery" className="text-white text-capitalize font-light">{this.props.language == 'en' ? this.state.content.menu_title3_english : this.state.content.menu_title3_arabic}</Link></li>
                                            <li><Link className="text-white text-capitalize font-light" to="/blog">{this.props.language  == 'en' ? this.state.content.menu_title6_english : this.state.content.menu_title6_arabic}</Link></li>
                                            <li><Link to="/faq" className="text-white text-capitalize font-light">{this.props.language  == 'en' ? this.state.content.menu_title7_english : this.state.content.menu_title7_arabic}</Link></li>
                                            <li><Link to="/rules-regulations" className="text-white text-capitalize font-light">{this.props.language  == 'en' ? this.state.content.menu_title8_english : this.state.content.menu_title8_arabic}</Link></li>
                                            <li><Link to="/careers" className="text-white text-capitalize font-light">{this.props.language  == 'en' ? this.state.content.menu_title9_english : this.state.content.menu_title9_arabic}</Link></li>
                                            <li><Link to="/sitemap" className="text-white text-capitalize font-light" href="#">{this.props.language  == 'en' ? this.state.content.menu_title10_english : this.state.content.menu_title10_arabic}</Link></li>
                                        </> 
                                    ) :
                                    (
                                        <>
                                            <li> <Link to="/about-us" className="text-white text-capitalize font-light">{this.props.language  == 'en' ? this.state.content.menu_title5_english : this.state.content.menu_title5_arabic}</Link></li>
                                            <li><Link className="text-white text-capitalize font-light" to="/blog">{this.props.language  == 'en' ? this.state.content.menu_title6_english : this.state.content.menu_title6_arabic}</Link></li>
                                            <li><Link to="/faq" className="text-white text-capitalize font-light">{this.props.language  == 'en' ? this.state.content.menu_title7_english : this.state.content.menu_title7_arabic}</Link></li>
                                            <li><Link to="/rules-regulations" className="text-white text-capitalize font-light">{this.props.language  == 'en' ? this.state.content.menu_title8_english : this.state.content.menu_title8_arabic}</Link></li>
                                            <li><Link to="/careers" className="text-white text-capitalize font-light">{this.props.language  == 'en' ? this.state.content.menu_title9_english : this.state.content.menu_title9_arabic}</Link></li>
                                            <li><Link to="/sitemap" className="text-white text-capitalize font-light" href="#">{this.props.language  == 'en' ? this.state.content.menu_title10_english : this.state.content.menu_title10_arabic}</Link></li>
                                        </>
                                    ) }
                                </ul>
                                <div className="social-media mx-auto d-inline-block">
                                    <ul className="social-media">
                                        {this.props.socialMediaLinks != undefined && (this.props.socialMediaLinks.result.map((data:any,i:any) => {
                                            const result = this.testIfValidURL(data.link);
                                            var link;
                                            if(result == false){
                                                link = 'https://'+''+data.link
                                            } else {
                                                link = data.link
                                            }
                                            if(data.image != ""){
                                                return (
                                                    <li key={i}><a href={link} target="_blank" title={data.type} className="text-white"><img src={IMAGE_URL + 'social/' + data.image} className="d-inline-block"/></a></li>
                                                )
                                            }
                                        }))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="inline-div d-flex justify-content-end app-store-images">
                                    <a target="_blank" href="https://apps.apple.com/us/app/doctoray/id1535807265" style={{cursor:'pointer'}}>
                                        <img src={IMAGE_URL + 'static_images/app-store.png'} /></a>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.doctoray.patient" style={{cursor:'pointer'}}>
                                        <img src={IMAGE_URL + 'static_images/google-play.png'} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright text-center">
                        <div className="d-md-flex d-lg-flex justify-content-center align-items-center float-none">
                            <p className="pr-2 text-white text-capitalize font-light m-0">{this.props.language  == 'en' ? this.state.content.menu_title11_english : this.state.content.menu_title11_arabic}</p>
                            <ul className="copyright-menu">
                                <li><Link to="/privacy-policy" className="text-white text-capitalize font-light">{this.props.language  == 'en' ? this.state.content.menu_title12_english : this.state.content.menu_title12_arabic}</Link>
                                </li>
                                <li><Link to="/terms-conditions" className="text-white text-capitalize font-light">{this.props.language  == 'en' ? this.state.content.menu_title13_english : this.state.content.menu_title13_arabic}
                                </Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        cmsContent : state.cms.headerfooter,
        socialMediaLinks : state.cms.socialMedia,
        language:state.global.setLanguage,
        currentUser: state.auth.currentUser,
    }
 }
 
 export default connect(mapStateToProps)(withRouter(Footer));

