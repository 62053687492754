import {GLOBAL_TYPES} from './action-types';

import {MiddleWare} from '../../interfaces/auth';

const initialState: MiddleWare[] = [];

const reducer = (state = initialState, action: GLOBAL_TYPES): MiddleWare[] => {
  switch (action.type) {
    case 'LANGUAGE':
      return setLanguage(state, action.payload);
    case 'ALERT_MESSAGE_SUCCESS':
      return alertMessageSuccess(state, action.payload);
    case 'ALERT_MESSAGE_ERROR':
      return alertMessageError(state, action.payload);
    case 'ALERT_MESSAGE_CLEAR':
      return alertMessageClear(state);
    default:
      return state;
  }
};

export default reducer;

function setLanguage(state: any, payload: any) {
  return {
    ...state,
    setLanguage: payload,
    loading: false,
  };
}
function alertMessageSuccess(state: any, payload: any) {
  return {
    ...state,
    alertMessage: payload,
    close: false,
  };
}
function alertMessageError(state: any, payload: any) {
  return {
    ...state,
    alertMessage: payload,
    close: false,
  };
}
function alertMessageClear(state: any) {
  return {
    ...state,
    alertMessage: null,
    close: true,
  };
}
