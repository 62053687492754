import React, {Component} from 'react';
import ReactDOM from "react-dom";
import {connect} from 'react-redux';
import {OTSession, OTPublisher, OTStreams, OTSubscriber} from 'opentok-react';
import {showAlert} from '../../lib/store/appointments/action';
import {constantData, rpp, IMAGE_URL,chatMaxChar } from '../../lib/config/constant';
import $ from 'jquery';
import { notesSchema,prescriptionSchema,labSchema,imageSchema } from '../../lib/validations/schema/patient_appointmentSchema';
import {addAppointmentNotes,addAppointmentPrescription,addAppointmentLabRequest,addAppointmentImageRequest} from '../../lib/apis/appointmentManagement';
import {
  GetVideoSessionRoom,
  GetStreamsVideoSessionRoom,
  StartArchiveVideoSession,
  StopArchiveVideoSession,
  ViewArchiveVideoSession,
  getAppointmentChatApi,
} from '../../lib/apis/VideoTokbox';
import 'opentok-solutions-css';
import {
  checkappointmentroom,
  AppointmentChangeStatus,
  bookAppointmentApi,
  bookAppointmentQueueApi,
  CancelAppointmentQueue,
} from '../../lib/apis/bookappointments';
import moment from 'moment'
import { getPatientAppointmentHistory,getAppointmentNotes,getAppointmentPrescription,getAppointmentLabRequest,getAppointmentImageRequest } from '../../lib/apis/appointmentHistory';
import * as constant from '../../config/constant';
import { getUnreadCountNotification, markReadNotificationCount, markReadNotification, getAllNotifications } from '../apis/notification'
import * as authActions from '../store/auth/actions';
import PerfectScrollbar from "react-perfect-scrollbar";
import { addChatMessageApi, addAppointmentChatMessageApi } from '../apis/dispute';

const OT = require('@opentok/client');
let socket: any = {};
// let orgConsultationTypes: any = [];
// const socket = socketIOClient(constant.API_URL);

let otCore: any;
let textChat: any;
const AccCore = require('opentok-accelerator-core');
const TextChatAccPack = require('opentok-text-chat');
let missedCallTimer:any = null;
const otCoreOptions = {
  credentials: {
    apiKey: '',
    sessionId: '',
    token: '',
  },
};
const textChatOptions = {
  session: {},
  sender: {
    id: 'myCustomIdentifier',
    alias: 'David',
  },
  limitCharacterMessage: 160,
  controlsContainer: '#feedControls',
  textChatContainer: '#chatContainer',
  alwaysOpen: true,
};

let otPublisher: any;
let timer: any;
let timerReverse: any;
let sessionTimer: any;

export default class AbstractVideoChat extends Component<any, any> {
  upload: any;
  chatArea: any = React.createRef();
  newMessage: any;
  constructor(props: any) {
    
    super(props);
    this.state = {
      current_time: null,
      isModalOpen: false,
      content: '',
      Appointment: {},
      Appointment_ID: null,
      AppointmentRoom: '',
      VideoSession: null,
      VideoSessionStrams: [],
      language: localStorage.getItem('language'),
      publishVideo: true,
      publishAudio: true,
      audioSource: true,
      videoSource: true,
      ConsultationTypes: [1, 2, 3],
      RunTimeConsultationTypes: [],
      orgConsultationTypes: [],
      isReceivedCall: false,
      PartnerUser: null,
      publisher: null,
      isReadytoJoincall: false,
      mandatoryAlert: false,
      Appointmentalert: false,
      notEnoughMoneyalert: false,
      appointmentrequestmessage: '',
      isRecording: false,
      ArchiveVideo: null,
      isBusyonanothercall: false,
      isDeclineCall: false,
      TokboxCurrentUser: {},
      TokboxPartnerUser: {},
      PartnerName: null,
      isDirectCall: false,
      isMissedCall: true,
      showAudioIcon: true,
      showVideoIcon: true,
      receive_message_socket: false,
      appointmentQueueId: 0,
      displayWindowName:'chat',//notes//prescription//lab//image
      // isViewRecording: false,
      responseMessage:'',
      responseMessageFrm:'',
      showErrors:[],//
      touched: {
          title:false,
          assessment:false,
          plan:false,
          prescription_title:false,
          drug_name:false,
          dose:false,
          frequency:false,
          duration:false,
          specimen:false,
          test_name:false,
          indication:false,
          body_part:false,
          imaging_modality:false,
          image_indication:false,
      },
      title:"",
      assessment:"",
      plan:"",
      prescription_title:"",
      drug_name:"",
      dose:"",
      frequency:"",
      duration:"",
      specimen:"",
      test_name:"",
      indication:"",
      body_part:"",
      imaging_modality:"",
      image_indication:"",
      isLoadingDisplay:false,
      isLoadingNotes:false,
      notesResponse:null,
      prescriptionResponse:null,
      labResponse:null,
      imageResponse:null,
      resDisputeChatList: [],
      chatCurrentPages: 1,
      pagelimit: 10,
      chatTotalPages: 1,
      chatTotalRecord: 0,
      showChatTypeError:false,
      showChatTypeMessage:"",
      showattachmentError:false,
      showattachmentMessage:"",
    };
    this.upload = React.createRef(); 
    this.newMessage = React.createRef(); 
    // this.chatArea = React.createRef();
    otPublisher = React.createRef();
    this.CheckPreviousSession = this.CheckPreviousSession.bind(this);
    this.SettimerAppointments = this.SettimerAppointments.bind(this);
    this.SetOverDueTimer = this.SetOverDueTimer.bind(this);
    this.CheckandGetAppointment = this.CheckandGetAppointment.bind(this);
    this.GetVideoChatRoom = this.GetVideoChatRoom.bind(this);
    this.GetStreamsVideoChatRoom = this.GetStreamsVideoChatRoom.bind(this);
    this.startCall = this.startCall.bind(this);
    this.JoinCall = this.JoinCall.bind(this);
    this.ReceiveCall = this.ReceiveCall.bind(this);
    this.DeclineCall = this.DeclineCall.bind(this);
    this.DisconnectCall = this.DisconnectCall.bind(this);
    this.ToggleAudio = this.ToggleAudio.bind(this);
    this.ToggleVideo = this.ToggleVideo.bind(this);
    this.ToggleRecording = this.ToggleRecording.bind(this);
    this.ViewRecording = this.ViewRecording.bind(this);
    this.StartRecording = this.StartRecording.bind(this);
    this.StopRecording = this.StopRecording.bind(this);
    this.getAppointmentChatAPI = this.getAppointmentChatAPI.bind(this);
  }

  resetForm = () => {
    let state = {
        alert:false,
        erroralert:false,
        alertTitle:"",
        alertDescription:"",
        responseMessage:'',
        responseMessageFrm:'',
        showErrors:[],//
        // publishVideo: true,
        // publishAudio: true,
        // audioSource: true,
        // videoSource: true,
        // showAudioIcon: true,
        // showVideoIcon: true,
        // displayWindowName:'chat',
        touched: {
            title:false,
            assessment:false,
            plan:false,
            prescription_title:false,
            drug_name:false,
            dose:false,
            frequency:false,
            duration:false,
            specimen:false,
            test_name:false,
            indication:false,
            body_part:false,
            imaging_modality:false,
            image_indication:false,
        },
        title:"",
        assessment:"",
        plan:"",
        prescription_title:"",
        drug_name:"",
        dose:"",
        frequency:"",
        duration:"",
        specimen:"",
        test_name:"",
        indication:"",
        body_part:"",
        imaging_modality:"",
        image_indication:"",
        isTyping: false
    }
    this.setState(state)
}

resetVonageControll = () => {
  let state = {
      publishVideo: true,
      publishAudio: true,
      audioSource: true,
      videoSource: true,
      showAudioIcon: true,
      showVideoIcon: true,
      displayWindowName:'chat',
  }
  this.setState(state)
}

scrollToBottom = () => {
  if (this.state.VideoSession && this.state.ConsultationTypes.includes(1) && this.state.displayWindowName == 'chat') {
    console.log('KKK this.chatArea:',this.chatArea);
    console.log('this.chatArea.current:',this.chatArea.current);
    if(this.chatArea.current != null){
      const chatContainer: any = ReactDOM.findDOMNode(this.chatArea);
      console.log('KKK chatContainer:',chatContainer);
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
    
  }
}

async onScrollUp() {
  if ((this.state.chatCurrentPages) < this.state.chatTotalPages) {
      await this.setState({ chatCurrentPages: this.state.chatCurrentPages + 1 });
      await this.getAppointmentChatAPI(this.state.Appointment_ID);
  }
}

handleChange = (event:any) => {
  this.setState({
      [event.target.name]: event.target.value
  },()=>{
      this.getValidationFieldObj();
  })
}

handleBlur = (field: any) => {
  this.setState({
    touched: {...this.state.touched, [field]: true},
  });
};

shouldMarkError = (field: any) => {
  const hasError =
      typeof this.state.showErrors == 'object' &&
      this.state.showErrors.hasOwnProperty(field)
      ? true
      : false;
  const shouldShow = this.state.touched[field];

  return hasError ? shouldShow : false;
};

getAppointmentChatAPI = async (appointmentId: any) => {

  if (this.state.currentPages == 1) {
      this.setState({ isLoadingDisplay: true, isShowBottomLoader: true });
  }

  let reqData: any = {
      page: this.state.chatCurrentPages,
      pagelimit: this.state.pagelimit,
      appointment_id: appointmentId
  };

  await this.props
      .dispatch(getAppointmentChatApi(reqData))
      .then((response: any) => {
          

          if (response.status == 200) {
              this.setState(
                  {
                      chatTotalPages: response.data.totalPages,
                      chatTotalRecord: response.data.totalRecord,
                      chatCurrentPages: response.data.currentPages,
                      isShowBottomLoader: false
                  },
                  async () => {

                      let rowData = response.data.data;
                      if (rowData && rowData.length > 0) {
                          rowData = rowData.sort(function (a: any, b: any) { return a.id - b.id });
                      }
                      let chatsList = this.state.resDisputeChatList;
                      let newChatsList = [];
                      if (chatsList.length > 0) {
                          newChatsList = [...rowData, ...chatsList];
                          
                      } else {
                          newChatsList = rowData;
                      }

                      await this.setState({ resDisputeChatList: newChatsList });

                      if (this.state.resDisputeChatList.length != this.state.chatTotalRecord) {
                          this.setState({ loadMore: true });
                      }
                  },
              );
          } else {
              this.setState({
                  isAlertShow: true,
                  responseMessage: response.data.message,
              });
          }
          this.setState({ isLoadingDisplay: false });
          // window.scrollTo(0, 0);
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
          
          this.setState({ isLoadingDisplay: false });
          this.setState({
              isAlertShow: true,
              responseMessage: error.error.message,
          });
      });
};

convertEnglishToArabicTime = (time:any) => {
  //
  if(time.includes("AM")){
      time = time.replace("AM","صباحا")
  } else{
      time = time.replace("PM","مساء")
  }
  return time;
}

convertEnglishToArabicDay = (day:any) => {
  if(day.includes("Monday")){
      day = day.replace("Monday","الإثنين")
  } else if(day.includes("Tuesday")) {
      day = day.replace("Tuesday","الثلاثاء")
  } else if(day.includes("Wednesday")) {
      day = day.replace("Wednesday","الأربعاء")
  } else if(day.includes("Thursday")) {
      day = day.replace("Thursday","الخميس")
  } else if(day.includes("Friday")) {
      day = day.replace("Friday","يوم الجمعة")
  } else if(day.includes("Saturday")) {
      day = day.replace("Saturday","يوم السبت")
  } else{
      day = day.replace("Sunday","الأحد")
  }

  if(day.includes("Jan")){
      day = day.replace("Jan","يناير")
  } else if(day.includes("Feb")) {
      day = day.replace("Feb","فبراير")
  } else if(day.includes("Mar")) {
      day = day.replace("Mar","مارس")
  } else if(day.includes("Apr")) {
      day = day.replace("Apr","أبريل")
  } else if(day.includes("May")) {
      day = day.replace("May","مايو")
  } else if(day.includes("Jun")) {
      day = day.replace("Jun","يونيو")
  } else if(day.includes("Jul")) {
      day = day.replace("Jul","يوليو")
  } else if(day.includes("Aug")) {
      day = day.replace("Aug","أغسطس")
  } else if(day.includes("Sep")) {
      day = day.replace("Sep","سبتمبر")
  } else if(day.includes("Oct")) {
      day = day.replace("Oct","أكتوبر")
  } else if(day.includes("Nov")) {
      day = day.replace("Nov","نوفمبر")
  } else if(day.includes("Dec")) {
      day = day.replace("Dec","ديسمبر")
  }

  return day;
}

async sendMessage() {

  if (!this.state.message || this.state.message.trim().length <= 0) {
      return;
  }
  if(!this.state.message && this.state.message.trim().length > chatMaxChar){
    let maxChar = this.props.intl['validat.maxcharacter'];
    maxChar = maxChar.replace('{#limit}',chatMaxChar);
    this.setState({
        showChatTypeError:true,
        showChatTypeMessage:maxChar,
    });
  }else{

    this.setState({
      isLoadingDisplay:true,
      showChatTypeError:false,
      showChatTypeMessage:'',
    })

    let fd = new FormData();
    fd.append('file', this.state.attachment ? this.state.attachment : '');
    fd.append('message', this.state.message);
    fd.append('message_type', '1');
    fd.append('sender_id', this.props.currentUser ? this.props.currentUser.id : 1);
    fd.append('appointment_id', this.state.Appointment_ID);
    await this.props
    .dispatch(addAppointmentChatMessageApi(fd))
    .then((response: any) => {

        if (response.status == 200) {
            let sendMessageData: any = response.data;
            socket.emit("sendappointmentmessage", {
                id: sendMessageData.id,
                appointment_id: sendMessageData.appointment_id,
                appointmentroom: this.state.AppointmentRoom,
                sender_id: sendMessageData.sender_id,
                message: sendMessageData.message,
                message_type: sendMessageData.message_type,
                sending_time: sendMessageData.sending_time,
                sender_pic: this.props.currentUser.profile_pic,
                sender_name:this.getNameAsPerLanguage(this.props.currentUser.title,this.props.currentUser.englishName,this.props.currentUser.arabicName ),
                event: 'send_message'
            });
            this.setState({ message: '',attachment:null });

            let chatsList = this.state.resDisputeChatList;

            let newChatsList = [...chatsList, response.data];
            this.setState({ resDisputeChatList: newChatsList });            
            this.scrollToBottom();
        } else {
            this.setState({
                isAlertShow: true,
                responseMessage: response.data.message,
            });
        }
        this.setState({ isLoadingDisplay: false });
        window.scrollTo(0, 0);
    })
    .catch((error: any, statusCode: any, message: any, e: any) => {
        console.log('error data===', error);
        this.setState({ isLoadingDisplay: false });
        this.setState({
            isAlertShow: true,
            responseMessage: error.error.message,
        });
    });
  }
}

// On File change
onFileChange = (event: any) => {
    var permitted = constantData.disputeAttachementExtenion;
    if (event.target.files && event.target.files[0]) {
        var file = event.target.files[0];
        var ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
        if ($.inArray(ext, permitted) < 0) {
            this.setState({
                showattachmentError:true,
                showattachmentMessage:this.props.intl['select_valid_attachment'],
            })
        }else{
            if(file.size > constantData.disputeAttachementFileSIze){
                this.setState({
                    showattachmentError:true,
                    showattachmentMessage:this.props.intl['max_attachment_validation'],
                })
            } else{
                this.setState({
                    showattachmentError:false,
                    showattachmentMessage:"",
                })
                this.setState({ attachment: event.target.files[0] });
                this.setState({message : file.name});
            }
        }
    }
}

cancelAttachment = () => {
  this.setState({
      attachment:null,
      message:"",
  })
}

getValidationFieldObj = () => {
  if(this.state.displayWindowName == 'notes'){
      this.validateField({
          title:this.state.title,
          assessment:this.state.assessment,
          plan:this.state.plan,
      })
  } else if(this.state.displayWindowName == 'prescription'){
      this.validateField({
          prescription_title:this.state.prescription_title,
          drug_name:this.state.drug_name,
          dose:this.state.dose,
          duration:this.state.duration,
          frequency:this.state.frequency
      })
  } else if(this.state.displayWindowName == 'lab'){
      this.validateField({
          specimen:this.state.specimen,
          test_name:this.state.test_name,
          indication:this.state.indication,
      })
  } else {
      this.validateField({
          body_part:this.state.body_part,
          imaging_modality:this.state.imaging_modality,
          image_indication:this.state.image_indication,
      })
  }
}

async validateField(validateFields:object){
  var {intl} = this.props;  
  let _self = this;
  const error:any = [];
  let schema:any = '';
  //////////
  
  if(this.state.displayWindowName == 'notes'){
      schema = notesSchema;
  } else if(this.state.displayWindowName == 'prescription'){
      schema = prescriptionSchema;
  } else if(this.state.displayWindowName == 'lab'){
      schema = labSchema;
  } else {
      schema = imageSchema;
  }
  const result = await schema.validate(validateFields,{abortEarly: false});
  ////////////
  if(result.error != undefined){
      var message = '';
      var isFocused = false;
      result.error.details.map(function(detail:any){
        //////////
          switch (detail.path[0]) {
            
              case 'title':
                  if(detail.type == 'string.empty'){
                      message = intl['valid.entertitle']
                  }else if(detail.type == 'string.min'){
                      message = intl['validat.mincharacter']
                      message = message.replace('{#limit}',detail.context.limit);
                  } else if(detail.type == 'string.max'){
                      message = intl['validat.maxcharacter']
                      message = message.replace('{#limit}',detail.context.limit);
                  } else {
                      message = detail.type;
                  }
                  break; 
              case 'assessment':
                  if(detail.type == 'string.empty'){
                      message = intl['valid.enterassessment']
                  }else if(detail.type == 'string.min'){
                      message = intl['validat.mincharacter']
                      message = message.replace('{#limit}',detail.context.limit);
                  } else if(detail.type == 'string.max'){
                      message = intl['validat.maxcharacter']
                      message = message.replace('{#limit}',detail.context.limit);
                  } else {
                      message = detail.type;
                  }
                  break; 
              case 'plan':
                  if(detail.type == 'string.empty'){
                      message = intl['valid.enterplan']
                  }else if(detail.type == 'string.min'){
                      message = intl['validat.mincharacter']
                      message = message.replace('{#limit}',detail.context.limit);
                  } else if(detail.type == 'string.max'){
                      message = intl['validat.maxcharacter']
                      message = message.replace('{#limit}',detail.context.limit);
                  } else {
                      message = detail.type;
                  }
                  break;
              case 'prescription_title':
                  if(detail.type == 'string.empty'){
                      message = intl['valid.enterpresc_title']
                  }else if(detail.type == 'string.min'){
                      message = intl['validat.mincharacter']
                      message = message.replace('{#limit}',detail.context.limit);
                  } else if(detail.type == 'string.max'){
                      message = intl['validat.maxcharacter']
                      message = message.replace('{#limit}',detail.context.limit);
                  } else {
                      message = detail.type;
                  }
                  break;
              case 'drug_name':
                      if(detail.type == 'string.empty'){
                          message = intl['valid.enterdrug']
                      }else if(detail.type == 'string.min'){
                          message = intl['validat.mincharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else if(detail.type == 'string.max'){
                          message = intl['validat.maxcharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else {
                          message = detail.type;
                      }
                      break;
              case 'frequency':
                      if(detail.type == 'string.empty'){
                          message = intl['valid.enterfrequency']
                      }else if(detail.type == 'string.min'){
                          message = intl['validat.mincharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else if(detail.type == 'string.max'){
                          message = intl['validat.maxcharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else {
                          message = detail.type;
                      }
                      break;
              case 'duration':
                      if(detail.type == 'string.empty'){
                          message = intl['valid.enterduration']
                      }else if(detail.type == 'string.min'){
                          message = intl['validat.mincharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else if(detail.type == 'string.max'){
                          message = intl['validat.maxcharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else {
                          message = detail.type;
                      }
                      break;
              case 'dose':
                      if(detail.type == 'string.empty'){
                          message = intl['valid.enterdose']
                      }else if(detail.type == 'string.min'){
                          message = intl['validat.mincharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else if(detail.type == 'string.max'){
                          message = intl['validat.maxcharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else {
                          message = detail.type;
                      }
                      break;
              case 'specimen':
                      if(detail.type == 'string.empty'){
                          message = intl['valid.enterspecimen']
                      }else if(detail.type == 'string.min'){
                          message = intl['validat.mincharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else if(detail.type == 'string.max'){
                          message = intl['validat.maxcharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else {
                          message = detail.type;
                      }
                      break;
              case 'test_name':
                      if(detail.type == 'string.empty'){
                          message = intl['valid.entertest_name']
                      }else if(detail.type == 'string.min'){
                          message = intl['validat.mincharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else if(detail.type == 'string.max'){
                          message = intl['validat.maxcharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else {
                          message = detail.type;
                      }
                      break;
              case 'indication':
                      if(detail.type == 'string.empty'){
                          message = intl['valid.enterindication']
                      }else if(detail.type == 'string.min'){
                          message = intl['validat.mincharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else if(detail.type == 'string.max'){
                          message = intl['validat.maxcharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else {
                          message = detail.type;
                      }
                      break;
              case 'body_part':
                      if(detail.type == 'string.empty'){
                          message = intl['valid.enterbody_part']
                      }else if(detail.type == 'string.min'){
                          message = intl['validat.mincharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else if(detail.type == 'string.max'){
                          message = intl['validat.maxcharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else {
                          message = detail.type;
                      }
                      break;
              case 'imaging_modality':
                      if(detail.type == 'string.empty'){
                          message = intl['valid.enterimaging_modality']
                      }else if(detail.type == 'string.min'){
                          message = intl['validat.mincharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else if(detail.type == 'string.max'){
                          message = intl['validat.maxcharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else {
                          message = detail.type;
                      }
                      break;
              case 'image_indication':
                      if(detail.type == 'string.empty'){
                          message = intl['valid.enterindication']
                      }else if(detail.type == 'string.min'){
                          message = intl['validat.mincharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else if(detail.type == 'string.max'){
                          message = intl['validat.maxcharacter']
                          message = message.replace('{#limit}',detail.context.limit);
                      } else {
                          message = detail.type;
                      }
                      break;
          }
          error[detail.path[0]] = message;
      })
      this.setState({
          showErrors:error,
      })
  } else {
      this.setState({
          showErrors:false,
      })
  }
}

handleNotesSubmit(appointmentID:any){
  ////////////
  let reqParam: any = {
      //need to change when integrate dynamic data
      appointments_id: appointmentID,
      title: this.state.title,
      assessment: this.state.assessment,
      plan: this.state.plan,    };


    this.props
      .dispatch(addAppointmentNotes(reqParam))
      .then((response: any) => {
          this.setState({
              isLoadingDisplay:false,
          })
          this.resetForm();
          this.setState({
            responseMessageFrm:response.data.message,
          })
        ////////////
        // let data:any = {
        //     alert:true,
        //     erroralert:false,
        //     alertTitle:"Success",
        //     alertDescription:response.data.message,
        // }
        // this.props.dispatch(showAlert(data))
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
          this.setState({
              isLoadingDisplay:false,
          })
          this.resetForm();
          this.setState({
            responseMessageFrm:error.message.message,
          })
          // let data:any = {
          //     alert:false,
          //     erroralert:true,
          //     alertTitle:"Oops!!!",
          //     alertDescription:error.message.message,
          // }
          // this.props.dispatch(showAlert(data))
      });
}

handlePrescriptionSubmit(appointmentID:any){
  ////////////
  let reqParam: any = {
      //need to change when integrate dynamic data
      appointments_id: appointmentID,
      prescription_title:this.state.prescription_title,
      drug_name:this.state.drug_name,
      dose:this.state.dose,
      duration:this.state.duration,
      frequency:this.state.frequency
    };

    this.props
      .dispatch(addAppointmentPrescription(reqParam))
      .then((response: any) => {
          this.setState({
              isLoadingDisplay:false,
          })
          // $('#appointmentHistory .close').click();
        ////////////
        // let data:any = {
        //     alert:true,
        //     erroralert:false,
        //     alertTitle:"Success",
        //     alertDescription:response.data.message,
        // }
        // this.props.dispatch(showAlert(data))
        this.resetForm();
        this.setState({
          responseMessageFrm:response.data.message,
        })
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
          this.setState({
              isLoadingDisplay:false,
          })
          // $('#appointmentHistory .close').click();
          // let data:any = {
          //     alert:false,
          //     erroralert:true,
          //     alertTitle:"Oops!!!",
          //     alertDescription:error.message.message,
          // }
          // this.props.dispatch(showAlert(data))
          this.resetForm();
          this.setState({
            responseMessageFrm:error.message.message,
          })
      });
}

handleLabRequestSubmit(appointmentID:any){
  ////////////
  let reqParam: any = {
      //need to change when integrate dynamic data
      appointments_id: appointmentID,
      specimen:this.state.specimen,
      test_name:this.state.test_name,
      indication:this.state.indication,
    };

    this.props
      .dispatch(addAppointmentLabRequest(reqParam))
      .then((response: any) => {
          this.setState({
              isLoadingDisplay:false,
          })
          // $('#appointmentHistory .close').click();
        ////////////
        // let data:any = {
        //     alert:true,
        //     erroralert:false,
        //     alertTitle:"Success",
        //     alertDescription:response.data.message,
        // }
        // this.props.dispatch(showAlert(data))
        this.resetForm();
        this.setState({
          responseMessageFrm:response.data.message,
        })
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
          this.setState({
              isLoadingDisplay:false,
          })
          // $('#appointmentHistory .close').click();
          // let data:any = {
          //     alert:false,
          //     erroralert:true,
          //     alertTitle:"Oops!!!",
          //     alertDescription:error.message.message,
          // }
          // this.props.dispatch(showAlert(data))
          this.resetForm();
          this.setState({
            responseMessageFrm:error.message.message,
          })
      });
}

handleImageRequestSubmit(appointmentID:any){
  ////////////
  let reqParam: any = {
      //need to change when integrate dynamic data
      appointments_id: appointmentID,
      body_part:this.state.body_part,
      imaging_modality:this.state.imaging_modality,
      indication:this.state.image_indication,
    };

    this.props
      .dispatch(addAppointmentImageRequest(reqParam))
      .then((response: any) => {
       this.setState({
           isLoadingDisplay:false,
       })
       this.resetForm();
       this.setState({
        responseMessageFrm:response.data.message,
      })
      //  $('#appointmentHistory .close').click();
      //   ////////////
      //   let data:any = {
      //       alert:true,
      //       erroralert:false,
      //       alertTitle:"Success",
      //       alertDescription:response.data.message,
      //   }
      //   this.props.dispatch(showAlert(data))
      
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
          ////////////
          this.resetForm();
          this.setState({
            responseMessageFrm:error.message,
          })
          // this.setState({
          //     isLoadingDisplay:false,
          // })
          // $('#appointmentHistory').removeClass('show');
          // $('#appointmentHistory .close').click();
          // let data:any = {
          //     alert:false,
          //     erroralert:true,
          //     alertTitle:"Oops!!!",
          //     alertDescription:error.message,
          // }
          // this.props.dispatch(showAlert(data))
      });
}

handleSubmit = async (e:any) => {
  e.preventDefault();
  // //////////
  
  var {intl} = this.props;  
  let touched = {
      title:true,
      assessment:true,
      plan:true,
      prescription_title:true,
      drug_name:true,
      dose:true,
      frequency:true,
      duration:true,
      specimen:true,
      test_name:true,
      indication:true,
      body_part:true,
      imaging_modality:true,
      image_indication:true,
  }
  this.setState({
      touched:touched,
  })
  await this.getValidationFieldObj();
  if (!this.state.showErrors) {
      await this.setState({
          isLoadingDisplay: true,
      })
      switch(this.state.displayWindowName){
          case 'notes':
              this.handleNotesSubmit(this.state.Appointment.id);
              break;
          case 'prescription':
              this.handlePrescriptionSubmit(this.state.Appointment.id);
              break;
          case 'lab':
              this.handleLabRequestSubmit(this.state.Appointment.id);
              break;
          case 'image':
              this.handleImageRequestSubmit(this.state.Appointment.id);
              break;
      }
  }else{
    //////////
    
  } 
}

  componentWillReceiveProps() {
    // //////////
  }

  public openModal() {
    this.setState({isModalOpen: true});
  }

  async componentWillMount() {
    this.props.onRef(null);
  }

  async componentDidMount() {
    let _this1:any = this;
    $( window ).on('beforeunload', function( event:any ) {
      // if(_this1.state.VideoSession != null && _this1.state.VideoSession != ''){
          let reqData_doctor_id = 0
          let reqData_patient_id = 0
          if (_this1.props.currentUser && _this1.props.currentUser.type == 3) {
            reqData_doctor_id = _this1.state.TokboxCurrentUser.id;
            reqData_patient_id = _this1.state.TokboxPartnerUser.id;
          } else {
            reqData_patient_id = _this1.state.TokboxCurrentUser.id;
            reqData_doctor_id = _this1.state.TokboxPartnerUser.id;
          }
          if(reqData_patient_id > 0){
            socket.emit('componentWillUnmount', {
              room: _this1.state.AppointmentRoom,
              VideoSession: _this1.state.VideoSession,
              appointment_id: _this1.state.Appointment_ID,
              patient_id:reqData_patient_id,
              doctor_id:reqData_doctor_id,
              user: _this1.props.currentUser,
              Partneruserid: _this1.state.TokboxPartnerUser.id,
              event: 'componentWillUnmount',
            });
          }
          
      // }
   });
    $("body").click(function(e:any){
        if($(e.target).parents('.emoji-pickers-frame').length == 0){
          _this1.setState({
                emojiToggle:false,
            })
        }
    });
    this.props.onRef(this);
    if (timer) {
      clearInterval(timer);
    }
    socket = this.props.socket;
    const _this = this;

    // socket.on('user_receive_time', function(data: any) {
    //   _this.setState({current_time: data.cuttent_time});
    //       if (timerReverse) {
    //         clearInterval(timerReverse);
    //       }
    //       timerReverse = setInterval(function() {
    //         _this.setReverseCurrentTime()
    //       }, 1000);
    // });

    socket.on('bread_for_appointment', function(data: any) {
      // console.log('KKK bread_for_appointment:')
      let name = (_this.props.languages == 'ar')?data.username2:data.username1;
      _this.setState({Appointmentalert: true, appointmentrequestmessage: _this.props.intl['bread_for_appointment'].replace('{username}',name)});
    });

    socket.on('user_receive_time', function(data: any) {
      let Appointment = _this.state.Appointment;
      if(data['appointment_room'] != Appointment.appointment_room) return false;

      let Dt: any = new Date(data['cuttent_time']);//we send current time in created_at field
      let startdt: any = new Date(Appointment.appointment_started_at);
      let enddt: any = new Date(Appointment.appointment_end_at);
      
      var seconds = Math.floor((enddt - Dt) / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      Appointment.timeDate = new Date(
        moment(enddt).format('YYYY/MM/DD') +
          ' ' +
          hours +
          ':' +
          minutes +
          ':' +
          seconds,
      ).toString();

        _this.setState({Appointment: Appointment});
    });

    socket.on('user_dial_state', function(data: any) {
      _this.setState({Appointmentalert: false, mandatoryAlert: false, notEnoughMoneyalert:false});
      _this.setState({ Appointmentroom: '', AppointmentId: null, PartnerUser: null, isReceivedCall: false, isJoinCall: false },  () => {
        if (data && data.user && data.user.id != _this.props.currentUser.id) {
          _this.setState({
            PartnerUser: data.user,
            isReceivedCall: true,
            isModalOpen: true,
            isLoadingDisplay: false,
          });
        }
      });
      
      
    });
    socket.on('user_receive_state', function(data: any) {
      _this.resetForm();
      _this.setState({Appointmentalert: false, mandatoryAlert: false, notEnoughMoneyalert:false});
      if (data && data.user && data.user.id != _this.props.currentUser.id) {
        _this.setState({PartnerUser: data.user, isMissedCall: false});
        socket.emit('doctorbusy', {
          user: _this.props.currentUser,
          Partneruserid: _this.state.TokboxPartnerUser.id,
          event: 'doctor_status',
        });
        console.log('KKK user_receive_state');

        _this.CheckandGetAppointment(data.room);
        // _this.GetVideoChatRoom(_this.state.AppointmentRoom);
      }else{
        _this.setState({isModalOpen: false, isReceivedCall: false});
      }
    });

    socket.on('user_decline_state', function(data: any) {
      console.log('KKK user_decline_state call')
      _this.resetForm();
      if (data && data.user && data.user.id != _this.props.currentUser.id && _this.state.PartnerName != null && _this.state.PartnerName != '') {
        _this.setState({
          PartnerUser: data.user,
          isReceivedCall: false,
          isDeclineCall: true,
          isLoadingDisplay: false,
          isMissedCall: false,
          isModalOpen: false,
        });

        _this.setState({
          Appointmentalert: true,
          appointmentrequestmessage:
            _this.state.PartnerName +
            ' ' +
            _this.props.intl['decline_your_call'],
        });

        if (
          _this.props.currentUser.type != 3 &&
          _this.state.appointmentQueueId
        ) {
          _this.CancelAppointmentsQueue(_this.state.appointmentQueueId);
        }
      }else{
        _this.setState({isModalOpen: false, isReceivedCall: false});
      }
    });

    socket.on('user_missed_state', function(data: any) {
      _this.resetForm();
      _this.setState({isLoadingDisplay: false});
      if (data && data.user && data.user.id != _this.props.currentUser.id && _this.state.PartnerName != null && _this.state.PartnerName != '') {
        _this.setState({
          VideoSession: null,
          isReceivedCall: false,
          isModalOpen: false,
        });
        _this.setState({
          Appointmentalert: true,
          appointmentrequestmessage:
            _this.props.intl['you_have_missed_call'] + _this.state.PartnerName,
        });
      }
    });

    socket.on('user_unavailable', function(data: any) {
      _this.resetForm();
      _this.setState({isLoadingDisplay: false});
      if ((_this.state.VideoSession == null || _this.state.VideoSession == '') && data && data.user && data.user.id != _this.props.currentUser.id && _this.state.PartnerName != null && _this.state.PartnerName != '') {
        _this.setState({
          VideoSession: null,
          isReceivedCall: false,
          isModalOpen: false,
        });
        _this.setState({
          Appointmentalert: true,
          appointmentrequestmessage:
          _this.state.PartnerName + _this.props.intl['user_not_available'] ,
        });
      }
    });

    socket.on('user_disconnect_state', function(data: any) {
      _this.resetForm();
      if (data && data.user && _this.state.PartnerName != null && _this.state.PartnerName != '') {
        socket.emit('doctoronline', {
          user: _this.props.currentUser,
          event: 'doctor_status',
        });
        _this.setState({isModalOpen: false});
        _this.setState({
          Appointment: {},
          AppointmentRoom: '',
          VideoSession: null,
          isReceivedCall: false,
        });
        if(data.user.id != _this.props.currentUser.id){
          _this.setState({
            Appointmentalert: true,
            appointmentrequestmessage:
              _this.state.PartnerName + ' ' + _this.props.intl['disconnect_call'],
          });
        }
      }else{
        _this.setState({isModalOpen: false, isReceivedCall: false});
      }
    });

    socket.on('user_check_call_Status', function(data: any) {
      console.log('KKK data1 videoChatBox:',data);

      if (data && data.user && data.user.id != _this.props.currentUser.id) {
        if (data.VideoSession && data.room == _this.state.AppointmentRoom) {
        } else if (data.room != _this.state.Appointment.appointment_room) {
        } else if (_this.state.VideoSession) {
          socket.emit('checksessioncall', {
            room: _this.state.AppointmentRoom,
            appointment_id: _this.state.Appointment_ID,   
            user: _this.props.currentUser,
            Partneruserid: _this.state.TokboxPartnerUser.id,
            VideoSession: _this.state.VideoSession ? true : false,
            event: 'check_state',
          });
        }
      }
    });

    socket.on('user_consultation_types_change', function(data: any) {
      // ////
      
      _this.setState({RunTimeConsultationTypes:data['consultation_types']});
      // _this.setState({ConsultationTypes:orgConsultationTypes});
      // _this.setState({ConsultationTypes:_this.state.orgConsultationTypes}, () =>{
        
        if (_this.state.orgConsultationTypes.length > 0) {
          if (!_this.state.orgConsultationTypes.includes(2) && !_this.state.orgConsultationTypes.includes(3)) {
            _this.setState({publishAudio: false, showAudioIcon: false});

            let ConsultationTypes = _this.state.ConsultationTypes;
            if(ConsultationTypes.includes(2)){
              const index = ConsultationTypes.indexOf(2);
              if (index > -1) {
                ConsultationTypes.splice(index, 1);
              }
            }

            if(ConsultationTypes.includes(3)){
              const index = ConsultationTypes.indexOf(3);
              if (index > -1) {
                ConsultationTypes.splice(index, 1);
              }
            }

            _this.setState({ConsultationTypes:ConsultationTypes});

          }
          if (!_this.state.orgConsultationTypes.includes(3)) {
            _this.setState({publishVideo: false, showVideoIcon: false});
            let ConsultationTypes = _this.state.ConsultationTypes;
            if(ConsultationTypes.includes(3)){
              const index = ConsultationTypes.indexOf(3);
              if (index > -1) {
                ConsultationTypes.splice(index, 1);
              }
            }
          }
          if (!_this.state.orgConsultationTypes.includes(1)) {
            _this.setState({displayWindowName: 'notes'});
            let ConsultationTypes = _this.state.ConsultationTypes;
            if(ConsultationTypes.includes(1)){
              const index = ConsultationTypes.indexOf(1);
              if (index > -1) {
                ConsultationTypes.splice(index, 1);
              }
            }
          }
        }
      // });
      
    });
    
    this.OpenDirectCall(this.props);
  }

  getNameAsPerLanguage(title:string, englishName:string, arabicName:string){
    // ////
    // ////
    // ////
    
    let returnName = '';
    returnName = this.props.intl['titleData'].filter((prefix: any) => prefix.id == title).map(
      (prefixtitle: any, i: any) => { 
        return (prefixtitle.name + ' ')
       }
      )

      let name = this.props.languages == 'ar'?arabicName:englishName;
      if(name == ''){
        name = this.props.languages == 'ar'?englishName:arabicName;
      }

     return returnName+''+ name;
  }

  async OpenDirectCall(data: any) {
      const _this = this;
      _this.resetForm();
      _this.resetVonageControll();
      this.setState({resDisputeChatList:[]});
      const currentUser = data.CurrentUser;
      const partnerUser = data.PartnerUser;
      const partnerName = data.PartnerName;
      const isDirectCall = data.IsDirectCall;
      const isReceivedCall = data.isReceivedCall;
      const AppointmentRoom = data.AppointmentRoom;
      const AppointmentId = data.AppointmentId;
      const checkPreviousSession = data.checkPreviousSession;
      const isModalOpen = data.isModalOpen;
      const isReadytoJoincall = data.isJoinCall;
      const appointmentQueueId = data.AppointmentQueueId;
      if (currentUser && partnerUser) {
        await this.setState({TokboxCurrentUser: currentUser});
        await this.setState({TokboxPartnerUser: partnerUser});
        await this.setState({PartnerName: partnerName});
        await this.setState({isDirectCall: isDirectCall});
        // if (isReceivedCall) {
          await this.setState({isReceivedCall:isReceivedCall});
        // }

        if (isModalOpen) {
          this.setState({isModalOpen});
        }
        //////////
        if (appointmentQueueId) {
          this.setState({appointmentQueueId});
        }

        this.setState({Appointment_ID: AppointmentId});

        ////////
        ////////

        if (AppointmentRoom && AppointmentRoom != '') {
          await this.setState({AppointmentRoom});
          if (checkPreviousSession) {
            await this.CheckPreviousSession(AppointmentRoom);
          }
        }

        if (isDirectCall) {
          ////////

          await this.startCall();
        }

        if (isReadytoJoincall) {
          await this.setState({isReadytoJoincall});
          setTimeout(() => {
            this.JoinCall();
            socket.emit('doctorbusy', {
              user: _this.props.currentUser,
              Partneruserid: _this.state.TokboxPartnerUser.id,
              event: 'doctor_status',
            });
          }, 2000);
        }
      }

      //
      //
      
  }

  CheckPreviousSession(appointmentroom: any) {
    const _this = this;
    //////////
    socket.emit('checksessioncall', {
      room: appointmentroom,
      appointment_id: this.state.Appointment_ID,
      user: this.props.currentUser,
      Partneruserid: this.state.TokboxPartnerUser.id,
      VideoSession: this.state.VideoSession ? true : false,
      event: 'check_state',
    });
  }

  bookAppointmentQueue() {
    if (this.props.currentUser && this.props.currentUser.type == 3) {
      ////////

      this.startCall();
      return;
    }

    let reqData = {doctor_id: 0};
    if (this.state.TokboxCurrentUser && this.state.TokboxPartnerUser) {
      if (this.props.currentUser && this.props.currentUser.type == 3) {
        reqData.doctor_id = this.state.TokboxCurrentUser.id;
      } else {
        reqData.doctor_id = this.state.TokboxPartnerUser.id;
      }
    }

    this.setState({isLoadingDisplay: true});
    this.props
      .dispatch(bookAppointmentQueueApi(reqData))
      .then((response: any) => {
        //////////

        if (response.status == 200) {
          this.setState({
            isShowBottomLoader: false,
          });

          if (response.data && response.data.startcall) {
            ////////

            this.startCall();
          } else {
            this.setState({
              Appointmentalert: true,
              appointmentrequestmessage: response.message,
            });
          }
        } else {
          this.setState({
            isAlertShow: true,
            responseMessage: response.data.message,
          });
          this.setState({isLoadingDisplay: false});
        }
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
        //////////

        this.setState({
          Appointmentalert: true,
          appointmentrequestmessage: error.error.message,
        });
        this.setState({isLoadingDisplay: false});
        this.setState({
          isAlertShow: true,
          responseMessage: error.error.message,
        });
      });
  }

  // setReverseCurrentTime(){
  //   let current_time = this.state.current_time;
  //     if(current_time != null){
  //       let dt = new Date(current_time);
  //       dt.setSeconds(dt.getSeconds() - 1);
  //       this.setState({current_time: dt});
  //     }    
  // }

  SettimerAppointments() {
    let Appointment: any = this.state.Appointment;
    ////
    
    if(typeof Appointment.timeDate != undefined){
      if (Appointment.timeDate) {
        let dt = new Date(Appointment.timeDate);
        dt.setSeconds(dt.getSeconds() - 1);
        Appointment.timeDate = dt;

        if (dt.getHours() == 0 && dt.getMinutes() < 1) {
          Appointment.lessthenminute = true;
        }

        let hours =
          dt.getHours().toString().length < 2
            ? '0' + dt.getHours().toString()
            : dt.getHours().toString();
        let minutes =
          dt.getMinutes().toString().length < 2
            ? '0' + dt.getMinutes().toString()
            : dt.getMinutes().toString();
        let seconds =
          dt.getSeconds().toString().length < 2
            ? '0' + dt.getSeconds().toString()
            : dt.getSeconds().toString();

        Appointment.timeticker = hours + ':' + minutes + ':' + seconds;
        // console.log('Appointment.timeticker:',Appointment.timeticker);
        
        if(seconds == '00' && this.props.currentUser.type == 3){          
          socket.emit('ticker_time_update', {
            doctor_id: Appointment.doctor.id,
            patient_id: Appointment.patient.id,
            user_id: this.props.currentUser.id,
            appointment_room: Appointment.appointment_room,
            event: 'update_ticker_time',
          });
        }
        // if (dt.getMinutes() <= 0) {
        // }

        if (dt.getHours() == 0 && dt.getMinutes() < 1 && dt.getSeconds() <= 1) {
          Appointment.lessthenminute = true;
          let newdt = new Date();
          // let overduedt = new Date(newdt.toLocaleDateString() + ' 00:00:00');
          let overduedt = new Date(moment(newdt).format('YYYY/MM/DD')+' 00:00:00');
          Appointment.timeDate = overduedt;
          Appointment.isOverDue = true;
          /**
           * over due call confirmation
           */
          // this.setState({mandatoryAlert: true});
        }

        this.setState({Appointment});
      }
    }
  }

  setSideForm (displayWindowName:String){
    this.resetForm();
    let ConsultationTypes = this.state.ConsultationTypes;
    if(displayWindowName == 'chat' && (this.state.displayWindowName == displayWindowName || !ConsultationTypes.includes(1))){
      if(ConsultationTypes.includes(1)){
        const index = ConsultationTypes.indexOf(1);
        if (index > -1) {
          ConsultationTypes.splice(index, 1);
        }
      }else{
        ConsultationTypes.push(1);
      }
    }

    this.setState({displayWindowName: displayWindowName,ConsultationTypes:ConsultationTypes}, () => {
      if (this.props.isAuthenticated && this.props.currentUser && this.props.currentUser.type == 2 && this.state.displayWindowName != 'chat') {
        if(this.state.displayWindowName == 'notes'){
          this.notesSlide();
        } else if(this.state.displayWindowName == 'prescription'){
          this.prescriptionSlide();
        } else if(this.state.displayWindowName == 'lab'){
          this.labSlide();
        } else if(this.state.displayWindowName == 'image'){
          this.imagesSlide();
        }
      }
      // else if(this.state.displayWindowName == 'chat'){
      //   let ConsultationTypes = this.state.ConsultationTypes;
      //   if(ConsultationTypes.includes(1)){
      //     const index = ConsultationTypes.indexOf(1);
      //     if (index > -1) {
      //       ConsultationTypes.splice(index, 1);
      //     }
      //   }else{
      //     ConsultationTypes.push(1);
      //   }
      //   this.setState({ConsultationTypes:ConsultationTypes})
      // }
      if(displayWindowName == 'chat' && !ConsultationTypes.includes(1)){
        this.setState({displayWindowName: 'notes'}, () => {
          this.notesSlide();
        });
      }
    });    
    if(this.props.currentUser.type == 3 && displayWindowName == 'chat'){
      socket.emit('consultation_types_change', {
        Partneruserid: this.state.TokboxPartnerUser.id,
        ConsultationTypes: ConsultationTypes,
        event: 'consultation_types_change',
      });
    }
  }

  SetOverDueTimer() {
    let Appointment: any = this.state.Appointment;
    if(typeof Appointment.timeDate != undefined){
      if (Appointment.timeDate) {
        let dt = new Date(Appointment.timeDate);
        dt.setSeconds(dt.getSeconds() + 1);
        Appointment.timeDate = dt;

        let hours =
          dt.getHours().toString().length < 2
            ? '0' + dt.getHours().toString()
            : dt.getHours().toString();
        let minutes =
          dt.getMinutes().toString().length < 2
            ? '0' + dt.getMinutes().toString()
            : dt.getMinutes().toString();
        let seconds =
          dt.getSeconds().toString().length < 2
            ? '0' + dt.getSeconds().toString()
            : dt.getSeconds().toString();

        Appointment.timeticker =
          this.props.intl['overdue'] +' : ' + hours + ':' + minutes + ':' + seconds;

        this.setState({Appointment});
      }
    }
  }

  CheckandGetAppointment(roomname: any) {
    console.log('KKK CheckandGetAppointment');
    
    this.setState({isLoadingDisplay: true});
    const _this = this;
    this.props
      .dispatch(checkappointmentroom(roomname))
      .then((response: any) => {
        this.resetForm();
        this.resetVonageControll();
        if (response.status == 200) {
          let Appointment = response.data;

          this.setState({AppointmentRoom: Appointment.appointment_room});

          if (this.props.isAuthenticated && this.props.currentUser) {
            if (Appointment && Appointment.doctor && Appointment.patient) {
            }
          socket.emit('joinappointmentroom', {
            appointmentroom: Appointment.appointment_room,
            event: 'doctor_appointment',
          });
          socket.on('connect', async function () {
            socket.emit('joinappointmentroom', {
              appointmentroom: Appointment.appointment_room,
              event: 'doctor_appointment',
            });
         });
          const _this:any = this;
            if(this.state.receive_message_socket == false){
              socket.on('receive_message', function (data: any) {
                
                if(constant.default.platform == 'web' && data){
                  _this.scrollToBottom();
                }
                let chatsList = _this.state.resDisputeChatList;
                let newChatsList = [...chatsList, data];
                _this.setState({ resDisputeChatList: newChatsList });
              });
              socket.on('receive_texts', function (data:any) {
                _this.setState({isTyping: true});
        
                setTimeout(() => {
                    _this.setState({ isTyping: false});
                }, 1500);
              });
              
            }
            socket.emit('updatesocketids', {
              appointment_id: Appointment.id,
              user: this.props.currentUser,
              event: 'update_socket',
            });
            this.setState({Appointment_ID: Appointment.id, receive_message_socket:true});
            this.CheckPreviousSession(Appointment.appointment_room);

            if (
              this.props.currentUser.type == 3 &&
              Appointment &&
              Appointment.doctor.SUserProfileLangs &&
              Appointment.doctor.SUserProfileLangs.length > 0
            ) {
              let doctorEnglishName = Appointment.doctor.SUserProfileLangs && Appointment.doctor.SUserProfileLangs.length > 0 && Appointment.doctor.SUserProfileLangs.filter((x:any) => x.language_id == 1)[0] ? Appointment.doctor.SUserProfileLangs.filter((x:any) => x.language_id == 1)[0].fname + ' ' + Appointment.doctor.SUserProfileLangs.filter((x:any) => x.language_id == 1)[0].lname : '';
              let doctorArabicName = Appointment.doctor.SUserProfileLangs && Appointment.doctor.SUserProfileLangs.length > 0 && Appointment.doctor.SUserProfileLangs.filter((x:any) => x.language_id == 2)[0] ? Appointment.doctor.SUserProfileLangs.filter((x:any) => x.language_id == 2)[0].fname + ' ' + Appointment.doctor.SUserProfileLangs.filter((x:any) => x.language_id == 2)[0].lname : '';
              textChatOptions.sender.id = this.getNameAsPerLanguage(Appointment.doctor.title, doctorEnglishName, doctorArabicName)
              textChatOptions.sender.alias = this.getNameAsPerLanguage(Appointment.doctor.title, doctorEnglishName, doctorArabicName)
            }

            if (
              this.props.currentUser.type == 2 &&
              Appointment &&
              Appointment.patient.SUserProfileLangs &&
              Appointment.patient.SUserProfileLangs.length > 0
            ) {
              let patientEnglishName = Appointment.patient.SUserProfileLangs && Appointment.patient.SUserProfileLangs.length > 0 && Appointment.patient.SUserProfileLangs.filter((x:any) => x.language_id == 1)[0] ? Appointment.patient.SUserProfileLangs.filter((x:any) => x.language_id == 1)[0].fname + ' ' + Appointment.patient.SUserProfileLangs.filter((x:any) => x.language_id == 1)[0].lname : '';
              let patientArabicName = Appointment.patient.SUserProfileLangs && Appointment.patient.SUserProfileLangs.length > 0 && Appointment.patient.SUserProfileLangs.filter((x:any) => x.language_id == 2)[0] ? Appointment.patient.SUserProfileLangs.filter((x:any) => x.language_id == 2)[0].fname + ' ' + Appointment.patient.SUserProfileLangs.filter((x:any) => x.language_id == 2)[0].lname : '';
              textChatOptions.sender.id = this.getNameAsPerLanguage(Appointment.patient.title, patientEnglishName, patientArabicName)
              textChatOptions.sender.alias = this.getNameAsPerLanguage(Appointment.patient.title, patientEnglishName, patientArabicName)
            }
          }

          let ConsultationTypesOrg: any = [];
          let ConsultationTypes: any = [];
          if (
            response.data &&
            response.data.doctor &&
            response.data.doctor.DoctorConsultationTypes &&
            response.data.doctor.DoctorConsultationTypes.length > 0
          ) {
            ConsultationTypesOrg = response.data.doctor.DoctorConsultationTypes;
            ConsultationTypes = response.data.doctor.DoctorConsultationTypes;
            ConsultationTypesOrg = ConsultationTypesOrg.map((x: any) => {
              return x.consultation_type;
            });
            ConsultationTypes = ConsultationTypes.map((x: any) => {
              return x.consultation_type;
            });
            this.setState({ConsultationTypes: ConsultationTypes, orgConsultationTypes:ConsultationTypesOrg, RunTimeConsultationTypes:[]});
          }

          if (ConsultationTypesOrg.length > 0) {
            if (!ConsultationTypesOrg.includes(2) && !ConsultationTypesOrg.includes(3)) {
              this.setState({publishAudio: false, showAudioIcon: false});
            }
            if (!ConsultationTypesOrg.includes(3)) {
              this.setState({publishVideo: false, showVideoIcon: false});
            }
            if (!ConsultationTypesOrg.includes(1)) {
              this.setState({displayWindowName: 'notes'});
                this.notesSlide();
            }
          }

          if (Appointment.AppointmentRecordings.length > 0) {
            let ArchiveVideo = {
              id: Appointment.AppointmentRecordings[0].recording_key,
            };
            this.setState({ArchiveVideo, isRecording: true});
          }

          Appointment.timeDate = null;
          Appointment.lessthenminute = false;
          Appointment.isOverDue = false;
          let currentDate = Appointment.created_at;
          // let currentDate = (this.state.current_time != null && this.state.current_time != '')?this.state.current_time:Appointment.created_at;
          // if(this.state.current_time != null && this.state.current_time != ''){
          //   console.log('socket time use');
          // }else{
          //   console.log('system time use');
          // }
          // if (timerReverse) {
          //   clearInterval(timerReverse);
          // }
          // this.setState({current_time:null})
          let Dt: any = new Date(currentDate);//we send current time in created_at field
          let startdt: any = new Date(Appointment.appointment_started_at);
          let enddt: any = new Date(Appointment.appointment_end_at);
          
          var seconds = Math.floor((enddt - Dt) / 1000);
          var minutes = Math.floor(seconds / 60);
          var hours = Math.floor(minutes / 60);
          var days = Math.floor(hours / 24);

          hours = hours - days * 24;
          minutes = minutes - days * 24 * 60 - hours * 60;
          seconds =
            seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

          if (hours == 0 && minutes < 1) {
            Appointment.lessthenminute = true;
          }

          if (startdt <= Dt && Dt <= enddt) {
            if (Dt.toLocaleDateString() == enddt.toLocaleDateString()) {
              Appointment.timeDate = new Date(
                moment(enddt).format('YYYY/MM/DD') +
                  ' ' +
                  hours +
                  ':' +
                  minutes +
                  ':' +
                  seconds,
              ).toString();
              Appointment.timeticker = '00:00:00';
            }
          }

          this.setState({Appointment: Appointment});

          const _this = this;
          if (timer) {
            clearInterval(timer);
          }
          timer = setInterval(function() {
            if (!_this.state.Appointment.isOverDue) {
              _this.SettimerAppointments();
            } else {
              _this.SetOverDueTimer();
            }
          }, 1000); 

            this.GetVideoChatRoom(roomname);

        } else {
          this.setState({
            isAlertShow: true,
            responseMessage: response.data.message,
          });
          this.setState({isLoadingDisplay: false});
        }
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
        ////////
        console.log('error:',error);
        
        this.setState({isLoadingDisplay: false});
        this.setState({
          isAlertShow: true,
          responseMessage: error.error.message,
        });
        // this.props.history.goBack();
      });
  }

  async sendUserTyping() {
    socket.emit("typing", { 
        event: 'appointment_typing',
        appointmentroom: this.state.AppointmentRoom,
        isTyping: true
    });
  }

  bookAppointment() {
    let reqData: any = {};

    if (this.props.currentUser && this.props.currentUser.type == 3) {
      reqData.doctor_id = this.state.TokboxCurrentUser.id;
      reqData.patient_id = this.state.TokboxPartnerUser.id;
    } else {
      reqData.patient_id = this.state.TokboxCurrentUser.id;
      reqData.doctor_id = this.state.TokboxPartnerUser.id;
    }

    this.setState({isLoadingDisplay: true});
    this.props
      .dispatch(bookAppointmentApi(reqData))
      .then((response: any) => {
        //////////

        if (response.status == 200) {
          this.setState({
            isShowBottomLoader: false,
          });
          let AppointmentRoom = '';
          if (response.data.startPreviousApp) {
            AppointmentRoom = response.data.doctorAppointment.appointment_room;
          } else {
            AppointmentRoom = response.data.appointment_room;
          }

          socket.emit('receivecall', {
            room: AppointmentRoom,
            appointment_id: this.state.Appointment_ID, 
            user: this.props.currentUser,
            Partneruserid: this.state.TokboxPartnerUser.id,
            event: 'receive_state',
          });

          socket.emit('doctorbusy', {
            user: this.props.currentUser,
            Partneruserid: this.state.TokboxPartnerUser.id,
            event: 'doctor_status',
          });
          console.log('KKK bookAppointment');
          
          this.CheckandGetAppointment(AppointmentRoom);
        } else {
          this.setState({
            isAlertShow: true,
            responseMessage: response.data.message,
          });
          this.setState({isLoadingDisplay: false});
        }
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
        //////////

        this.setState({
          notEnoughMoneyalert: true,
          appointmentrequestmessage: error.error.message,
        });
        this.setState({isLoadingDisplay: false});
        this.setState({
          isAlertShow: true,
          responseMessage: error.error.message,
        });
        this.props.history.goBack();
      });
  }

  GetVideoChatRoom(roomname: any) {
    let _this = this;
    this.setState({isLoadingDisplay: true});
    this.props
      .dispatch(GetVideoSessionRoom(roomname))
      .then(async (response: any) => {
        if (response.status == 200) {
          await this.setState({VideoSession: response.data});
          this.getAppointmentChatAPI(this.state.Appointment_ID);
          setTimeout(() => {
            this.scrollToBottom();
          }, 2000);

          otCoreOptions.credentials = response.data;
          
// var session = OT.initSession(otCoreOptions.credentials.apiKey, otCoreOptions.credentials.sessionId);
          otCore = await new AccCore(otCoreOptions);

          if(sessionTimer){
            clearInterval(sessionTimer);
          }
          sessionTimer = setInterval(async function() {
            let otCoreSession =  await otCore.session;
            if(otCoreSession){
              if(otCoreSession.connection != null ){
                if(otCoreSession.connection.hasOwnProperty('connectionId')){
                    clearInterval(sessionTimer);
                    if (_this.props.currentUser) {
                      _this.StartRecording(_this.state.Appointment.appointment_room);
                    }

                    textChatOptions.session = otCoreSession
                    // textChat = await new TextChatAccPack(textChatOptions);
                    
                    // otCore.connect().then(() => 
                    otCore.on('messageSent', (event: any) =>  {
                      
                    })
                    _this.setState({isLoadingDisplay: false});
                }
              }
            }
          }, 1000); 

          //  setTimeout(async () => { 
          // if (_this.props.currentUser) {
          //     _this.StartRecording(_this.state.Appointment.appointment_room);
          //     }
          // }, 3000);

          // setTimeout(async () => { 
          //   ////////
            
          //   textChatOptions.session = await otCore.session;
          //   textChat = await new TextChatAccPack(textChatOptions);
          //   this.setState({isLoadingDisplay: false});
          //   /**
          //    * Recording process commented
          //   if (this.props.currentUser && this.props.currentUser.type == 3) {
          //     this.StartRecording(this.state.Appointment.appointment_room);
          //   }*/
          // }, 5000);
        } else {
          this.setState({
            isAlertShow: true,
            responseMessage: response.data.message,
          });
          this.setState({isLoadingDisplay: false});
        }
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
        ////////
        this.setState({isLoadingDisplay: false});
        this.setState({
          isAlertShow: true,
          responseMessage: error.error.message,
        });
      });
  }

  GetStreamsVideoChatRoom(roomname: any) {
    this.setState({isLoadingDisplay: true});
    this.props
      .dispatch(GetStreamsVideoSessionRoom(roomname))
      .then(async (response: any) => {
        //this.setState({isLoadingDisplay: false});
        // //////////

        if (response.status == 200) {
          await this.setState({VideoSessionStrams: response.data});
        } else {
          this.setState({
            isAlertShow: true,
            responseMessage: response.data.message,
          });
        }
        this.setState({isLoadingDisplay: false});
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
        // //////////
        this.setState({isLoadingDisplay: false});
        this.setState({
          isAlertShow: true,
          responseMessage: error.error.message,
        });
      });
  }

  CancelAppointmentsQueue(appointmentid: any) {
    this.props
      .dispatch(CancelAppointmentQueue(appointmentid))
      .then((response: any) => {
        // //////////
        if (response.status == 200) {
          let message = '';
        }
        this.setState({isLoadingDisplay: false});
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
        // //////////
        this.setState({isLoadingDisplay: false});
        this.setState({
          isAlertShow: true,
          responseMessage: error.error.message,
        });
      });
  }

  componentDidUpdate(prevProps: any) {
    
  }
  // componentWillUnmount() {
  //   console.log('componentWillUnmount');
    
  //   socket.emit('componentWillUnmount',{});
  // }

  async chatBox() {
    if (!this.state.ischatboxOpen) {
      otCoreOptions.credentials = this.state.VideoSession;
      otCore = new AccCore(otCoreOptions);
      setTimeout(() => {
        textChatOptions.session = otCore.session;
        textChat = new TextChatAccPack(textChatOptions);
        this.setState({isLoadingDisplay: false});
      }, 5000);
    }
  }

  startCall() {
    this.setState({isModalOpen: true, isMissedCall:true});
    ////////
      // 'starrrrttttttttt',
    //   this.state.AppointmentRoom,
    //   this.props.currentUser,
    //   this.state.TokboxPartnerUser.id,
    // );
    socket.emit('dialstate', {
      room: this.state.AppointmentRoom,
      appointment_id: this.state.Appointment_ID,
      user: this.props.currentUser,
      Partneruserid: this.state.TokboxPartnerUser.id,
      Partneruser: this.state.TokboxPartnerUser,
      event: 'dial_state',
    });
    if(missedCallTimer){
      clearTimeout(missedCallTimer)
    }
    // this.setState({ isLoadingDisplay: true });
    missedCallTimer = setTimeout(() => {
      if (this.state.isMissedCall) {
        this.setState({Appointmentalert: true, appointmentrequestmessage: this.props.intl['user_not_available_now'].replace('{username}',this.state.PartnerName)});
        this.setState({isLoadingDisplay: false, isModalOpen: false});
        let reqData_doctor_id = 0
        let reqData_patient_id = 0
        if (this.props.currentUser && this.props.currentUser.type == 3) {
          reqData_doctor_id = this.state.TokboxCurrentUser.id;
          reqData_patient_id = this.state.TokboxPartnerUser.id;
        } else {
          reqData_patient_id = this.state.TokboxCurrentUser.id;
          reqData_doctor_id = this.state.TokboxPartnerUser.id;
        }

        socket.emit('missedcall', {
          room: this.state.AppointmentRoom,
          appointment_id: this.state.Appointment_ID,
          patient_id:reqData_patient_id,
          doctor_id:reqData_doctor_id,
          user: this.props.currentUser,
          Partneruserid: this.state.TokboxPartnerUser.id,
          event: 'missed_state',
        });
      }
    }, 30 * 1000);
  }

  JoinCall() {
    console.log('KKK JoinCall');
    
    const _this = this;
    socket.emit('joincallstate', {
      room: this.state.AppointmentRoom,
      appointment_id: this.state.Appointment_ID,
      user: _this.props.currentUser,
      Partneruserid: _this.state.TokboxPartnerUser.id,
      event: 'join_state',
    });

    this.CheckandGetAppointment(this.state.AppointmentRoom);
    // this.GetVideoChatRoom(this.state.AppointmentRoom);
  }

  ReceiveCall() {
    ////////
    
    this.bookAppointment();
  }

  DeclineCall() {
    socket.emit('declinecall', {
      room: this.state.AppointmentRoom,
      appointment_id: this.state.Appointment_ID,
      user: this.props.currentUser,
      Partneruserid: this.state.TokboxPartnerUser.id,
      declineByReceiver: true,
      event: 'decline_state',
    });

    // if (this.props.currentUser.type != 3 && this.state.appointmentQueueId) {
    if (this.state.appointmentQueueId) {
      this.CancelAppointmentsQueue(this.state.appointmentQueueId);
    }

    this.setState({isModalOpen: false, isReceivedCall: false});
  }

  UserCutCall() {
    socket.emit('declinecall', {
      room: this.state.AppointmentRoom,
      appointment_id: this.state.Appointment_ID,
      user: this.props.currentUser,
      Partneruserid: this.state.TokboxPartnerUser.id,
      declineByReceiver: false,
      event: 'decline_state',
    });
    socket.emit('missedcall', {
      room: this.state.AppointmentRoom,
      appointment_id: this.state.Appointment_ID,
      user: this.props.currentUser,
      Partneruserid: this.state.TokboxPartnerUser.id,
      notSendVOIP: true,
      event: 'missed_state',
    });
    this.setState({isModalOpen: false, isReceivedCall: false, isMissedCall:false});

    if (this.state.appointmentQueueId) {
      this.CancelAppointmentsQueue(this.state.appointmentQueueId);
    }
    
  }

  async DisconnectCall() {
    //////
    
    socket.emit('disconnectcall', {
      room: this.state.AppointmentRoom,
      appointment_id: this.state.Appointment_ID,
      user: this.props.currentUser,
      Partneruserid: this.state.TokboxPartnerUser.id,
      event: 'disconnect_state',
    });
    socket.emit('doctoronline', {
      user: this.props.currentUser,
      event: 'doctor_status',
    });
    
    if (this.state.isRecording && this.state.ArchiveVideo) {
      await this.StopRecording(this.state.ArchiveVideo.id);
    }
    if (this.state.Appointment && this.state.Appointment.id > 0) {
      this.changedAppointmentstatus(this.state.Appointment.id);
    }
    this.setState({
      Appointment: {},
      AppointmentRoom: '',
      VideoSession: null,
      isReceivedCall: false,
    });
    this.setState({isModalOpen: false, isMissedCall:false});
    // this.props.history.goBack();
    /**
     * update user balance after diconnect call
     */
    // await this.getUnreadCountNotification();
    this.resetForm();
  }

//   async getUnreadCountNotification() {
//     this.props.dispatch(getUnreadCountNotification()).then((response: any) => {
//        //////
//        this.setState({
//           unReadCount: response.data.data.unread
//        })
//        let newCurrentUser = this.props.currentUser
//        newCurrentUser.total_wallet_balance = response.data.data.total_wallet_balance;
//        this.props.dispatch(authActions.currentUserChange(newCurrentUser));
//        setTimeout(() => {
//         this.forceUpdate();
//      }, 1000);
//     })
//        .catch((error: any, statusCode: any, message: any, e: any) => {
//        })
//  }

  async DisconnectCallFromHomePage(data:any) {
    socket.emit('disconnectcall', {
      room: data.AppointmentRoom,
      appointment_id: this.state.Appointment_ID,
      user: this.props.currentUser,
      Partneruserid: data.Partneruserid,
      event: 'disconnect_state',
    });
    socket.emit('doctoronline', {
      user: this.props.currentUser,
      event: 'doctor_status',
    });
    //////
    //////
    
    if (data.Appointment && data.Appointment.id > 0) {
      this.changedAppointmentstatus(data.Appointment.id);
    }
    this.setState({
      Appointment: {},
      AppointmentRoom: '',
      VideoSession: null,
      isReceivedCall: false,
    });
    this.setState({isModalOpen: false});
    // this.props.history.goBack();
  }

  ToggleAudio() {
    this.setState({publishAudio: !this.state.publishAudio});
    let ConsultationTypes = this.state.ConsultationTypes;
    if(ConsultationTypes.includes(2)){
      const index = ConsultationTypes.indexOf(2);
      if (index > -1) {
        ConsultationTypes.splice(index, 1);
      }
    }else{
      ConsultationTypes.push(2);
    }
    this.setState({ConsultationTypes:ConsultationTypes});
    if(this.props.currentUser.type == 3){
      socket.emit('consultation_types_change', {
        Partneruserid: this.state.TokboxPartnerUser.id,
        ConsultationTypes: ConsultationTypes,
        event: 'consultation_types_change',
      });
    }
  }

  ToggleVideo() {
    this.setState({publishVideo: !this.state.publishVideo});
    //
    
    let ConsultationTypesVid = this.state.ConsultationTypes;
    if(ConsultationTypesVid.includes(3)){
      const index = ConsultationTypesVid.indexOf(3);
      if (index > -1) {
        ConsultationTypesVid.splice(index, 1);
      }
    }else{
      ConsultationTypesVid.push(3);
    }
    //

    this.setState({ConsultationTypes:ConsultationTypesVid})
    //

    if(this.props.currentUser.type == 3){
    socket.emit('consultation_types_change', {
        Partneruserid: this.state.TokboxPartnerUser.id,
        ConsultationTypes: ConsultationTypesVid,
        event: 'consultation_types_change',
      });
    }
  }

  async ToggleRecording() {
    if (!this.state.isRecording) {
      this.StartRecording(this.state.Appointment.appointment_room);
    } else if (this.state.isRecording && this.state.ArchiveVideo) {
      // //////////
      this.StopRecording(this.state.ArchiveVideo.id);
    }
  }

  changedAppointmentstatus(appointmentid: any) {
    this.props
      .dispatch(AppointmentChangeStatus(appointmentid, 3))
      .then((response: any) => {
        // //////////
        if (response.status == 200) {
        }
        this.setState({isLoadingDisplay: false});
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
        // //////////
        this.setState({isLoadingDisplay: false});
        this.setState({
          isAlertShow: true,
          responseMessage: error.error.message,
        });
      });
  }

  ViewRecording() {
    this.setState({isLoadingDisplay: true});
    let archiveId: any = this.state.ArchiveVideo.id;
    this.props
      .dispatch(ViewArchiveVideoSession(archiveId))
      .then(async (response: any) => {
        //this.setState({isLoadingDisplay: false});
        // //////////

        if (response.status == 200) {
          var Archive = response.data;

          if (Archive && Archive.url) {
            window.open(Archive.url, '_blank');
          }
        } else {
          this.setState({
            isAlertShow: true,
            responseMessage: response.data.message,
          });
        }
        this.setState({isLoadingDisplay: false});
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
        // //////////
        this.setState({isLoadingDisplay: false});
        this.setState({
          isAlertShow: true,
          responseMessage: error.error.message,
        });
      });
  }

  StartRecording(roomname: any) {
    // this.setState({ isLoadingDisplay: true });
    let reqData = {
      hasAudio: this.state.publishAudio,
      hasVideo: this.state.publishVideo,
    };

    this.props
      .dispatch(StartArchiveVideoSession(roomname, reqData))
      .then(async (response: any) => {
        //this.setState({isLoadingDisplay: false});
        // //////////

        if (response.status == 200) {
          await this.setState({ArchiveVideo: response.data, isRecording: true});
        } else {
          this.setState({
            isAlertShow: true,
            responseMessage: response.data.message,
          });
        }
        // this.setState({ isLoadingDisplay: false });
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
        // //////////
        this.setState({isLoadingDisplay: false});
        this.setState({
          isAlertShow: true,
          responseMessage: error.error.message,
        });
      });
  }

  StopRecording(archiveId: any) {
    this.setState({isLoadingDisplay: true});
    this.props
      .dispatch(StopArchiveVideoSession(archiveId))
      .then(async (response: any) => {
        //this.setState({isLoadingDisplay: false});
        // //////////

        if (response.status == 200) {
          // await this.setState({ isRecording: false, isViewRecording: true });
          await this.setState({isRecording: false});
        } else {
          this.setState({
            isAlertShow: true,
            responseMessage: response.data.message,
          });
        }
        this.setState({isLoadingDisplay: false});
      })
      .catch((error: any, statusCode: any, message: any, e: any) => {
        // //////////
        this.setState({isLoadingDisplay: false});
        this.setState({
          isAlertShow: true,
          responseMessage: error.error.message,
        });
      });
  }

  CloseModalPopup() {
    // this.setState({ VideoSession: null, isReceivedCall: false });
    // this.setState({ isModalOpen: false });
    // this.props.history.goBack();
  }

  closeMesage = () => {
    //////////

    this.setState({
      responseMessage: '',
      responseMessageFrm: '',
    });
  };

  notesSlide = () => {
      this.setState({
          notesResponse:null,
          isLoadingNotes:true
      },() => {
            let data = {
                appointment_id:this.state.Appointment.id,
            }
            this.props.dispatch(getAppointmentNotes(data)).then((response:any) => {
                ////////
                this.setState({
                    isLoadingNotes:false,
                    notesResponse:response.data,
                })
                
            }).catch((error:any) => {
                ////////
            })
      });
  }

  onEmojiClick = (event: any, emojiObject: any) => {

    let message = this.state.message;
    
    message += emojiObject.emoji.toString();

    this.setState({ chosenEmoji: emojiObject, message });
};

  imagesSlide = () => {
      this.setState({isLoadingNotes:true,imageResponse:null},() =>{
          
          let data = {
              appointment_id:this.state.Appointment.id,
          }
          this.props.dispatch(getAppointmentImageRequest(data)).then((response:any) => {
              this.setState({
                  isLoadingNotes:false,
                  imageResponse:response.data,
              })
              
          }).catch((error:any) => {
              ////////
          })
      })
  }

  prescriptionSlide = () => {
      this.setState({isLoadingNotes:true,prescriptionResponse:null},() =>{
          let data = {
              appointment_id:this.state.Appointment.id,
          }
          this.props.dispatch(getAppointmentPrescription(data)).then((response:any) => {
              ////////
              this.setState({
                  isLoadingNotes:false,
                  prescriptionResponse:response.data,
              })
              
          }).catch((error:any) => {
              ////////
          })
      });
  }

  labSlide = () => {
      this.setState({isLoadingNotes:true,labResponse:null},() =>{
          let data = {
              appointment_id:this.state.Appointment.id,
          }
          this.props.dispatch(getAppointmentLabRequest(data)).then((response:any) => {
              ////////
              this.setState({
                  isLoadingNotes:false,
                  labResponse:response.data,
              })
              
          }).catch((error:any) => {
              ////////
          })
      })
  }
}
