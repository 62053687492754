import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "../node_modules/video-react/dist/video-react.css";
import './css/styles.css';
import './css/bootstrap.min.css';
import './css/bootstrap_limitless.min.css';
import './css/bootstrap_limitless.css';
import './css/doctoray-header.css';
import './css/doctoray-responsive.css';
import './css/owl.carousel.min.css';
import './css/doctoray-custom.css';
import './css/login-reset.css';
import 'react-block-ui/style.css';

// import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(<Suspense fallback={null}>
  <App />
</Suspense>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
