// import { createStore, applyMiddleware } from 'redux';
// import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
// import rootReducer , { RootState } from './reducers';

// let middleware = [thunkMiddleware]
// if (process.env.NODE_ENV !== 'production') {
//     const loggerMiddleware = createLogger();
//     middleware = [ ...middleware, loggerMiddleware ]
// }

// export const store = createStore(
//     rootReducer,
//     applyMiddleware(...middleware)
// );

import { createStore, applyMiddleware } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer, { RootState } from './reducers';
import { AUTH_INITIAL_TYPES } from './auth/action-types';

let middleware = [thunk as ThunkMiddleware<RootState, AUTH_INITIAL_TYPES>]

// if (process.env.NODE_ENV !== 'production') {
//     const loggerMiddleware = createLogger();
//     middleware = [ ...middleware, loggerMiddleware ]
// }

export const store = createStore(
    rootReducer,
    applyMiddleware(...middleware)
);
