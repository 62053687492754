import {
    NOTIFICATION_TYPES
  } from './action-types';
  
  import { MiddleWare } from '../../interfaces/auth';
  
  const initialState: MiddleWare [] = [];
  
  const reducer = (state = initialState,action: NOTIFICATION_TYPES) : MiddleWare[] => {
    // console.log("action.type",action.type);
    switch (action.type) {
      case "GET_ALL_NOTIFICATION":
        return getAllNotification(state,action.payload)
      case "GET_ALL_NOTIFICATIONS":
        return getAllNotifications(state,action.payload)
      case "CLEAR_NOTIFICATION":
        return clearNotification(state)
      default:
        return state;
    }
  };
  
  export default reducer;
  
  function getAllNotification(state:any, payload:any) {
    // console.log(state,'statestatetstatetsatetstate')
    return [...state,...payload.data.result];
  }
  function clearNotification(state:any) {
    // console.log(state,'statestatetstatetsatetstate')
    return [];
  }

  function getAllNotifications(state:any, payload:any) {
    return {
      ...state,
      notification:payload,
    }
  }
  