import {Dispatch} from 'react';
import {RootState} from '../reducers';
import {
  CMS_PAGES,
  CMS_PAGES_TYPES,
  CMS_PAGES_PENDING,
  HEADER_FOOTER,
  PRIVACY_POLICY,
  RULES_REGULATIONS,
  TERMS_CONDITION,
  HOME,
  ABOUT_US,
  CONTACT_US,
  SOCIAL_MEDIA,
  SIGN_IN_SIGN_UP,
  FORGOT_RESEND
} from './action-types';
import {MiddleWare, PayLoad} from '../../interfaces/auth';

export function headerfooter(payload: PayLoad): CMS_PAGES_TYPES {
  return {
    type: HEADER_FOOTER,
    payload,
  };
}

export function headerfooterIntial(payload: PayLoad) {
  return (dispatch: Dispatch<CMS_PAGES_TYPES>, getState: () => RootState) => {
    dispatch(headerfooter(payload));
  };
}

export function privacyPolicy(payload: PayLoad): CMS_PAGES_TYPES {
  return {
    type: PRIVACY_POLICY,
    payload,
  };
}

export function privacyPolicyIntial(payload: PayLoad) {
  return (dispatch: Dispatch<CMS_PAGES_TYPES>, getState: () => RootState) => {
    dispatch(privacyPolicy(payload));
  };
}

export function rulesRegulations(payload: PayLoad): CMS_PAGES_TYPES {
  return {
    type: RULES_REGULATIONS,
    payload,
  };
}

export function rulesRegulationsIntial(payload: PayLoad) {
  return (dispatch: Dispatch<CMS_PAGES_TYPES>, getState: () => RootState) => {
    dispatch(rulesRegulations(payload));
  };
}

export function contact_us(payload: PayLoad): CMS_PAGES_TYPES {
  return {
    type: CONTACT_US,
    payload,
  };
}

export function contact_usIntial(payload: PayLoad) {
  return (dispatch: Dispatch<CMS_PAGES_TYPES>, getState: () => RootState) => {
    dispatch(contact_us(payload));
  };
}

export function termsConditions(payload: PayLoad): CMS_PAGES_TYPES {
  return {
    type: TERMS_CONDITION,
    payload,
  };
}

export function termsConditionsIntial(payload: PayLoad) {
  return (dispatch: Dispatch<CMS_PAGES_TYPES>, getState: () => RootState) => {
    dispatch(termsConditions(payload));
  };
}

export function home(payload: PayLoad): CMS_PAGES_TYPES {
  return {
    type: HOME,
    payload,
  };
}

export function homeIntial(payload: PayLoad) {
  return (dispatch: Dispatch<CMS_PAGES_TYPES>, getState: () => RootState) => {
    dispatch(home(payload));
  };
}
export function aboutUs(payload: PayLoad): CMS_PAGES_TYPES {
  return {
    type: ABOUT_US,
    payload,
  };
}

export function aboutUsIntial(payload: PayLoad) {
  return (dispatch: Dispatch<CMS_PAGES_TYPES>, getState: () => RootState) => {
    dispatch(aboutUs(payload));
  };
}

export function socialMediaLinks(payload: PayLoad): CMS_PAGES_TYPES {
  return {
    type: SOCIAL_MEDIA,
    payload,
  };
}

export function socialMediaLinksIntial(payload: PayLoad) {
  return (dispatch: Dispatch<CMS_PAGES_TYPES>, getState: () => RootState) => {
    dispatch(socialMediaLinks(payload));
  };
}

export function cmsPages(payload: PayLoad): CMS_PAGES_TYPES {
  return {
    type: CMS_PAGES,
    payload,
  };
}

export function cmsPagesIntial(payload: PayLoad) {
  return (dispatch: Dispatch<CMS_PAGES_TYPES>, getState: () => RootState) => {
    dispatch(cmsPages(payload));
  };
}

export function cmsPagesPending() {
  return {
    type: CMS_PAGES_PENDING,
  };
}

export function signInSignUp(payload: PayLoad): CMS_PAGES_TYPES {
  return {
    type: SIGN_IN_SIGN_UP,
    payload,
  };
}

export function signInSignUpIntial(payload: PayLoad) {
  return (dispatch: Dispatch<CMS_PAGES_TYPES>, getState: () => RootState) => {
    dispatch(signInSignUp(payload));
  };
}

export function forgotResend(payload: PayLoad): CMS_PAGES_TYPES {
  return {
    type: FORGOT_RESEND,
    payload,
  };
}

export function forgotResendIntial(payload: PayLoad) {
  return (dispatch: Dispatch<CMS_PAGES_TYPES>, getState: () => RootState) => {
    dispatch(forgotResend(payload));
  };
}