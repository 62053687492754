import {Dispatch} from 'react';
import {RootState} from '../reducers';
import {
  LANGUAGE,
  GLOBAL_TYPES,
  ALERT_MESSAGE_SUCCESS,
  ALERT_MESSAGE_ERROR,
  ALERT_MESSAGE_CLEAR,
} from './action-types';
import { MiddleWare, PayLoad } from '../../interfaces/auth';


export function language(payload:PayLoad) : GLOBAL_TYPES {
    return {
      type: LANGUAGE,
      payload,
    };
  }
  
  export function languageIntial(payload:PayLoad) {
    return (dispatch:Dispatch<GLOBAL_TYPES>, getState: () => RootState) => {
        dispatch(language(payload));
    }
  }

export function alertMessageSuccess(payload:PayLoad) : GLOBAL_TYPES {
    return {
      type: ALERT_MESSAGE_SUCCESS,
      payload,
    };
  }
  
  export function alertMessageSuccessIntial(payload:PayLoad) {
    return (dispatch:Dispatch<GLOBAL_TYPES>, getState: () => RootState) => {
        dispatch(alertMessageSuccess(payload));
    }
  }

export function alertMessageError(payload:PayLoad) : GLOBAL_TYPES {
    return {
      type: ALERT_MESSAGE_ERROR,
      payload,
    };
  }
  
  export function alertMessageErrorIntial(payload:PayLoad) {
    return (dispatch:Dispatch<GLOBAL_TYPES>, getState: () => RootState) => {
        dispatch(alertMessageError(payload));
    }
  }

export function alertMessageClear() {
    return {
      type: ALERT_MESSAGE_CLEAR
    };
  }