import React, { Component } from 'react';
import './App.css';
import './css/bootstrap_limitless.min.css';
import './css/bootstrap_limitless.css';
import './css/bootstrap.min.css';
import './css/bootstrap.css';
import './css/doctoray-custom.css';
import './css/font/flaticons/flaticon.css';
import Route from './routes/index'
import { Provider } from 'react-redux';
import { store } from './lib/store';
import AlertMessage from './global/AlertMessage';
import { SocketProvider } from 'socket.io-react';
import socketIOClient from 'socket.io-client';
import * as constant from './lib/config/constant'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

function generatRandomString(length:number) {
	var result           = '';
	var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-=';
	var charactersLength = characters.length;
	for ( var i = 0; i < length; i++ ) {
	   result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
 }

 const socket = socketIOClient(constant.API_URL,
  { query: {
    polling:generatRandomString(20),
  } });
  
socket.on('connect', (msg: any) => console.log(msg));

class App extends Component<any, any>{
  //https://levelup.gitconnected.com/using-jwt-in-your-react-redux-app-for-authorization-d31be51a50d2
  render() {
    var lang = (localStorage.getItem('language') == 'ar') ? 'ar' : 'en';
    if (lang == 'ar') {
      document.body.classList.add('text-right');
      document.body.dir = 'rtl';
    } else {
      document.body.classList.remove('text-right');
      document.body.dir = 'ltr';
    }
    return (
      <>
        <Provider store={store}>
          <SocketProvider socket={socket}>
            {/* <AlertMessage /> */}
            <Route />
          </SocketProvider>
        </Provider>
      </>
    )
  }
}

export default App;
