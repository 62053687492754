import Http from '../utils/Http';
import * as socialActions from '../store/cms/action';
import Transformer from '../utils/Transformer';

/**
 * cms
 *
 * @param credentials
 * @returns {function(*)}
 */
export function socialLinksRender() {
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      // dispatch(cmsActions.cmsPagesPending());
      Http.get(`api/frontend/social`)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            dispatch(socialActions.socialMediaLinks(data));
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}
