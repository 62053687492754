import * as constant from '../../config/constant';

let lib = null;
// if (constant.default.platform == 'Mobile') {
if (false) {
  lib = require('@react-native-community/async-storage');
} else {
  //lib = localStorage;
}
export default lib;
