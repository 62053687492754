// import libs
import React, { lazy } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

// import components
import PrivateRoute from './Private';
import NewPublicRoute from './NewPublic';
// import PublicRoute from './Public';
import AuthRoute from './Auth';
import Layout from "../components/Layout";
import Header from "../global/Header";
import Footer from "../global/Footer";
import UserAuth from './UserAuth';
import { RoleType } from './RoleTypeEnum'
import PatientsAppointmentHistory from '../Pages/PatientsAppointmentHistory';
import BreadCrumbPatient from '../Pages/MedicalInformation/BreadCrumb';

const Contactus = lazy(() =>
    import("../Pages/Contact/index"));

const Career = lazy(() =>
    import("../Pages/Career/index"));

const Privacy = lazy(() =>
    import("../Pages/PrivacyPolicy/index"));

const Terms = lazy(() =>
    import("../Pages/Terms/index"));

const Faq = lazy(() =>
    import("../Pages/FAQ/index"));

const HowItWorks = lazy(() =>
    import("../Pages/HowItWorks/index"));

const Aboutus = lazy(() =>
    import("../Pages/About/index"));

const Login = lazy(() =>
    import("../Pages/SignIn/index"));

const SiteMap = lazy(() =>
    import("../Pages/SiteMap/index"));

const Forgot = lazy(() =>
    import("../Pages/Forgot/index"));

const Rules = lazy(() =>
    import("../Pages/Rules/index"));

const Blog = lazy(() =>
    import("../Pages/Blog/index"));

const BlogDetail = lazy(() =>
    import("../Pages/Blog/BlogDetail/index"));

const AccountSettings = lazy(() =>
    import("../Pages/AccountSettings/index"));
    
const BankDetails = lazy(() =>
    import("../Pages/AccountSettings/index"));

const Home = lazy(() =>
    import("../Pages/Home/index"));

const DoctorProfileForm = lazy(() =>
    import("../Pages/DoctorProfile/DoctorProfileForm/index"));

const DoctorProfile = lazy(() =>
    import("../Pages/DoctorProfile/index"));

const PrescriptionPrint = lazy(() =>
    import("../Pages/PatientProfile/PrescriptionPrint"));

const LabPrint = lazy(() =>
    import("../Pages/PatientProfile/LabPrint"));
    
const ImagePrint = lazy(() =>
    import("../Pages/PatientProfile/ImagePrint"));

const Media = lazy(() =>
    import("../Pages/Media/index"));

const ImageGallery = lazy(() =>
    import("../Pages/Media/ImageGallery/index"));

const VideoGallery = lazy(() =>
    import("../Pages/Media/VideoGallery/index"));

const PatientProfile = lazy(() =>
    import("../Pages/PatientProfile/index"));

const PatientProfileForm = lazy(() =>
    import("../Pages/PatientProfile/PatientProfileForm/index"));

const Resetpassword = lazy(() =>
    import("../Pages/resetpassword/index"));

const MyPatient = lazy(() =>
    import("../Pages/DoctorProfile/MyPatient/index"));

const AppointmentManagement = lazy(() =>
    import("../Pages/DoctorProfile/AppointmentManagement/index"));

const ReportsImages = lazy(() =>
    import("../Pages/PatientProfile/index"));

const PaymentHistory = lazy(() => 
import("../Pages/PatientProfile/index"));

const PaymentHistoryDoctor = lazy(() => 
    import("../Pages/DoctorProfile/index"));

const DoctorNotes = lazy(() =>
    import("../Pages/PatientProfile/index"));

const Prescription = lazy(() =>
import("../Pages/PatientProfile/index"));

const LabRequest = lazy(() =>
import("../Pages/PatientProfile/index"));

const ImageRequest = lazy(() =>
    import("../Pages/PatientProfile/index"));

const Notification = lazy(() =>
    import("../Pages/Notification/index"));

const AppointmentHistory = lazy(() =>
    import("../Pages/PatientProfile/index"));

const MyAppointments = lazy(() =>
    import("../Pages/PatientProfile/index"));

const TicketSummary = lazy(() =>
    import("../Pages/PatientProfile/index"));

const TicketDisputeDetail = lazy(() =>
    import("../Pages/PatientProfile/TicketDisputeDetails/index"));

const MedicalInformation = lazy(() =>
    import("../Pages/MedicalInformation/BreadCrumb"));

const PatientAppointmentHistory = lazy(() =>
    import("../Pages/MedicalInformation/BreadCrumb"));

const PatientsDoctorProfile = lazy(() =>
    import("../Pages/PatientProfile/PatientsDoctorProfile/index"));

const SearchDoctor = lazy(() =>
    import("../Pages/SearchDoctor/index"));

const VideoChat = lazy(() =>
    import("../Pages/VideoChat/index"));


const VideoChat2 = lazy(() =>
    import("../Pages/VideoChat2/index"));



const Routes = (props: any) => (
    <Router>
        <Header {...props} />
        <Layout>
            <Switch>

                {/* Auth */}
                <AuthRoute path={`${process.env.PUBLIC_URL}/login`}
                    component={Login} />

                <AuthRoute path={`${process.env.PUBLIC_URL}/reset-password/:email?/:token?`}
                    component={Resetpassword} />

                <AuthRoute path={`${process.env.PUBLIC_URL}/patient-reset-password/:email?/:token?`}
                    component={Resetpassword} />

                <AuthRoute path={`${process.env.PUBLIC_URL}/doctor-reset-password/:email?/:token?`}
                    component={Resetpassword} />

                <AuthRoute path={`${process.env.PUBLIC_URL}/forgot`}
                    component={Forgot} />

                {/* Public */}
                <NewPublicRoute exact path={`${process.env.PUBLIC_URL}/`}
                    component={Home} />

                <NewPublicRoute exact path={`${process.env.PUBLIC_URL}/verify/:email?/:activation_hash?`}
                    component={Home} />

                <NewPublicRoute exact path={`${process.env.PUBLIC_URL}/doctor-verify/:email?/:activation_hash?`}
                    component={Home} />

                <NewPublicRoute exact path={`${process.env.PUBLIC_URL}/patient-verify/:email?/:activation_hash?`}
                    component={Home} />

                <NewPublicRoute exact path={`${process.env.PUBLIC_URL}/patient_payment_success`}
                    component={Home} />

                <NewPublicRoute exact path={`${process.env.PUBLIC_URL}/patient_payment_error`}
                    component={Home} />

                <NewPublicRoute exact path={`${process.env.PUBLIC_URL}/home`}
                    component={Home} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/contact-us`}
                    component={Contactus} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/careers`}
                    component={Career} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/privacy-policy`}
                    component={Privacy} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/terms-conditions`}
                    component={Terms} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/faq`}
                    component={Faq} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/howitworks`}
                    component={HowItWorks} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/sitemap`}
                    component={SiteMap} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/rules-regulations`}
                    component={Rules} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/about-us`}
                    component={Aboutus} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/photo-gallery`}
                    component={Media} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/image-gallery`}
                    component={ImageGallery} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/video-gallery`}
                    component={VideoGallery} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/medical-information/:slug`}
                    component={MedicalInformation} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/app-history/:slug`}
                    component={PatientAppointmentHistory} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/search-doctor`}
                    component={SearchDoctor} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/blog`}
                    component={Blog} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/blog-detail/:slug`}
                    component={BlogDetail} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/videochat`}
                    component={VideoChat} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/videochat2`}
                    component={VideoChat2} />

                {/* Doctors */}
                <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor-profile`}
                    component={UserAuth(DoctorProfile, [RoleType.Doctor])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/my-patients`}
                    component={UserAuth(DoctorProfile, [RoleType.Doctor])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/appointment-management`}
                    component={UserAuth(DoctorProfile, [RoleType.Doctor])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor-profile-form`}
                    component={UserAuth(DoctorProfileForm, [RoleType.Doctor])} />

                {/* Patients */}
                <PrivateRoute path={`${process.env.PUBLIC_URL}/patient-profile`}
                    component={UserAuth(PatientProfile, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/patient-profile-form`}
                    component={UserAuth(PatientProfileForm, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/report-images`}
                    component={UserAuth(ReportsImages, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/prescription`}
                    component={UserAuth(Prescription, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/prescription-print/:id`}
                    component={UserAuth(PrescriptionPrint, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/lab-print/:id`}
                    component={UserAuth(LabPrint, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/image-print/:id`}
                    component={UserAuth(ImagePrint, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor-notes`}
                    component={UserAuth(DoctorNotes, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/lab-request`}
                    component={UserAuth(LabRequest, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/image-request`}
                    component={UserAuth(ImageRequest, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/appointment-history`}
                    component={UserAuth(AppointmentHistory, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/my-appointments/:name`}
                    component={UserAuth(MyAppointments, [RoleType.Patient])} />

                <NewPublicRoute path={`${process.env.PUBLIC_URL}/view-doctor-profile/:slug/`}
                    component={PatientsDoctorProfile} />

                {/* Patients - Doctors */}
                <PrivateRoute path={`${process.env.PUBLIC_URL}/notifications`}
                    component={UserAuth(Notification, [RoleType.Patient, RoleType.Doctor])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/patient-wallet-logs`}
                    component={UserAuth(PaymentHistory, [RoleType.Patient, RoleType.Doctor])} />

                {/* <PrivateRoute path={`${process.env.PUBLIC_URL}/patient_payment_success`}
                    component={UserAuth(PaymentHistory, [RoleType.Patient, RoleType.Doctor])} /> */}

                {/* <PrivateRoute path={`${process.env.PUBLIC_URL}/patient_payment_error`}
                    component={UserAuth(PaymentHistory, [RoleType.Patient, RoleType.Doctor])} /> */}

                <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor-wallet-logs`}
                    component={UserAuth(PaymentHistoryDoctor, [RoleType.Patient, RoleType.Doctor])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/settings`}
                    component={UserAuth(AccountSettings, [RoleType.Patient, RoleType.Doctor])} />
                <PrivateRoute path={`${process.env.PUBLIC_URL}/bank-details`}
                    component={UserAuth(BankDetails, [RoleType.Patient, RoleType.Doctor])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/ticket-summary`}
                    component={UserAuth(TicketSummary, [RoleType.Patient])} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/ticket-detail/:id`}
                    component={UserAuth(TicketDisputeDetail, [RoleType.Patient])} />
                <PrivateRoute path={`${process.env.PUBLIC_URL}/ticket-detail-dispute/:id`}
                    component={UserAuth(TicketDisputeDetail, [RoleType.Patient])} />
                <PrivateRoute path={`${process.env.PUBLIC_URL}/ticket-detail-chat/:id`}
                    component={UserAuth(TicketDisputeDetail, [RoleType.Patient])} />

                {/* {routes.map((route, i) => {
                    if (route.beforeAuth) {
                        return <AuthRoute key={i} {...route} />
                    } else if (route.auth) {

                        
                        if (route.permissionTo) {
                            UserAuth(route.permissionTo);
                        }

                        // if (route.permissionTo == 'doctore') {
                        //     return <DoctoreRoute key={i} {...route} />
                        // }else if (route.permissionTo == 'patient') {
                        //     return <PatientRoute key={i} {...route} />
                        // }
                        return <PrivateRoute key={i} {...route} />
                    } else {
                        return <NewPublicRoute key={i} {...route} />
                    }
                })} */}
            </Switch>
        </Layout>
        <Footer  {...props} />
    </Router>
)

export default Routes
