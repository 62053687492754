import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import PublicLayout from './PublicLayout'
import AlertMessage from '../../global/AlertMessage'

class Layout extends React.Component<any,any>{
    static propTypes = {
        children: PropTypes.node.isRequired,
    }
    render() {
        
        const { children, ...props } = this.props
        if(this.props.alertMessage != undefined){
            return (
                <>
                    <AlertMessage alert={this.props.alertMessage}/>
                    <PublicLayout {...props}>{children}</PublicLayout>
                </>
            )
        } else {
            return <PublicLayout {...props}>{children}</PublicLayout>
        }
        // if (this.props.auth.isAuthenticated) {
        //     return <PrivateLayout {...props}>{children}</PrivateLayout>
        // }
    }
}

const mapStateToProps = (state:any) => {
    return {
        auth: state.auth,
        alertMessage:state.global.alertMessage,
    }
}

export default connect(mapStateToProps)(Layout);