import * as constant from '../../../config/constant';

const Joi =
  constant.default.platform == 'Mobile'
    ? require('joi-react-native')
    : require('@hapi/joi');

   export var notesSchema = Joi.object().keys({
    title: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
    assessment: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
    plan: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
  });

   export var prescriptionSchema = Joi.object().keys({
    prescription_title: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
    drug_name: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
    dose: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
    frequency: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
    duration: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
  });

   export var labSchema = Joi.object().keys({
    specimen: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
    test_name: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
    indication: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
  });

   export var imageSchema = Joi.object().keys({
    body_part: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
    imaging_modality: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
    image_indication: Joi.string()
    .trim()
      .min(2)
      .max(200)
      .required(),
  });

// export default schema;
