import {
    MEDIA_TYPES
  } from './action-types';
  
  import { MiddleWare } from '../../interfaces/auth';
  
  const initialState: MiddleWare [] = [];
  
  const reducer = (state = initialState,action: MEDIA_TYPES) : MiddleWare[] => {
    switch (action.type) {
      case "BANNER_IMAGE":
        return bannerImage(state,action.payload)
      case "GALLERY":
        return gallery(state,action.payload)
      case "VIDEOS":
        return videos(state,action.payload)
      default:
        return state;
    }
  };
  
  export default reducer;
  
  function bannerImage(state:any, payload:any) {
    return {
      ...state,
      bannerImage:payload,
      loading:false,
    };
  }
  function gallery(state:any, payload:any) {
    return {
      ...state,
      gallery:payload,
      loading:false,
    };
  }
  function videos(state:any, payload:any) {
    return {
      ...state,
      videos:payload,
      loading:false,
    };
  }