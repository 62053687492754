import { MiddleWare, PayLoad } from '../../interfaces/auth';


export const GET_APPOINTMENT_HISTORY = 'GET_APPOINTMENT_HISTORY';
export const SHOW_ALERT = 'SHOW_ALERT';
export const DOCTOR_NOTES = 'DOCTOR_NOTES';
export const DOCTOR_PRESCRIPTION = 'DOCTOR_PRESCRIPTION';
export const DOCTOR_LAB_REQUEST = 'DOCTOR_LAB_REQUEST';
export const DOCTOR_IMAGE_REQUEST = 'DOCTOR_IMAGE_REQUEST';
export const REPORTS_IMAGES = 'REPORTS_IMAGES';
export const CHECK_ROUTE = 'CHECK_ROUTE';

interface GET_APPOINTMENT_HISTORY_ACTION {
    type: typeof GET_APPOINTMENT_HISTORY
    payload: PayLoad;
}
interface SHOW_ALERT_ACTION {
    type: typeof SHOW_ALERT
    payload: PayLoad;
}
interface DOCTOR_NOTES_ACTION {
    type: typeof DOCTOR_NOTES
    payload: PayLoad;
}
interface DOCTOR_PRESCRIPTION_ACTION {
    type: typeof DOCTOR_PRESCRIPTION
    payload: PayLoad;
}
interface DOCTOR_LAB_ACTION {
    type: typeof DOCTOR_LAB_REQUEST
    payload: PayLoad;
}
interface DOCTOR_IMAGE_ACTION {
    type: typeof DOCTOR_IMAGE_REQUEST
    payload: PayLoad;
}
interface REPORTS_IMAGES_ACTION {
    type: typeof REPORTS_IMAGES
    payload: PayLoad;
}
interface CHECK_ROUTE_ACTION {
    type: typeof CHECK_ROUTE
    payload: PayLoad;
}

export type APPOINTMENT_TYPES = GET_APPOINTMENT_HISTORY_ACTION | SHOW_ALERT_ACTION | DOCTOR_NOTES_ACTION | DOCTOR_PRESCRIPTION_ACTION | DOCTOR_LAB_ACTION | DOCTOR_IMAGE_ACTION | REPORTS_IMAGES_ACTION | CHECK_ROUTE_ACTION;