import Http from '../utils/Http';
import Transformer from '../utils/Transformer';
import * as constant from '../../config/constant';

export function bookAppointmentApi(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments`, data)
        // Http.get(`api/users/get-patient-profile`,data)
        .then(res => {
          // //console.log('Book an appointment response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function bookAppointmentQueueApi(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/queue`, data)
        // Http.get(`api/users/get-patient-profile`,data)
        .then(res => {
          //console.log('Book an appointment response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function CheckPreviousAppointmentApi() {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.get(`api/appointments/checkprevious`)
        // Http.get(`api/users/get-patient-profile`,data)
        .then(res => {
          //console.log('Book an appointment response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function getAppointmentApi(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/list`, data)
        // Http.get(`api/users/get-patient-profile`,data)
        .then(res => {
          //console.log('Book an appointment response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function getAppointmentsQueueApi(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/queue/list`, data)
        // Http.get(`api/users/get-patient-profile`,data)
        .then(res => {
          //console.log('Book an appointment response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function GetusersAllAppointments() {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.get(`api/appointments/userwise`)
        // Http.get(`api/users/get-patient-profile`,data)
        .then(res => {
          //console.log('appointments response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function getPatientAppointmentApi(doctor_id: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.get(`api/appointments/${doctor_id}`)
        // Http.get(`api/users/get-patient-profile`,data)
        .then(res => {
          //console.log('Book an appointment response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function checkappointmentroom(roomname: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.get(`api/appointments/checkappointmentroom/${roomname}`)
        // Http.get(`api/users/get-patient-profile`,data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function CancelAppointmentQueue(appointmentid: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.delete(`api/appointments/queue/${appointmentid}`)
        // Http.get(`api/users/get-patient-profile`,data)
        .then(res => {
          //console.log('Book an appointment response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function checkBookedAppointmentStatusBeforeStartCall(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(
        `api/appointments/checkBookedAppointmentStatusBeforeStart`,
        data,
      )
        .then(res => {
          //console.log('Book an appointment response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function checkQueueStatusBeforeStartCall(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/checkQueueStatusBeforeStartCall`, data)
        .then(res => {
          //console.log('Book an appointment response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function AppointmentChangeStatus(appointmentid: any, status: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.get(`api/appointments/changestatus/${appointmentid}/${status}`)
        // Http.get(`api/users/get-patient-profile`,data)
        .then(res => {
          //console.log('Book an appointment response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}
export function GetDisputeReasons() {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.get(`api/get-dispute-reasons`)
        // Http.get(`api/users/get-patient-profile`,data)
        .then(res => {
          //console.log('Book an appointment response: ', res.data);
          const data = Transformer.fetch(res.data);
          return resolve(data);
          // if (res.status === 200) {
          // }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}
export function addDisputeData(data: any) {
  // const config = { headers: { 'Content-Type': 'multipart/form-data', 'Accept': "application/x-www-form-urlencoded"}}
  // const config = { headers: { 'Content-Type': 'application/json'}}
  // const config = { headers: { 'Content-Type': 'multipart/form-data'}}
  // const config = {
  //     headers: { 'content-type': 'multipart/form-data' }
  // }
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    console.log('Profile Access Token: ', constant.default.accessToken);
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/dispute/add`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(doctorActions.patientProfileForm(data))
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}
/**
 * this function is used to display dispute listing .
 * @param data 
 *  data = {
      page:1,
      pagelimit:10,
    }
 */
export function getTicketSummary(data: any) {
  // const config = { headers: { 'Content-Type': 'multipart/form-data', 'Accept': "application/x-www-form-urlencoded"}}
  // const config = { headers: { 'Content-Type': 'application/json'}}
  // const config = { headers: { 'Content-Type': 'multipart/form-data'}}
  // const config = {
  //     headers: { 'content-type': 'multipart/form-data' }
  // }
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    console.log('Profile Access Token: ', constant.default.accessToken);
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/dispute/list`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(doctorActions.patientProfileForm(data))
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}