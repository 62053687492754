import { MiddleWare,PayLoad } from '../../interfaces/auth';

// language action types
export const UPDATE_LANGUAGE_INITIAL_ACTION = 'UPDATE_LANGUAGE_INITIAL_ACTION';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';

interface LANGUAGE_INITIAL_ACTION {
  type: typeof UPDATE_LANGUAGE_INITIAL_ACTION
  language:string;
}

interface LANGUAGE_ACTION {
  type: typeof UPDATE_LANGUAGE
  language:string;
}

export type LANGUAGE_INITIAL_TYPES = LANGUAGE_INITIAL_ACTION | LANGUAGE_ACTION;