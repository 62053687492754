import React, { Component } from "react";
import { connect } from "react-redux"
import { getUserUsingToken } from '../lib/apis/auth';

const RoleType = {
  Doctor: 3,
  Patient: 2
}

export default function (ComposedComponent, Permissions) {
  class UserAuth extends Component {

    async componentWillMount() {
      if (this.props.isAuthenticated != true && localStorage.getItem('access_token')) {
        
        await this.GetTokenUser();
      } else {
        this.CheckRole();
      }

      
      

    }

    async GetTokenUser() {
      this.props.getUserUsingToken({}).then((response) => {
        this.CheckRole();
      })
        .catch((error, statusCode, message, e) => {
          this.props.history.push(`${process.env.PUBLIC_URL}`);
        });
    }

    async CheckRole() {
      if (Permissions && Permissions.length > 0) {
        if (this.props.currentUser && this.props.currentUser.type) {
          if (!Permissions.includes(this.props.currentUser.type)) {
            if (this.props.isAuthenticated && this.props.currentUser.type == 3) {
              this.props.history.push(`${process.env.PUBLIC_URL}/doctor-profile`);
            } else {
              this.props.history.push(`${process.env.PUBLIC_URL}`);
            }
          }
        } else if (!Permissions.includes(1)) {
          this.props.history.push(`${process.env.PUBLIC_URL}`);
        }
      } else {
        this.props.history.push(`${process.env.PUBLIC_URL}`);
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    return {
      currentUser: state.auth.currentUser,
      isAuthenticated: state.auth.isAuthenticated
    }
  }

  return connect(mapStateToProps, { getUserUsingToken })(UserAuth);
}
