import Http from '../utils/Http';
import * as notificationActions from '../store/notification/action';
import Transformer from '../utils/Transformer';
import * as constant from '../../config/constant';

// remove doctor availability
export function removeDoctorAvailability(id: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/doctor/remove-doctor-availability`, id)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(notificationActions.getUnreadCountNotification(data));
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// Get Doctor Single Availability
export function getDoctorSingleAvailability(id: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/doctor/get-doctor-single-availability`, id)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(notificationActions.markReadNotificationCount(data));
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

// add/edit doctor availability
export function addDoctorAvailability(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';

  

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/doctor/add-doctor-available-slot`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(notificationActions.markReadNotification(data));
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (
          //   statusCode === 422 ||
          //   statusCode === 403 ||
          //   statusCode === 404 ||
          //   statusCode === 401
          // ) {
          //   data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

// get doctor availability
export function getDoctorAvailability(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/doctor/get-doctor-availability`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(notificationActions.markReadNotification(data));
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }
          return reject(data);
        });
    });
}

export function getDoctorSlotsForBooking(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/doctor/get-doctor-availability-for-booking`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            return resolve(res);
          }
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };

          if (
            statusCode === 422 ||
            statusCode === 403 ||
            statusCode === 404 ||
            statusCode === 401
          ) {
            data.error = err.response.data;
          }

          return reject(data);
        });
    });
}

export function getAppointmentNotes(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    // Http.defaults.headers.common['auth'] = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJVMkZzZEdWa1gxOVVXbjVKeDgzU01zT0VaQ2hqa0ZWTTFWbnRndVZkaE1TUWdxZlU0U2UyY2FDRmVTMGQzbnhrZVJUaVZwVFk0Rnc5MTNIQkFsQVZzQk51OXhEUXZ5c2xhVGg4SDZtemVJVTVEazl5S25Ib2g2TUU5VEZVWGl6Y0NqMTBHQm9obkp1K0ZYSVZBdHZHTVE9PSIsImlhdCI6MTU5MDQ4NjY2OSwiZXhwIjoxNjIyMDIyNjY5fQ.M6r3an_Q-OfN0-cnvWNEOrqce5ltxH0pipjvzhVjuYw'
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-appointment-notes`, data)
        .then(res => {
          
          const data = Transformer.fetch(res.data);
          return resolve(data);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function addAppointmentNotes(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/add-appointment-notes`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(notificationActions.markReadNotification(data));
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (
          //   statusCode === 422 ||
          //   statusCode === 403 ||
          //   statusCode === 404 ||
          //   statusCode === 401
          // ) {
          //   data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function addAppointmentImageRequest(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/add-appointment-imagerequest`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(notificationActions.markReadNotification(data));
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (
          //   statusCode === 422 ||
          //   statusCode === 403 ||
          //   statusCode === 404 ||
          //   statusCode === 401
          // ) {
          //   data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function addAppointmentLabRequest(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/add-appointment-labrequest`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(notificationActions.markReadNotification(data));
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (
          //   statusCode === 422 ||
          //   statusCode === 403 ||
          //   statusCode === 404 ||
          //   statusCode === 401
          // ) {
          //   data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function addAppointmentPrescription(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  Http.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
  Http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  Http.defaults.headers.common['Access-Control-Allow-Methods'] =
    'POST, GET, PUT, OPTIONS, DELETE';
  Http.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type';
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/add-appointment-prescription`, data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(notificationActions.markReadNotification(data));
            return resolve(res);
          }
          // return resolve()
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (
          //   statusCode === 422 ||
          //   statusCode === 403 ||
          //   statusCode === 404 ||
          //   statusCode === 401
          // ) {
          //   data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function getAppointmentImageRequest(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    // Http.defaults.headers.common['auth'] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJVMkZzZEdWa1gxK3RiL0NuVUZlUldvem5UZ1hSOXNrWFc0NUI3TlRpLzdNSzNQMVFTaGRxanJPSXlVeWhtdjB0bG9uV0xMMHlUSVdaanZaOExRMDczbFFYNE91WTJqZmF1VDBTYTVSZ3RWNkttbS9jU3IrSm00eWtiNVZWTzluRFp6em1PT0cyMDd3bFNLRDUyOGdlMUE9PSIsImlhdCI6MTU5MDU3NjM5OSwiZXhwIjoxNjIyMTEyMzk5fQ.480OPgxmdMtlKnQwInUPB_t21x0rIML-hPw8Az4bQtU"
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-appointment-imagerequest`, data)
        .then(res => {
          
          const data = Transformer.fetch(res.data);
          return resolve(data);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function getAppointmentLabRequest(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    // Http.defaults.headers.common['auth'] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJVMkZzZEdWa1gxK3RiL0NuVUZlUldvem5UZ1hSOXNrWFc0NUI3TlRpLzdNSzNQMVFTaGRxanJPSXlVeWhtdjB0bG9uV0xMMHlUSVdaanZaOExRMDczbFFYNE91WTJqZmF1VDBTYTVSZ3RWNkttbS9jU3IrSm00eWtiNVZWTzluRFp6em1PT0cyMDd3bFNLRDUyOGdlMUE9PSIsImlhdCI6MTU5MDU3NjM5OSwiZXhwIjoxNjIyMTEyMzk5fQ.480OPgxmdMtlKnQwInUPB_t21x0rIML-hPw8Az4bQtU"
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-appointment-labrequest`, data)
        .then(res => {
          
          const data = Transformer.fetch(res.data);
          return resolve(data);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function getAppointmentPrescription(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    // Http.defaults.headers.common['auth'] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJVMkZzZEdWa1gxK3RiL0NuVUZlUldvem5UZ1hSOXNrWFc0NUI3TlRpLzdNSzNQMVFTaGRxanJPSXlVeWhtdjB0bG9uV0xMMHlUSVdaanZaOExRMDczbFFYNE91WTJqZmF1VDBTYTVSZ3RWNkttbS9jU3IrSm00eWtiNVZWTzluRFp6em1PT0cyMDd3bFNLRDUyOGdlMUE9PSIsImlhdCI6MTU5MDU3NjM5OSwiZXhwIjoxNjIyMTEyMzk5fQ.480OPgxmdMtlKnQwInUPB_t21x0rIML-hPw8Az4bQtU"
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/appointments/get-appointment-prescription`, data)
        .then(res => {
          
          const data = Transformer.fetch(res.data);
          return resolve(data);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}