import React from 'react';
import '../../css/font/flaticons/flaticon.css';
import '../../css/bootstrap.min.css';
import '../../css/bootstrap_limitless.min.css';
import '../../css/bootstrap_limitless.css';
import '../../css/doctoray-custom.css';
import '../../css/doctoray-header.css';
import '../../css/doctoray-responsive.css';
import '../../css/styles.css';
import '../../css/font/fontawesome/styles.min.css';
import './styles.css';
import $ from 'jquery';
import { connect } from 'react-redux';
import { constantData } from '../../lib/config/constant';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import 'opentok-solutions-css';
import { Row, Col, } from 'reactstrap';
import { socketConnect } from 'socket.io-react';
import * as constant from '../../lib/config/constant'
import * as classNames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import BlockUi from 'react-block-ui';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import AbstractVideoChat from '../../lib/abstractComponents/videoChatBox';
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from 'moment-timezone';
import Picker from 'emoji-picker-react';
import { timeZone } from '../../lib/config/constant'
import { Send, Paperclip, Smile } from "react-feather";
var Sound = require('react-sound').default;

const OT = require('@opentok/client');
const customStyles = {
    content: {
        width: '65%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};


let timer: any;
class VideoChat extends AbstractVideoChat {

    handleMessageInput = (e:any) => {
        this.setState({ message: e.target.value }, () => {
            if(this.state.message.trim().length > 0){
                this.setState({
                    showChatTypeError:false,
                    showChatTypeMessage:'',
                    showattachmentError:false,
                    showattachmentMessage:'',
                })
            }
        });
    }

    render() {
        const { isModalOpen, VideoSession, isReceivedCall, PartnerUser, resDisputeChatList, isReadytoJoincall, isViewRecording, isBusyonanothercall, isDeclineCall, isDirectCall, PartnerName, TokboxCurrentUser, TokboxPartnerUser, emojiToggle, message, isTyping  } = this.state;
        return (
            <>
            <Sound
                url={`${constantData.saticimages}ringtone.mp3`}
                playStatus={(isModalOpen && !VideoSession)?Sound.status.PLAYING:Sound.status.STOPPED}
                playFromPosition={0}
                loop={true}
                // onLoading={this.handleSongLoading}
                // onPlaying={this.handleSongPlaying}
                // onFinishedPlaying={this.handleSongFinishedPlaying}
                />
                <SweetAlert title={this.props.intl['appointment']}
                    warning
                    show={this.state.notEnoughMoneyalert}
                    confirmBtnText={this.props.intl['okay']}
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        this.setState({ notEnoughMoneyalert: false });
                        this.setState({ VideoSession: null, isReceivedCall: false });
                        this.setState({ isModalOpen: false });
                        this.props.history.goBack();
                    }}
                    btnSize="sm"
                >
                    {this.state.appointmentrequestmessage}
                </SweetAlert>
                <SweetAlert title={this.props.intl['appointment']}
                    warning
                    show={this.state.Appointmentalert}
                    confirmBtnText={this.props.intl['okay']}
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        this.setState({ Appointmentalert: false })
                    }}
                    btnSize="sm"
                >
                    {this.state.appointmentrequestmessage}
                </SweetAlert>
                <SweetAlert title={this.props.intl['overdue_call_duration']}
                    warning
                    show={this.state.mandatoryAlert}
                    showCancel
                    confirmBtnText={this.props.intl['continue']}
                    cancelBtnText={this.props.intl['disconnect_call']}
                    cancelBtnBsStyle="default"
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        this.setState({ mandatoryAlert: false })
                    }}
                    onCancel={() => {
                        this.DisconnectCall();
                    }}
                    btnSize="sm"
                >
                    {this.props.intl['videoalertmsg']}
                </SweetAlert>
                <div>
                    {/* {isModalOpen ?
                        <div className="modal-overlay"></div>
                        : ''} */}
                    <Modal
                        isOpen={isModalOpen && PartnerName && PartnerName != undefined  && PartnerName != 'undefined'  && PartnerName != ''  && PartnerName != null}
                        // onAfterOpen={afterOpenModal}
                        ariaHideApp={false}
                        onRequestClose={this.CloseModalPopup}
                        style={customStyles}
                        contentLabel="Instant Call"
                        className={!VideoSession ? 'precall_modal' 
                            : !this.state.ConsultationTypes.includes(2) && !this.state.ConsultationTypes.includes(3) ? 'chatOnlyModal' : 'fullscreenVideoChat'
                        }
                    >
                        <BlockUi tag="div" className='modal-body' blocking={this.state.isLoadingDisplay}>
                            {!VideoSession ?
                                <div className="App-mask">
                                    <Row className="w-100 text-center">
                                        {!isReceivedCall ?
                                            !isDirectCall ?
                                                <Col md="12">
                                                    {this.props.isAuthenticated && this.props.currentUser && this.props.currentUser.type == 3 && this.state.Appointment && this.state.Appointment.patient && (isBusyonanothercall || isDeclineCall) ?
                                                        <h1>{this.getNameAsPerLanguage(this.state.Appointment.doctor.Profile.title,this.state.Appointment.doctor.englishName,this.state.Appointment.doctor.arabicName )} {isBusyonanothercall ? this.props.intl["is_busy_on_another_call"] : isDeclineCall ? this.props.intl["decline_your_call"] : ''} </h1>
                                                        : this.props.isAuthenticated && this.props.currentUser && this.state.Appointment && this.state.Appointment.doctor && (isBusyonanothercall || isDeclineCall) ?
                                                            <h1>{this.getNameAsPerLanguage(this.state.Appointment.doctor.Profile.title,this.state.Appointment.doctor.englishName,this.state.Appointment.doctor.arabicName )} {isBusyonanothercall ? this.props.intl["is_busy_on_another_call"] : isDeclineCall ? this.props.intl["decline_your_call"] : ''}</h1>
                                                    : <>
                                                    {/* // ---> User Photo will be display */}
                                                    <div className="user-photo">
                                                        <img src={constantData.profileImageUrl + TokboxPartnerUser.profile_pic} className="d-block mx-auto"/>
                                                    </div>  
                                                    <h1>{this.props.intl["conecting_to"]} {PartnerName}</h1>
                                                    
                                                    </>  
                                                    }
                                                </Col>
                                                :
                                                <Col md="12">
                                                    {PartnerName ?
                                                    <>
                                                        {/* // ---> User Photo will be display */}
                                                        <div className="user-photo">
                                                            <img src={constantData.profileImageUrl + TokboxPartnerUser.profile_pic} className="d-block mx-auto"/>
                                                        </div>  
                                                        <h1>{PartnerName}</h1>
                                                        
                                                        </>      
                                                        : ''
                                                    }
                                                </Col>
                                            : ''
                                        }
                                        {/* {!isReceivedCall && !isReadytoJoincall ? */}
                                        {!isReceivedCall ?
                                            <Col md="12" className="mt-0 mt-sm-5">
                                                {/* <button className="btn btn-primary" onClick={() => this.bookAppointmentQueue()}>{this.props.intl["call_now"]} </button> */}
                                                <button className="btn btn-danger btnFloatNone flaticon-phone-3" onClick={() => this.UserCutCall()}>{/* {this.props.intl["decline_call"]}*/} </button>
                                            </Col>
                                            : ''}
                                        {/* {isReadytoJoincall ?
                                            <Col md="12">
                                                <button className="btn btn-primary" onClick={() => this.JoinCall()}>{this.props.intl["join_call"]}</button>
                                            </Col>
                                            : ''} */}
                                        {isReceivedCall && PartnerName ?
                                            <Col md="12">
                                                {/* User Photo will be display */}
                                                <div className="user-photo">
                                                    <img src={constantData.profileImageUrl + TokboxPartnerUser.profile_pic} className="d-block mx-auto"/>
                                                </div>
                                                <h1>{PartnerName} {this.props.intl["is_calling"]} .....</h1>
                                            </Col> : ''}
                                        {isReceivedCall ?
                                            <Col md="12" className="mt-0 mt-sm-5">
                                                <button className="btn btn-primary clickable flaticon-phone-4" onClick={() => this.ReceiveCall()}>{/*{this.props.intl["receive_call"]}*/}</button>
                                                <button className="btn btn-danger ml-3 flaticon-phone-3" onClick={() => this.DeclineCall()}>{/* {this.props.intl["decline_call"]}*/} </button>
                                            </Col> : ''}
                                    </Row>
                                </div> : ''}
                            <Row>
                                {/* <Col md={this.state.ConsultationTypes.includes(1) ? '7' : '12'} className={this.state.ConsultationTypes.includes(2) || this.state.ConsultationTypes.includes(3) ? '' : 'd-none'}> */}
                                {/* <Col lg={(this.props.currentUser.type == 3 || (this.props.currentUser.type == 2 && this.state.ConsultationTypes.includes(1))) ? '7' : '12'} xl={(this.props.currentUser.type == 3 || (this.props.currentUser.type == 2 && this.state.ConsultationTypes.includes(1))) ? '9' : '12'} className={this.state.ConsultationTypes.includes(2) || this.state.ConsultationTypes.includes(3) ? '' : 'd-none'}> */}
                                <Col lg={(this.props.currentUser.type == 3 || this.props.currentUser.type == 2) ? '7' : '12'} xl={(this.props.currentUser.type == 3 || this.props.currentUser.type == 2) ? '9' : '12'} className={this.state.ConsultationTypes.includes(2) || this.state.ConsultationTypes.includes(3) ? '' : 'd-none'}>
                                    <div className="video-user-details">
                                    {VideoSession ?
                                        <Row className="align-items-center">
                                            <Col md="8">
                                                <div className="d-flex align-items-center">
                                                    {/* User Profile photo will be displayed */}
                                                    <div className="user-photo">
                                                        <img src={constantData.profileImageUrl + TokboxPartnerUser.profile_pic} className="d-block"/>
                                                    </div>
                                                    {/* User name will be displayed here and on click on it will redirect to user profile */}
                                                    <p className="ml-1 mb-0">
                                                        {PartnerName}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                {/* Call duration */}
                                                <div className={"call-duration-timer " + (this.props.languages == 'en' ? " text-right" : " text-left")}>
                                                    {/* <p className="text-right mb-0">12:12:12</p> */}
                                                    {this.state.Appointment.timeDate ?
                                                    <span className={this.state.Appointment.lessthenminute ? 
                                                    'dangertimetickerblinking' : 'clsTimetickerspan'}>{this.state.Appointment.timeticker}</span> : ''}
                                                </div>
                                            </Col>
                                        </Row>
                                    :''}
                                    </div>

                                    {VideoSession ?
                                        <>
                                            <OTSession apiKey={VideoSession.apiKey} sessionId={VideoSession.sessionId} token={VideoSession.token} >
                                                <OTPublisher properties={{
                                                    publishVideo: this.state.publishVideo,
                                                    publishAudio: this.state.publishAudio,
                                                    name: this.getNameAsPerLanguage(this.props.currentUser.title,this.props.currentUser.englishName,this.props.currentUser.arabicName ),
                                                    showControls: true,
                                                    width: '120px',
                                                    height: '120px',
                                                    insertMode: 'append'
                                                }} className={'video-container' } />
                                                <div className="position-relative">
                                                    <div id="controls" className={'App-control-container'}>
                                                        <Row>
                                                            <Col md='5'>
                                                                <div className="video-btn-group-left">
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                        {/* {this.props.isAuthenticated && this.props.currentUser && this.props.currentUser.type == 3 ?
                                                                            <div className="ots-video-control position-relative custom-center-btn">
                                                                                <button type="button" className={'btn ' + (this.state.isRecording ? 'btn-danger' : 'btn-primary')} title="Recording" onClick={() => this.ToggleRecording()}> {this.state.isRecording ? ' ' : ' '}</button>
                                                                            </div>
                                                                        : ''} */}
                                                                        {/* <div className={classNames('ots-video-control circle modal-screen-toggle')}/> */}
                                                                        {this.props.currentUser.type == 3 || this.state.orgConsultationTypes.includes(2) || this.state.RunTimeConsultationTypes.includes(2)?
                                                                            <div className={classNames('ots-video-control circle audio', { muted: !this.state.publishAudio })} onClick={() => this.ToggleAudio()} />    
                                                                        :''}
                                                                        {this.props.currentUser.type == 3 || this.state.orgConsultationTypes.includes(3) || this.state.RunTimeConsultationTypes.includes(3)?
                                                                            <div className={classNames('ots-video-control circle video', { muted: !this.state.publishVideo })} onClick={() => this.ToggleVideo()} />
                                                                        :''}
                                                                        {this.props.currentUser.type == 3 || this.state.orgConsultationTypes.includes(1) || this.state.RunTimeConsultationTypes.includes(1)?
                                                                            // <div className={classNames('ots-video-control circle flaticon-comment custom-video-btn')} title="Message"  onClick={() => this.setSideForm('chat')} />  
                                                                            <div className={this.state.ConsultationTypes.includes(1)?'ots-video-control circle custom-video-btn flaticon-comment':'ots-video-control circle custom-video-btn flaticon-forbbiden'} title="Message"  onClick={() => this.setSideForm('chat')} />  
                                                                            // <div className={classNames('ots-video-control circle custom-video-btn') + (this.state.orgConsultationTypes.includes(1) || this.state.RunTimeConsultationTypes.includes(1))?classNames('flaticon-comment'):classNames('flaticon-comment')} title="Message"  onClick={() => this.setSideForm('chat')} />  
                                                                        :''}
                                                                        {/* <div className={'ots-video-control circle end-call'} onClick={() => this.DisconnectCall()} /> */}
                                                                    </div>            
                                                                </div>
                                                            </Col>
                                                            <Col md='2'>
                                                                <div className="video-end-call-btn">
                                                                    <div className={'ots-video-control circle end-call'} onClick={() => this.DisconnectCall()} />
                                                                </div>
                                                            </Col>
                                                            <Col md='5'>
                                                                <div className="video-btn-group-right"> 
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                    {/* {this.props.isAuthenticated && this.props.currentUser && this.props.currentUser.type == 3 ? */}
                                                                    <>
                                                                        <div className={classNames('ots-video-control circle flaticon-document custom-video-btn')} title={this.props.intl['notes']} onClick={() => this.setSideForm('notes')}/> 
                                                                        <div className={classNames('ots-video-control circle flaticon-test custom-video-btn')} title={this.props.intl['prescription']} onClick={() => this.setSideForm('prescription')}/> 
                                                                        <div className={classNames('ots-video-control circle flaticon-report custom-video-btn')} title={this.props.intl['image_request']} onClick={() => this.setSideForm('image')}/> 
                                                                        <div className={classNames('ots-video-control circle flaticon-blood-test custom-video-btn')} title={this.props.intl['lab_request']} onClick={() => this.setSideForm('lab')}/> 
                                                                    </>
                                                                    {/* :''} */}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                {/* <div id="controls" className={'App-control-container'}>
                                                    {this.props.isAuthenticated && this.props.currentUser && this.props.currentUser.type == 3 ?
                                                        <button type="button" className={'btn ' + (this.state.isRecording ? 'btn-danger' : 'btn-primary')} onClick={() => this.ToggleRecording()}> {this.state.isRecording ? ' ' : ' '}</button>
                                                    : ''}
                                                    <div className="d-flex">
                                                        <div className={classNames('ots-video-control circle audio', { muted: !this.state.publishAudio })} onClick={() => this.ToggleAudio()} />
                                                        <div className={classNames('ots-video-control circle video', { muted: !this.state.publishVideo })} onClick={() => this.ToggleVideo()} />
                                                        <div className={'ots-video-control circle end-call'} onClick={() => this.DisconnectCall()} />
                                                    </div>
                                                </div> */}
                                                <OTStreams>
                                                    <OTSubscriber properties={{
                                                        width: '100%',
                                                        height: '830px',
                                                        insertMode: 'append'
                                                    }} />
                                                </OTStreams>
                                            </OTSession>

                                            {/* <div id="controls" className={'App-control-container'}>
                                                <div className={classNames('ots-video-control circle audio', { muted: !this.state.publishAudio })} onClick={() => this.ToggleAudio()} />
                                                <div className={classNames('ots-video-control circle video', { muted: !this.state.publishVideo })} onClick={() => this.ToggleVideo()} />
                                                < div className={'ots-video-control circle end-call'} onClick={() => this.DisconnectCall()} />
                                            </div> */}
                                            {/* {this.props.isAuthenticated && this.props.currentUser && this.props.currentUser.type == 3 ?
                                                <Row className="text-center">
                                                    <Col>
                                                        <button type="button" className={'btn ' + (this.state.isRecording ? 'btn-danger' : 'btn-primary')} onClick={() => this.ToggleRecording()}> {this.state.isRecording ? 'Stop Recording' : 'Start Recording'}</button>
                                                    </Col>
                                                </Row>
                                                : ''} */}
                                        </>
                                        : ""}
                                </Col>
                                {VideoSession ?
                                    <Col lg={this.state.ConsultationTypes.includes(2) || this.state.ConsultationTypes.includes(3) ? '5' : '12'} xl={this.state.ConsultationTypes.includes(2) || this.state.ConsultationTypes.includes(3) ? '3' : '12'} style={{maxHeight:'100vh', overflow:'auto'}}>
                                        {/* {this.state.ConsultationTypes.includes(1) && this.state.displayWindowName == 'chat'? */}
                                    <>
                                        {!this.state.ConsultationTypes.includes(2) && !this.state.ConsultationTypes.includes(3) ?
                                        <div className="video-user-details">
                                            <Row className="align-items-center">
                                                <Col md="7">
                                                    <div className="d-flex align-items-center">
                                                        {/* User Profile photo will be displayed */}
                                                        <div className="user-photo">
                                                            <img src={constantData.profileImageUrl + TokboxPartnerUser.profile_pic} className="d-block"/>
                                                        </div>
                                                        {/* User name will be displayed here and on click on it will redirect to user profile */}
                                                        <p className="ml-1 mb-0">
                                                            {PartnerName}
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md="5">
                                                    {/* Call duration */}
                                                    <div className={"call-duration-timer " + (this.props.languages == 'en' ? " text-right" : " text-left")}>
                                                        {/* <p className="text-right mb-0">12:12:12</p> */}
                                                        {this.state.Appointment.timeDate ?
                                                        <span className={this.state.Appointment.lessthenminute ? 
                                                        'dangertimetickerblinking' : 'clsTimetickerspan'}>{this.state.Appointment.timeticker}</span> : ''}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        : ''}
                                            <div id="feedControls" />
                                            {/* <div id="chatContainer" className={'App-chat-container '+(this.state.ConsultationTypes.includes(1) && this.state.displayWindowName == 'chat'?'':'d-none')} /> */}
                                            <div className= {'custom-video-chat-container '+(this.state.ConsultationTypes.includes(1) && this.state.displayWindowName == 'chat'?'':'d-none')}>
                                                <div className="video-chat-msg-holder">
                                                    <div className="video-chat-display py-1">
                                                    <PerfectScrollbar
                                                        className="user-chats widget-user-chat"
                                                        id="chatList"
                                                        options={{
                                                            wheelPropagation: false
                                                        }}
                                                        onScrollUp={() => this.onScrollUp()}
                                                        ref={el => {
                                                            this.chatArea = el
                                                        }}
                                                    >
                                                        {/* user send message */}
                                                        {resDisputeChatList && resDisputeChatList.length > 0 && resDisputeChatList.map((chat: any, key: any) => {
                                                            let senderTime = '';
                                                            if(this.props.languages == 'en'){
                                                                senderTime = moment(new Date(chat.sending_time)).tz(timeZone).format('dddd, DD MMM YYYY, hh:mm A');;
                                                            } else {
                                                                senderTime = moment(new Date(chat.sending_time)).tz(timeZone).format('dddd, DD MMM YYYY, hh:mm A');;
                                                
                                                                senderTime = this.convertEnglishToArabicDay(senderTime)
                                                                senderTime = this.convertEnglishToArabicTime(senderTime)
                                                            }
                                                            return (
                                                                <>
                                                                {this.props.currentUser && chat.sender_id && chat.sender_id == this.props.currentUser.id ?
                                                        <div className="msg-send w-100 d-flex align-items-top justify-content-end" ref={this.newMessage}>
                                                            <div className="video-chat-msg mb-1">
                                                            {chat.message_type == 1 ? <p className="p-2 mb-0" style={{direction:'ltr'}}>{chat.message}</p> : <p className="p-2 mb-0"><a href={constant.IMAGE_URL + 'chats/' + chat.message} download>{chat.message}</a></p>}
                                                                            <span className={(this.props.languages == 'en' ? " float-right" : " float-left")}>{senderTime}</span>
                                                            </div>
                                                            <div className={"video-user-icon " + (this.props.languages == 'en' ? " ml-2" : " mr-2")}>
                                                                <img alt="user-image" src={constantData.profileImageUrl + TokboxCurrentUser.profile_pic} className=""/>
                                                            </div>
                                                        </div>
                                                        :
                                                        // {/* user recive message */}
                                                        <div className="msg-recive w-100 d-flex align-items-top justify-content-start">
                                                            <div className={"video-user-icon " + (this.props.languages == 'en' ? " mr-2" : " ml-2")}>
                                                                <img alt="user-image" src={constantData.profileImageUrl + TokboxPartnerUser.profile_pic} className=""/>
                                                            </div>
                                                            <div className="video-chat-msg mb-1">
                                                            {chat.message_type == 1 ? <p className="p-2 mb-0" style={{direction:'ltr'}}>{chat.message}</p> : <p className="p-2 mb-0"><a href={constant.IMAGE_URL + 'chats/' + chat.message} download>{chat.message}</a></p>}
                                                                            <span className={(this.props.languages == 'en' ? " float-left" : " float-right")}>{senderTime}</span>
                                                            </div>
                                                        </div>
                                                        }
                                                        </>)
                                                        })}
                                                        </PerfectScrollbar>
                                                    </div>
                                                </div>
                                                <div className="video-chat-form w-100 px-1">
                                                    <div className="">
                                                        { isTyping ?<p className="">{PartnerName + ' ' + this.props.intl["appointment_is_typing"]} </p> : <p className="">
                                                            {this.state.showChatTypeError == true ? (<p style={{wordBreak:'break-all'}} className={"mt-2 px-2 p-0 "+(this.props.languages == 'en' ? "text-left" : "text-right")+"font-light custom_font_error"}>{this.state.showChatTypeMessage}</p>) : <span>&nbsp;</span>}
                                                            {this.state.showattachmentError == true ? (<p style={{wordBreak:'break-all'}} className={"mt-2 px-2 p-0 "+(this.props.languages == 'en' ? "text-left" : "text-right")+"font-light custom_font_error"}>{this.state.showattachmentMessage}</p>) : <span>&nbsp;</span>}
                                                            </p> }
                                                    </div>
                                                    <div className="px-1">
                                                        <div className="form-group d-flex align-items-center mb-0">
                                                        <input id="myInput" type="file" onChange={this.onFileChange} ref={(ref) => this.upload = ref} style={{ display: 'none' }} />
                                                                <input type="text" placeholder={this.props.intl['type_message']} maxLength={500} className="form-control border-0" value={message} onChange={this.handleMessageInput}
                                                                     onKeyPress={event => { if (event.key === 'Enter') {this.sendMessage()} else {this.sendUserTyping()}}} />

                                                                {this.state.attachment != null ? (
                                                                    <button onClick={this.cancelAttachment} className="btn d-flex align-items-center justify-content-center mx-1" type="button"><i className="flaticon-close-1"></i></button>
                                                                )
                                                                :
                                                                    <button type="button" onClick={(e) => this.upload.click()} className="btn d-flex align-items-center justify-content-center mx-1 attchment-btn">
                                                                        {/* <i className="flaticon-link"></i> */}
                                                                        <Paperclip size={15} />
                                                                    </button>
                                                                }

                                                                <button type="button" onClick={() => this.setState({ emojiToggle: !emojiToggle })} className="btn d-flex align-items-center justify-content-center mx-1">
                                                                    <Smile size={15} />
                                                                </button>
                                                                <button type="button" onClick={() => this.sendMessage()} className="btn d-flex align-items-center justify-content-center">
                                                                    {/* <i className={(this.props.languages == 'en' ? 
                                                                    " flaticon-next" : " flaticon-back")}></i> */}
                                                                    <Send size={15} />
                                                                </button>
                                                        </div>
                                                        {emojiToggle ?
                                                                    <div className="emoji-pickers-frame">
                                                                    <Picker onEmojiClick={this.onEmojiClick} />
                                                                    </div>
                                                                    : ''}
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                            {/* <div id="chatContainer" className={'App-chat-container '+(this.state.ConsultationTypes.includes(1) && this.state.displayWindowName == 'chat'?'':'d-none')} /> */}
                                    {/* </> */}
                                         {/* :''} */}
                                        {/* Add Notes Form Start */}
                                        { this.props.isAuthenticated && this.props.currentUser && this.state.displayWindowName == 'notes'?
                                        <div className="video-forms">
                                            {this.state.responseMessageFrm != ''?
                                            <div className="message-box text-white position-relative my-3 mx-auto p-2 pr-3">
                                                <p className="text-white p-0 m-0 font-semi-bold ">
                                                    {this.state.responseMessageFrm}
                                                </p>
                                                <i className="flaticon-close-1 position-absolute cursor-pointer" onClick={this.closeMesage}></i>
                                            </div>
                                            :''}
                                            {this.props.currentUser.type == 3?
                                            <>
                                                <h3 className="form-section-title text-blue font-medium text-center mb-3">
                                                {this.props.intl['add_notes']}
                                                </h3>
                                                
                                                <form className="pop-up-form" onSubmit={this.handleSubmit}>
                                                    <div>
                                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                                            <label className="form-control-label font-semi-medium text-grey">{this.props.intl['notes_title']}</label>
                                                            <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" value={this.state.title} name="title" className="form-control form-control-sm drug_name"
                                                            placeholder={this.props.intl['valid.entertitle']}/>
                                                            {this.shouldMarkError('title') ? (<p  className={'mt-2 p-0  font-light custom_font_error text-left'} >{this.state.showErrors.title}</p>) : ''}
                                                        </div>
                                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                                            <label className="form-control-label font-semi-medium text-grey">{this.props.intl['notes_assessment']}</label>
                                                            <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" value={this.state.assessment} name="assessment" className="form-control form-control-sm dose"
                                                            placeholder={this.props.intl['valid.enterassessment']}/>
                                                            {this.shouldMarkError('assessment') ? (<p  className={'mt-2 p-0  font-light custom_font_error text-left'} >{this.state.showErrors.assessment}</p>) : ''}
                                                        </div>
                                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                                            <label className="form-control-label font-semi-medium text-grey">{this.props.intl['notes_plan']}</label>
                                                            <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" value={this.state.plan} name="plan" className="form-control form-control-sm frequecy"
                                                            placeholder={this.props.intl['valid.enterplan']}/>
                                                            {this.shouldMarkError('plan') ? (<p  className={'mt-2 p-0  font-light custom_font_error text-left'} >{this.state.showErrors.plan}</p>) : ''}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                    <div className="col-12 text-center">
                                                        {this.state.isLoadingDisplay == true ? (
                                                            <button type="button" disabled className="doc-btn border-white font-semi-bold profile-submit mr-3 mx-auto mb-3">{this.props.intl['pleasewait']}
                                                            <i className="flaticon-next position-absolute"></i></button>
                                                        ):(
                                                            <button type="submit" className="doc-btn border-white font-semi-bold profile-submit mr-3 mx-auto mb-3">{this.props.intl['add_notes']}
                                                            <i className="flaticon-next position-absolute"></i></button>
                                                        )}
                                                    </div>
                                                </div>
                                                </form>
                                            </>
                                            :
                                            <BlockUi tag="div" blocking={this.state.isLoadingNotes}>
                                            {/* video form card section start */}
                                            <div className="section-title">
                                                <h3 className="font-medium text-blue">
                                                {this.props.intl['doctor_notes']}
                                                </h3>
                                            </div>
                                            
                                            {this.state.notesResponse != undefined && this.state.notesResponse != null && this.state.notesResponse.status == 200 ?
                                            this.state.notesResponse.data.length > 0?
                                             this.state.notesResponse.data.map((data:any,i:any) => {
                                                return (<>
                                                    <div className="videoFormCard p-2 mb-3">
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['notes_title']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.title}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['notes_assessment']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.assessment}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['notes_plan']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.plan}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </>)
                                            }):this.props.intl['notes_added_will_be_here'] :this.props.intl['pleasewait']}
                                            
                                            </BlockUi>
                                            }
                                    </div>
                                        :''}
                                        {/* Add Notes Form End */}

                                        

                                        {/* Add Prescription Form Start */}
                                        { this.props.isAuthenticated && this.props.currentUser && this.state.displayWindowName == 'prescription'?
                                        <div className="video-forms">
                                            {this.state.responseMessageFrm != ''?
                                            <div className="message-box text-white position-relative my-3 mx-auto p-2 pr-3">
                                                <p className="text-white p-0 m-0 font-semi-bold ">
                                                    {this.state.responseMessageFrm}
                                                </p>
                                                <i className="flaticon-close-1 position-absolute cursor-pointer" onClick={this.closeMesage}></i>
                                            </div>
                                            :''}
                                            {this.props.currentUser.type == 3?
                                            <>
                                            <h3 className="form-section-title text-blue font-medium text-center mb-3">
                                                {this.props.intl['add_prescription']}
                                            </h3>
                                            <form className="pop-up-form" onSubmit={this.handleSubmit}>
                                            <div>
                                                <div className="form-group form-group-feedback form-group-feedback-right">
                                                    <label className="form-control-label font-semi-medium text-grey">{this.props.intl['prescription_title']}</label>
                                                    <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" value={this.state.prescription_title} name="prescription_title" className="form-control form-control-sm drug_name"
                                                    placeholder={this.props.intl['valid.enterpresc_title']}/>
                                                    {this.shouldMarkError('prescription_title') ? (<p  className={'mt-2 p-0  font-light custom_font_error '+(this.props.languages == 'en'?' text-left ':' text-right ')} >{this.state.showErrors.prescription_title}</p>) : ''}
                                                </div>
                                                <div className="form-group form-group-feedback form-group-feedback-right">
                                                    <label className="form-control-label font-semi-medium text-grey">{this.props.intl['drug_name']}</label>
                                                    <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" value={this.state.drug_name} name="drug_name" className="form-control form-control-sm dose"
                                                        placeholder={this.props.intl['valid.enterdrug']}/>
                                                    {this.shouldMarkError('drug_name') ? (<p  className={'mt-2 p-0  font-light custom_font_error '+(this.props.languages == 'en'?' text-left ':' text-right ')} >{this.state.showErrors.drug_name}</p>) : ''}
                                                </div>
                                                <div className="form-group form-group-feedback form-group-feedback-right">
                                                    <label className="form-control-label font-semi-medium text-grey">{this.props.intl['dose']}</label>
                                                    <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" value={this.state.dose} name="dose" className="form-control form-control-sm frequecy"
                                                        placeholder={this.props.intl['valid.enterdose']}/>
                                                    {this.shouldMarkError('dose') ? (<p  className={'mt-2 p-0  font-light custom_font_error '+(this.props.languages == 'en'?' text-left ':' text-right ')} >{this.state.showErrors.dose}</p>) : ''}
                                                </div>
                                                <div className="form-group form-group-feedback form-group-feedback-right">
                                                    <label className="form-control-label font-semi-medium text-grey">{this.props.intl['frequency']}</label>
                                                    <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" value={this.state.frequency} name="frequency" className="form-control form-control-sm frequecy"
                                                        placeholder={this.props.intl['valid.enterfrequency']}/>
                                                    {this.shouldMarkError('frequency') ? (<p  className={'mt-2 p-0  font-light custom_font_error '+(this.props.languages == 'en'?' text-left ':' text-right ')} >{this.state.showErrors.frequency}</p>) : ''}
                                                </div>
                                                <div className="form-group form-group-feedback form-group-feedback-right">
                                                    <label className="form-control-label font-semi-medium text-grey">{this.props.intl['duration']}</label>
                                                    <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} value={this.state.duration} type="text" name="duration" className="form-control form-control-sm frequecy"
                                                        placeholder={this.props.intl['valid.enterduration']}/>
                                                    {this.shouldMarkError('duration') ? (<p  className={'mt-2 p-0  font-light custom_font_error '+(this.props.languages == 'en'?' text-left ':' text-right ')} >{this.state.showErrors.duration}</p>) : ''}
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-12 text-center">
                                                    {this.state.isLoadingDisplay == true ? (
                                                        <button type="button" disabled className="doc-btn border-white font-semi-bold profile-submit mr-3 mx-auto mb-3">{this.props.intl['pleasewait']}
                                                        <i className="flaticon-next position-absolute"></i></button>
                                                    ):(
                                                        <button type="submit" className="doc-btn border-white font-semi-bold profile-submit mr-3 mx-auto mb-3">{this.props.intl['add_prescription']}
                                                        <i className="flaticon-next position-absolute"></i></button>
                                                    )}
                                                </div>
                                            </div>
                                            </form>
                                            </>
                                            :
                                            <BlockUi tag="div" blocking={this.state.isLoadingNotes}>
                                            {/* video form card section start */}
                                            <div className="section-title">
                                                <h3 className="font-medium text-blue">
                                                {this.props.intl['prescription']}
                                                </h3>
                                            </div>
                                            
                                            {this.state.prescriptionResponse != undefined && this.state.prescriptionResponse != null && this.state.prescriptionResponse.status == 200 ?
                                            this.state.prescriptionResponse.data.length > 0?
                                             this.state.prescriptionResponse.data.map((data:any,i:any) => {
                                                return (<>
                                                    <div className="videoFormCard p-2 mb-3">
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['prescription_title']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.prescription_title}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['drug_name']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.drug_name}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['dose']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.dose}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['frequency']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.frequency}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['duration']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.duration}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </>)
                                            }):this.props.intl['prescription_added_will_be_here'] :this.props.intl['pleasewait']}
                                            
                                            </BlockUi>
                                        }
                                        </div>
                                        
                                        :''}
                                        {/* Add Prescription Form End */}


                                    {/* Add image request Form Start */}
                                        {this.props.isAuthenticated && this.props.currentUser && this.state.displayWindowName == 'image'?
                                        <div className="video-forms">
                                            {this.state.responseMessageFrm != ''?
                                            <div className="message-box text-white position-relative my-3 mx-auto p-2 pr-3">
                                                <p className="text-white p-0 m-0 font-semi-bold ">
                                                    {this.state.responseMessageFrm}
                                                </p>
                                                <i className="flaticon-close-1 position-absolute cursor-pointer" onClick={this.closeMesage}></i>
                                            </div>
                                            :''}
                                            {this.props.currentUser.type == 3?
                                            <>
                                            <h3 className="form-section-title text-blue font-medium text-center mb-3">
                                                {this.props.intl['add_image']}
                                            </h3>
                                            <form className="pop-up-form" onSubmit={this.handleSubmit}>
                                            <div>
                                                <div className="form-group form-group-feedback form-group-feedback-right">
                                                    <label className="form-control-label font-semi-medium text-grey">{this.props.intl['bodyPart']}</label>
                                                    <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" name="body_part" className="form-control form-control-sm drug_name" value={this.state.body_part}
                                                    placeholder={this.props.intl['valid.enterbody_part']}/>
                                                    {this.shouldMarkError('body_part') ? (<p  className={'mt-2 p-0  font-light custom_font_error '+' text-left '} >{this.state.showErrors.body_part}</p>) : ''}
                                                </div>
                                                <div className="form-group form-group-feedback form-group-feedback-right">
                                                    <label className="form-control-label font-semi-medium text-grey">{this.props.intl['imageModality']}</label>
                                                    <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" value={this.state.imaging_modality} name="imaging_modality" className="form-control form-control-sm dose"
                                                        placeholder={this.props.intl['valid.enterimaging_modality']}/>
                                                    {this.shouldMarkError('imaging_modality') ? (<p  className={'mt-2 p-0  font-light custom_font_error '+' text-left '} >{this.state.showErrors.imaging_modality}</p>) : ''}
                                                </div>
                                                <div className="form-group form-group-feedback form-group-feedback-right">
                                                    <label className="form-control-label font-semi-medium text-grey">{this.props.intl['indication']}</label>
                                                    <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" value={this.state.image_indication} name="image_indication" className="form-control form-control-sm frequecy"
                                                        placeholder={this.props.intl['valid.enterindication']}/>
                                                    {this.shouldMarkError('image_indication') ? (<p  className={'mt-2 p-0  font-light custom_font_error '+' text-left '} >{this.state.showErrors.image_indication}</p>) : ''}
                                                </div>
                                            </div>                   
                                            <div className="row mt-4">
                                                <div className="col-12 text-center">
                                                    {this.state.isLoadingDisplay == true ? (
                                                        <button type="button" disabled className="doc-btn border-white font-semi-bold profile-submit mr-3 mx-auto mb-3">{this.props.intl['pleasewait']}
                                                        <i className="flaticon-next position-absolute"></i></button>
                                                    ):(
                                                        <button type="submit" className="doc-btn border-white font-semi-bold profile-submit mr-3 mx-auto mb-3">{this.props.intl['add_image']}
                                                        <i className="flaticon-next position-absolute"></i></button>
                                                    )}
                                                </div>
                                            </div>
                                            </form>
                                            </>
                                            :
                                            <BlockUi tag="div" blocking={this.state.isLoadingNotes}>
                                            {/* video form card section start */}
                                            <div className="section-title">
                                                <h3 className="font-medium text-blue">
                                                {this.props.intl['image_request']}
                                                </h3>
                                            </div>
                                            
                                            {this.state.imageResponse != undefined && this.state.imageResponse != null && this.state.imageResponse.status == 200 ?
                                            this.state.imageResponse.data.length > 0?
                                             this.state.imageResponse.data.map((data:any,i:any) => {
                                                return (<>
                                                    <div className="videoFormCard p-2 mb-3">
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['bodyPart']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.body_part}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['imageModality']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.imaging_modality}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['indication']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.indication}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </>)
                                            }):this.props.intl['imagerequest_added_will_be_here'] :this.props.intl['pleasewait']}
                                            
                                            </BlockUi>
                                        }
                                        </div>
                                         :''} 
                                        {/* Add Prescription Form End */}


                                        {/* Add lab request Form Start */}
                                        {this.props.isAuthenticated && this.props.currentUser && this.state.displayWindowName == 'lab'?
                                        <div className="video-forms">
                                            {this.state.responseMessageFrm != ''?
                                            <div className="message-box text-white position-relative my-3 mx-auto p-2 pr-3">
                                                <p className="text-white p-0 m-0 font-semi-bold ">
                                                    {this.state.responseMessageFrm}
                                                </p>
                                                <i className="flaticon-close-1 position-absolute cursor-pointer" onClick={this.closeMesage}></i>
                                            </div>
                                            :''}
                                            {this.props.currentUser.type == 3?
                                            <>
                                            <h3 className="form-section-title text-blue font-medium text-center mb-3">
                                                {this.props.intl['add_lab']}
                                            </h3>
                                            <form className="pop-up-form" onSubmit={this.handleSubmit}>
                                            
                                            <div>
                                                <div className="form-group form-group-feedback form-group-feedback-right">
                                                    <label className="form-control-label font-semi-medium text-grey">{this.props.intl['specimen']}</label>
                                                    <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" value={this.state.specimen} name="specimen" className="form-control form-control-sm drug_name"
                                                        placeholder={this.props.intl['valid.enterspecimen']}/>
                                                    {this.shouldMarkError('specimen') ? (<p  className={'mt-2 p-0  font-light custom_font_error '+' text-left '} >{this.state.showErrors.specimen}</p>) : ''}
                                                </div>
                                                <div className="form-group form-group-feedback form-group-feedback-right">
                                                    <label className="form-control-label font-semi-medium text-grey">{this.props.intl['test_name']}</label>
                                                    <input value={this.state.test_name} onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" name="test_name" className="form-control form-control-sm dose"
                                                        placeholder={this.props.intl['valid.entertest_name']}/>
                                                    {this.shouldMarkError('test_name') ? (<p  className={'mt-2 p-0  font-light custom_font_error '+' text-left '} >{this.state.showErrors.test_name}</p>) : ''}
                                                </div>
                                                <div className="form-group form-group-feedback form-group-feedback-right">
                                                    <label className="form-control-label font-semi-medium text-grey">{this.props.intl['indication']}</label>
                                                    <input onBlur={(event:any) => this.handleBlur(event.target.name)} onChange={this.handleChange} type="text" value={this.state.indication} name="indication" className="form-control form-control-sm frequecy"
                                                        placeholder={this.props.intl['valid.enterindication']}/>
                                                    {this.shouldMarkError('indication') ? (<p  className={'mt-2 p-0  font-light custom_font_error '+' text-left '} >{this.state.showErrors.indication}</p>) : ''}
                                                </div>
                                            </div>
                    
                                            <div className="row mt-4">
                                                <div className="col-12 text-center">
                                                    {this.state.isLoadingDisplay == true ? (
                                                        <button type="button" disabled className="doc-btn border-white font-semi-bold profile-submit mr-3 mx-auto mb-3">{this.props.intl['pleasewait']}
                                                        <i className="flaticon-next position-absolute"></i></button>
                                                    ):(
                                                        <button type="submit" className="doc-btn border-white font-semi-bold profile-submit mr-3 mx-auto mb-3">{this.props.intl['add_lab']}
                                                        <i className="flaticon-next position-absolute"></i></button>
                                                    )}
                                                </div>
                                            </div>
                                            </form>
                                            </>
                                            :
                                            <BlockUi tag="div" blocking={this.state.isLoadingNotes}>
                                            {/* video form card section start */}
                                            <div className="section-title">
                                                <h3 className="font-medium text-blue">
                                                {this.props.intl['lab_request']}
                                                </h3>
                                            </div>
                                            
                                            {this.state.labResponse != undefined && this.state.labResponse != null && this.state.labResponse.status == 200 ?
                                            this.state.labResponse.data.length > 0?
                                             this.state.labResponse.data.map((data:any,i:any) => {
                                                return (<>
                                                    <div className="videoFormCard p-2 mb-3">
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['test_name']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.test_name}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['specimen']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.specimen}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="videoCardDetails d-block">
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="font-semi-bold">{this.props.intl['indication']}</p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <p>{data.indication}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </>)
                                            }):this.props.intl['labrequest_added_will_be_here'] :this.props.intl['pleasewait']}
                                            
                                            </BlockUi>
                                        }
                                        </div>
                                         :''} 
                                        {/* Add lab Form End */}

                                        {!this.state.ConsultationTypes.includes(2) && !this.state.ConsultationTypes.includes(3) ?
                                            <div id="controls" className={'App-control-container'}>
                                                {/* < div className={'ots-video-control circle end-call'} onClick={() => this.DisconnectCall()} /> */}

                                                <Row>
                                                    <Col md='5'>
                                                        <div className="video-btn-group-left">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                            {/* {this.props.isAuthenticated && this.props.currentUser && this.props.currentUser.type == 3 ?
                                                                <div className="ots-video-control position-relative custom-center-btn">
                                                                    <button type="button" className={'btn ' + (this.state.isRecording ? 'btn-danger' : 'btn-primary')} title="Recording" onClick={() => this.ToggleRecording()}> {this.state.isRecording ? ' ' : ' '}</button>
                                                                </div>
                                                            : ''} */}
                                                            {this.props.currentUser.type == 3 || this.state.orgConsultationTypes.includes(2) || this.state.RunTimeConsultationTypes.includes(2)?
                                                                <div className={classNames('ots-video-control circle audio', { muted: !this.state.publishAudio })} onClick={() => this.ToggleAudio()} />
                                                            :''}
                                                            {this.props.currentUser.type == 3 || this.state.orgConsultationTypes.includes(3) || this.state.RunTimeConsultationTypes.includes(3)?
                                                                <div className={classNames('ots-video-control circle video', { muted: !this.state.publishVideo })} onClick={() => this.ToggleVideo()} />
                                                            :''}
                                                            {this.props.currentUser.type == 3 || this.state.orgConsultationTypes.includes(1) || this.state.RunTimeConsultationTypes.includes(1)?
                                                                // <div className={classNames('ots-video-control circle custom-video-btn') + (this.state.orgConsultationTypes.includes(1) || this.state.RunTimeConsultationTypes.includes(1))?classNames('flaticon-comment'):classNames('flaticon-forbbiden')} title="Message"  onClick={() => this.setSideForm('chat')} />  
                                                                // <div className={classNames('ots-video-control circle custom-video-btn flaticon-comment')} title="Message"  onClick={() => this.setSideForm('chat')} />  
                                                                // <div className={classNames('ots-video-control circle custom-video-btn ') + (this.state.ConsultationTypes.includes(1))?classNames('flaticon-comment'):classNames('flaticon-forbbiden')} title="Message"  onClick={() => this.setSideForm('chat')} />  
                                                                <div className={this.state.ConsultationTypes.includes(1)?'ots-video-control circle custom-video-btn flaticon-comment':'ots-video-control circle custom-video-btn flaticon-forbbiden'} title="Message"  onClick={() => this.setSideForm('chat')} />  
                                                            :''}
                                                            {/* <div className={'ots-video-control circle end-call'} onClick={() => this.DisconnectCall()} /> */}
                                                            </div>            
                                                        </div>
                                                    </Col>
                                                    <Col md='2'>
                                                        <div className="video-end-call-btn">
                                                            <div className={'ots-video-control circle end-call'} onClick={() => this.DisconnectCall()} />
                                                        </div>
                                                    </Col>
                                                    <Col md='5'>
                                                        <div className="video-btn-group-right"> 
                                                            <div className="d-flex align-items-center justify-content-center">
                                                            {/* {this.props.isAuthenticated && this.props.currentUser && this.props.currentUser.type == 3 ? */}
                                                            <>
                                                                <div className={classNames('ots-video-control circle flaticon-document custom-video-btn')} title={this.props.intl['notes']} onClick={() => this.setSideForm('notes')}/> 
                                                                <div className={classNames('ots-video-control circle flaticon-test custom-video-btn')} title={this.props.intl['prescription']} onClick={() => this.setSideForm('prescription')}/> 
                                                                <div className={classNames('ots-video-control circle flaticon-report custom-video-btn')} title={this.props.intl['image_request']} onClick={() => this.setSideForm('image')}/> 
                                                                <div className={classNames('ots-video-control circle flaticon-blood-test custom-video-btn')} title={this.props.intl['lab_request']} onClick={() => this.setSideForm('lab')}/> 
                                                            </>
                                                            {/* :''} */}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </div>
                                            : ''}
                                    </Col>
                                    : ''}
                            </Row>
                            {/* <Row className="w-100 text-center">
                                <Col md="12">
                                    {this.state.Appointment.timeDate ?
                                        <span className={this.state.Appointment.lessthenminute ? 'dangertimetickerblinking' : 'clsTimetickerspan'}>{this.state.Appointment.timeticker}</span> : ''}
                                </Col>
                            </Row> */}
                        </BlockUi>
                    </Modal>
                </div >
            </>
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
        cmsContent: state.cms.aboutUs,
        languages: state.global.setLanguage,
        intl: state.intl.messages,
        currentUser: state.auth.currentUser,
        isAuthenticated: state.auth.isAuthenticated,
    }
}

export default socketConnect(withRouter(connect(mapStateToProps)(VideoChat)));