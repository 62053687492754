/* eslint-disable no-console */
import axios from 'axios';
import { store } from '../store/index';
import { API_URL } from '../../lib/config/constant';
import * as authActions from '../store/auth/actions';
import Transformer from './Transformer';
import * as constant from '../../config/constant';
//import AsyncStorage from '@react-native-community/async-storage';
const AsyncStorage: any = require('../abstractComponents/libfile');

const ApiAddress = API_URL;


axios.defaults.baseURL = ApiAddress;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//console.log('language:: KKK', constant.default.platform);
if (constant.default.platform == 'web') {
  //console.log('language:: KKK', localStorage.getItem('language'));

  axios.defaults.headers.common['Accept-Language'] =
    localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
  if (
    localStorage.getItem('access_token') &&
    localStorage.getItem('access_token') != null &&
    localStorage.getItem('access_token') != undefined
  ) {
    axios.defaults.headers.common['auth'] = localStorage.getItem(
      'access_token',
    );
  }
} else {
  /**
   * set async storage
   */
  AsyncStorage.default.default.getItem('language').then((value: any) => {
    axios.defaults.headers.common['Accept-Language'] =
      value == 'ar' ? 'ar' : 'en';
  });
  AsyncStorage.default.default.getItem('access_token').then((token: any) => {
    if (token != null && token != undefined) {
      axios.defaults.headers.common['auth'] = token;
    }
  });
}

axios.interceptors.response.use(
  response => response,
  error => {
    console.log('error https====', error);
    console.log('error https response====', error.response);
    if (error.message === 'Network Error') {
      error.response = {
        status: 500,
        data: {
          message: 'Network Error | Network Unavailable',
        },
      };
    }
    if (error.response.status === 401) {
      //console.log('error.response.status', error.response.status);

      /**
       * coment due to error
       */
      // store.dispatch(authLogout())
      const data = Transformer.fetch({});
      store.dispatch(authActions.authLogout(data));
      if (constant.default.platform == 'Mobile') {
        AsyncStorage.default.default.removeItem('access_token');
      } else {
        localStorage.removeItem('access_token');
      }
    }
    if (error.response.status === 500) {
      if (
        !error.response.data ||
        !error.response.data.message ||
        error.response.data.message != ''
      ) {
        error.response = {
          status: 500,
          data: {
            message: error.response.data.message,
          },
        };
      }
    }
    return Promise.reject(error);
  },
);

export default axios;
