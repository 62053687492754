import { MiddleWare,PayLoad } from '../../interfaces/auth';

export const DOCTOR_PROFILE_FORM = 'DOCTOR_PROFILE_FORM';

export const DOCTOR_VIEW_PROFILE = 'DOCTOR_VIEW_PROFILE';
export const CHANGE_DOCTOR_VIEW_PROFILE = 'CHANGE_DOCTOR_VIEW_PROFILE';
export const CHANGE_INSTANT_AVAILABILITY = 'CHANGE_INSTANT_AVAILABILITY';
export const CHANGE_FAVOURITE_DATA = 'CHANGE_FAVOURITE_DATA';
export const SUBSCRIBE_NOTIFICATION_AVAILABILITY = 'SUBSCRIBE_NOTIFICATION_AVAILABILITY';

interface DOCTOR_PROFILE_FORM_ACTION {
    type: typeof DOCTOR_PROFILE_FORM
    payload:PayLoad;
}

interface DOCTOR_VIEW_PROFILE_ACTION {
    type: typeof DOCTOR_VIEW_PROFILE
    payload:PayLoad;
}

interface CHANGE_INSTANT_AVAILABILITY_ACTION {
    type: typeof CHANGE_INSTANT_AVAILABILITY
    payload:PayLoad;
}

interface CHANGE_DOCTOR_VIEW_PROFILE_ACTION {
    type: typeof CHANGE_DOCTOR_VIEW_PROFILE
    payload:PayLoad;
}
interface CHANGE_FAVOURITE_DATA_ACTION {
    type: typeof CHANGE_FAVOURITE_DATA
    payload:PayLoad;
}
interface SUBSCRIBE_NOTIFICATION_AVAILABILITY_ACTION {
    type: typeof SUBSCRIBE_NOTIFICATION_AVAILABILITY
    payload:PayLoad;
}


export type DOCTOR_TYPES = 
 | DOCTOR_PROFILE_FORM_ACTION
 | DOCTOR_VIEW_PROFILE_ACTION
 | CHANGE_INSTANT_AVAILABILITY_ACTION
 | CHANGE_DOCTOR_VIEW_PROFILE_ACTION
 | CHANGE_FAVOURITE_DATA_ACTION
 | SUBSCRIBE_NOTIFICATION_AVAILABILITY_ACTION;