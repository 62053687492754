import {CMS_PAGES_TYPES} from './action-types';

import {MiddleWare} from '../../interfaces/auth';

const initialState: MiddleWare[] = [];

const reducer = (
  state = initialState,
  action: CMS_PAGES_TYPES,
): MiddleWare[] => {
  switch (action.type) {
    case 'CMS_PAGES':
      return cmsPage(state, action.payload);
    case 'CMS_PAGES_PENDING':
      return cmsPagePending(state);
    case 'HEADER_FOOTER':
      return headerfooter(state, action.payload);
    case 'PRIVACY_POLICY':
      return privacyPolicy(state, action.payload);
    case 'RULES_REGULATIONS':
      return rulesRegulations(state, action.payload);
    case 'TERMS_CONDITION':
      return termsConditions(state, action.payload);
    case 'HOME':
      return home(state, action.payload);
    case 'ABOUT_US':
      return aboutUs(state, action.payload);
    case 'CONTACT_US':
      return contact_us(state, action.payload);
    case 'SOCIAL_MEDIA':
      return socialMediaLinks(state, action.payload);
    case 'SIGN_IN_SIGN_UP':
        return signInSignUp(state, action.payload);
      case 'FORGOT_RESEND':
      return forgotResend(state, action.payload);
    default:
      return state;
  }
};

export default reducer;

function rulesRegulations(state: any, payload: any) {
  return {
    ...state,
    rulesRegulations: payload,
    loading: false,
  };
}
function privacyPolicy(state: any, payload: any) {
  return {
    ...state,
    privacyPolicy: payload,
    loading: false,
  };
}
function termsConditions(state: any, payload: any) {
  return {
    ...state,
    termsConditions: payload,
    loading: false,
  };
}
function aboutUs(state: any, payload: any) {
  return {
    ...state,
    aboutUs: payload,
    loading: false,
  };
}
function contact_us(state: any, payload: any) {
  return {
    ...state,
    contact_us: payload,
    loading: false,
  };
}
function cmsPage(state: any, payload: any) {
  return {
    ...state,
    cmsContent: payload,
    loading: false,
  };
}

function headerfooter(state: any, payload: any) {
  return {
    ...state,
    headerfooter: payload,
    loading: false,
  };
}

function home(state: any, payload: any) {
  return {
    ...state,
    home: payload,
    loading: false,
  };
}

function cmsPagePending(state: any) {
  return {
    ...state,
    loading: true,
  };
}

function socialMediaLinks(state: any, payload: any) {
  return {
    ...state,
    socialMedia: payload,
    loading: false,
  };
}

function signInSignUp(state: any, payload: any) {
  return {
    ...state,
    signInSignUp: payload,
    loading: false,
  };
}

function forgotResend(state: any, payload: any) {
  return {
    ...state,
    forgotResend: payload,
    loading: false,
  };
}

// export const getAuth = state => state.auth.isAuthenticated;

// export default reducer;
