import { Dispatch } from 'react';
import { RootState } from '../reducers';
import {
  APPOINTMENT_TYPES,GET_APPOINTMENT_HISTORY,SHOW_ALERT,DOCTOR_NOTES,DOCTOR_LAB_REQUEST,DOCTOR_IMAGE_REQUEST,DOCTOR_PRESCRIPTION,REPORTS_IMAGES, CHECK_ROUTE,
} from './action-types';
import { PayLoad } from '../../interfaces/auth';


export function getPatientAppointmentHistory(payload: PayLoad): APPOINTMENT_TYPES {
  return {
    type: GET_APPOINTMENT_HISTORY,
    payload,
  };
}

export function getPatientAppointmentHistoryIntial(payload: PayLoad) {
  return (dispatch: Dispatch<APPOINTMENT_TYPES>, getState: () => RootState) => {
    dispatch(getPatientAppointmentHistory(payload));
  }
}
export function showAlert(payload: PayLoad): APPOINTMENT_TYPES {
  return {
    type: SHOW_ALERT,
    payload,
  };
}

export function showAlertIntial(payload: PayLoad) {
  return (dispatch: Dispatch<APPOINTMENT_TYPES>, getState: () => RootState) => {
    dispatch(showAlert(payload));
  }
}
export function myNotes(payload: PayLoad): APPOINTMENT_TYPES {
  return {
    type: DOCTOR_NOTES,
    payload,
  };
}

export function myNotesIntial(payload: PayLoad) {
  return (dispatch: Dispatch<APPOINTMENT_TYPES>, getState: () => RootState) => {
    dispatch(myNotes(payload));
  }
}
export function myPrescription(payload: PayLoad): APPOINTMENT_TYPES {
  return {
    type: DOCTOR_PRESCRIPTION,
    payload,
  };
}

export function myPrescriptionIntial(payload: PayLoad) {
  return (dispatch: Dispatch<APPOINTMENT_TYPES>, getState: () => RootState) => {
    dispatch(myPrescription(payload));
  }
}
export function myLabRequest(payload: PayLoad): APPOINTMENT_TYPES {
  return {
    type: DOCTOR_LAB_REQUEST,
    payload,
  };
}

export function myLabRequestIntial(payload: PayLoad) {
  return (dispatch: Dispatch<APPOINTMENT_TYPES>, getState: () => RootState) => {
    dispatch(myLabRequest(payload));
  }
}
export function myImageRequest(payload: PayLoad): APPOINTMENT_TYPES {
  return {
    type: DOCTOR_IMAGE_REQUEST,
    payload,
  };
}

export function myImageRequestIntial(payload: PayLoad) {
  return (dispatch: Dispatch<APPOINTMENT_TYPES>, getState: () => RootState) => {
    dispatch(myImageRequest(payload));
  }
}
export function myReportsImages(payload: PayLoad): APPOINTMENT_TYPES {
  return {
    type: REPORTS_IMAGES,
    payload,
  };
}

export function myReportsImagesIntial(payload: PayLoad) {
  return (dispatch: Dispatch<APPOINTMENT_TYPES>, getState: () => RootState) => {
    dispatch(myReportsImages(payload));
  }
}

export function checkRoutes(payload: PayLoad): APPOINTMENT_TYPES {
  return {
    type: CHECK_ROUTE,
    payload,
  };
}

export function checkRoutesIntial(payload: PayLoad) {
  return (dispatch: Dispatch<APPOINTMENT_TYPES>, getState: () => RootState) => {
    dispatch(checkRoutes(payload));
  }
}