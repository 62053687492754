import {MiddleWare, PayLoad} from '../../interfaces/auth';

// auth action types
export const IS_VIDEO_REF_SET_SUCCESS = 'IS_VIDEO_REF_SET_SUCCESS';

interface IS_VIDEO_REF_SET_SUCCESS_ACTION {
  type: typeof IS_VIDEO_REF_SET_SUCCESS;
  payload: PayLoad;
}

export type VIDEO_INITIAL_TYPES = IS_VIDEO_REF_SET_SUCCESS_ACTION;
