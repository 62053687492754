import {
    FAQ_TYPES
  } from './action-types';
  
  import { MiddleWare } from '../../interfaces/auth';
  
  const initialState: MiddleWare [] = [];
  
  const reducer = (state = initialState,action: FAQ_TYPES) : MiddleWare[] => {
    switch (action.type) {
      case "FAQ":
        return faq(state,action.payload)
      default:
        return state;
    }
  };
  
  export default reducer;
  
  function faq(state:any, payload:any) {
    return {
      ...state,
      faq:payload,
      loading:false,
    };
  }