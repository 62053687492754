import {MiddleWare, PayLoad} from '../../interfaces/auth';

export const CMS_PAGES = 'CMS_PAGES';
export const CMS_PAGES_PENDING = 'CMS_PAGES_PENDING';
export const HEADER_FOOTER = 'HEADER_FOOTER';
export const RULES_REGULATIONS = 'RULES_REGULATIONS';
export const PRIVACY_POLICY = 'PRIVACY_POLICY';
export const TERMS_CONDITION = 'TERMS_CONDITION';
export const HOME = 'HOME';
export const ABOUT_US = 'ABOUT_US';
export const CONTACT_US = 'CONTACT_US';
export const SOCIAL_MEDIA = 'SOCIAL_MEDIA';
export const SIGN_IN_SIGN_UP = 'SIGN_IN_SIGN_UP';
export const FORGOT_RESEND = 'FORGOT_RESEND';

interface CMS_PAGES_ACTION {
  type: typeof CMS_PAGES;
  payload: PayLoad;
}
interface CMS_PAGES_ACTION_PENDING {
  type: typeof CMS_PAGES_PENDING;
  payload: PayLoad;
}

interface HEADER_FOOTER {
  type: typeof HEADER_FOOTER;
  payload: PayLoad;
}

interface PRIVACY_POLICY_ACTION {
  type: typeof PRIVACY_POLICY;
  payload: PayLoad;
}

interface RULES_REGULATIONS_ACTION {
  type: typeof RULES_REGULATIONS;
  payload: PayLoad;
}

interface TERMS_CONDITION_ACTION {
  type: typeof TERMS_CONDITION;
  payload: PayLoad;
}

interface HOME_ACTION {
  type: typeof HOME;
  payload: PayLoad;
}
interface ABOUT_US_ACTION {
  type: typeof ABOUT_US;
  payload: PayLoad;
}
interface CONTACT_US_ACTION {
  type: typeof CONTACT_US;
  payload: PayLoad;
}
interface SOCIAL_MEDIA_ACTION {
  type: typeof SOCIAL_MEDIA;
  payload: PayLoad;
}
interface SIGN_IN_SIGN_UP_ACTION {
  type: typeof SIGN_IN_SIGN_UP;
  payload: PayLoad;
}
interface FORGOT_RESEND_ACTION {
  type: typeof FORGOT_RESEND;
  payload: PayLoad;
}

export type CMS_PAGES_TYPES =
  | CMS_PAGES_ACTION
  | CMS_PAGES_ACTION_PENDING
  | HEADER_FOOTER
  | RULES_REGULATIONS_ACTION
  | PRIVACY_POLICY_ACTION
  | TERMS_CONDITION_ACTION
  | HOME_ACTION
  | ABOUT_US_ACTION
  | CONTACT_US_ACTION
  | SIGN_IN_SIGN_UP_ACTION
  | FORGOT_RESEND_ACTION
  | SOCIAL_MEDIA_ACTION;
