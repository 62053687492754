import { MiddleWare, PayLoad } from '../../interfaces/auth';

export const NOTIFICATION_UNREAD_COUNT = 'NOTIFICATION_UNREAD_COUNT';
export const MARK_READ_NOTIFICATION_COUNT = 'MARK_READ_NOTIFICATION_COUNT';
export const MARK_READ_NOTIFICATION = 'MARK_READ_NOTIFICATION';
export const GET_ALL_NOTIFICATION = 'GET_ALL_NOTIFICATION';
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

interface NOTIFICATION_UNREAD_COUNT_ACTION {
    type: typeof NOTIFICATION_UNREAD_COUNT
    payload: PayLoad;
}

interface MARK_READ_NOTIFICATION_COUNT_ACTION {
    type: typeof MARK_READ_NOTIFICATION_COUNT
    payload: PayLoad;
}

interface MARK_READ_NOTIFICATION_ACTION {
    type: typeof MARK_READ_NOTIFICATION
    payload: PayLoad;
}

interface GET_ALL_NOTIFICATION_ACTION {
    type: typeof GET_ALL_NOTIFICATION
    payload: PayLoad;
}
interface GET_ALL_NOTIFICATIONS_ACTION {
    type: typeof GET_ALL_NOTIFICATIONS
    payload: PayLoad;
}
interface CLEAR_NOTIFICATION_ACTION {
    type: typeof CLEAR_NOTIFICATION
}

export type NOTIFICATION_TYPES = 
 | NOTIFICATION_UNREAD_COUNT_ACTION
 | MARK_READ_NOTIFICATION_COUNT_ACTION 
 | MARK_READ_NOTIFICATION_ACTION
 | GET_ALL_NOTIFICATION_ACTION
 | GET_ALL_NOTIFICATIONS_ACTION
 | CLEAR_NOTIFICATION_ACTION;