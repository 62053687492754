import {
  SEARCH_DOCTOR_TYPES
} from './action-types';

import { MiddleWare } from '../../interfaces/auth';

const initialState: MiddleWare[] = [];

const reducer = (state = initialState, action: SEARCH_DOCTOR_TYPES): MiddleWare[] => {
  switch (action.type) {
    case "SEARCH_DOCTOR":
      return searchDoctor(state, action.payload)
    case "SEARCH_DOCTOR_PAGINATION_PAGE_NUMBER":
      return searchDoctorPagination(state, action.payload)
    default:
      return state;
  }
};

export default reducer;

function searchDoctor(state: any, payload: any) {
  return {
    ...state,
    searchDoctors: payload,
    searchDoctorPage: 1,
  }
}

function searchDoctorPagination(state: any, payload: any) {
  return {
    ...state,
    searchDoctorPage: payload,
  }
}
