import Http from '../utils/Http';
import * as authActions from '../store/auth/actions';
import Transformer from '../utils/Transformer';
import * as constant from '../../config/constant';
const AsyncStorage: any = require('../abstractComponents/libfile');

/**
 * login user
 *
 * @param credentials
 * @returns {function(*)}
 */
// export function login(credentials) {
//     return dispatch => (
//         new Promise((resolve, reject) => {
//             Http.post('auth/logi', credentials)
//                 .then(res => {
//                     const data = Transformer.fetch(res.data)
//                     if (res.status === 200) {
//                         dispatch(authActions.authLogin(data.token))
//                     }
//                     return resolve()
//                 })
//                 .catch((err) => {
//                     const statusCode = err.response.status;
//                     const data = {
//                         error: null,
//                         statusCode,
//                     };

//                     if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
//                         data.error = err.response.data;
//                     }
//                     return reject(data);
//                 })
//         })
//     )
// }

/**
 * sign up user
 *
 * @param credentials
 * @returns {function(*)}
 */
export function signUp(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/auth/signup', data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          // //console.log('data::',data);
          // //console.log('res.data::',res.data);
          // if (res.status === 200) {
          // dispatch(authActions.authLogin(data.token))
          // }
          return resolve(res);
        })
        .catch(err => {
          // //console.log('signUp error:'+err);
          // //console.log('signUp error response:',err.response);
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          // data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

export function emailExistCheck(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/auth/emailExistCheck', data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          // //console.log('data::',data);
          // //console.log('res.data::',res.data);
          if (res.status === 200) {
            // dispatch(authActions.authLogin(data.token))
          }
          return resolve();
        })
        .catch(err => {
          // //console.log('signUp error:'+err);
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

/**
 * set user authInitialState
 *
 * @returns {function(*)}
 */
export function initialState() {
  return (dispatch: any) => {
    /**
     * comment due to error
     */
    // dispatch(authActions.authInitialState())
  };
}

/**
 * logout user
 *
 * @returns {function(*)}
 */
export function logout() {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  return (dispatch: any) => {
    return Http.get('api/auth/logout')
      .then(res => {
        // dispatch(authActions.authLogout())
        const data = Transformer.fetch({});
        dispatch(authActions.authLogout(data));
      })
      .catch(err => {
        //console.log(err);
      });
  };
}
export function logoutMobile(deviceID: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
    Http.defaults.headers.common['device_id'] = deviceID;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.get('api/auth/logout')
        .then(res => {
          const data = Transformer.fetch(res.data);
          return resolve(res);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function verifyEmail(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/verify/emailconfirm', data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
            // dispatch(authActions.authLogin(data.token))
          }
          return resolve(data);
        })
        .catch(err => {
          // //console.log('signUp error:'+err);
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          //     data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

/**
 * sign up user
 *
 * @param credentials
 * @returns {function(*)}
 */
export function resendActivationLink(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/auth/resendemail', data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          return resolve(res);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

/**
 * forgot password
 *
 * @param credentials
 * @returns {function(*)}
 */
export function forgotPasswordLink(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/auth/forgotPassword', data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          return resolve(res);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

/**
 * reset password
 *
 * @param credentials
 * @returns {function(*)}
 */
export function resetPassword(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/auth/resetpassword', data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          return resolve(res);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

/**
 * sign in user
 *
 * @param credentials
 * @returns {function(*)}
 */

export function signIn(data: any) {
  //export const signIn = (data: any) => async (dispatch: any) => {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/auth/login', data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          dispatch(authActions.authLoginSuccess(data));
          return resolve(res);
        })
        .catch(err => {
          // //console.log('signUp error:'+err);
          // //console.log('signUp error response:',err.response);
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };

          // if (statusCode === 422 || statusCode === 403 || statusCode === 404 || statusCode === 401) {
          // data.error = err.response.data;
          // }
          return reject(data);
        });
    });
}

/**
 * get user using token
 *
 * @param credentials
 * @returns {function(*)}
 */
export function getUserUsingToken(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/auth/getTokenUser', data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          dispatch(authActions.authLoginSuccess(data));
          dispatch(authActions.isLocalStorageSet(data));
          return resolve(res);
        })
        .catch(err => {
          return reject();
        });
    });
}

/**
 * change password
 *
 * @param credentials
 * @returns {function(*)}
 */
export function changePassword(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/users/changepassword', data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          return resolve(res);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}
/**
 * change password
 *
 * @param credentials
 * @returns {function(*)}
 */
export function bankDetails(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/users/add-bankdetail', data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          return resolve(res);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

/**
 * change password
 *
 * @param credentials
 * @returns {function(*)}
 */
export function settings() {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else {
    Http.defaults.headers.common['Accept-Language'] =
      constant.default.isRTL == 0 ? 'en' : 'ar';
    Http.defaults.headers.common['auth'] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/users/settings')
        .then(res => {
          const data = Transformer.fetch(res.data);
          return resolve(res);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

/**
 * change password
 *
 * @param credentials
 * @returns {function(*)}
 */
export function setMailLanguages(data: any) {
  if (constant.default.platform == 'web') {
    Http.defaults.headers.common['Accept-Language'] =
      localStorage.getItem('language') == 'ar' ? 'ar' : 'en';
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('access_token') != undefined
    ) {
      Http.defaults.headers.common['auth'] = localStorage.getItem(
        'access_token',
      );
    }
  } else if (constant.default.platform == 'Mobile') {
    AsyncStorage.default.default.getItem('language').then((value: any) => {
      if (value != null) {
        Http.defaults.headers.common['Accept-Language'] =
          value == 'ar' ? 'ar' : 'en';
      }
    });

    if (
      AsyncStorage.default.default.getItem('access_token') &&
      AsyncStorage.default.default.getItem('access_token') != null &&
      AsyncStorage.default.default.getItem('access_token') != undefined
    ) {
      AsyncStorage.default.default
        .getItem('access_token')
        .then((value: any) => {
          if (value != null) {
            Http.defaults.headers.common['auth'] = value;
          }
        });
    }
  }
  //console.log('headers----', Http.defaults.headers);
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post('api/users/changeemaillang', data)
        .then(res => {
          const data = Transformer.fetch(res.data);
          return resolve(res);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const responseData = err.response.data;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}
