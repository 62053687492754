import React, { Component } from 'react';
import '../css/doctoray-header.css';
import { Route, Link, withRouter } from "react-router-dom";
import Icon from '../images/Menu.png';
import { cmsRequestParams } from '../lib/config/constant';
import * as authActions from '../lib/store/auth/actions';
import { cmsPageRender } from '../lib/apis/cmsPage';
import { language, alertMessageSuccess } from '../lib/store/global/action';
import { connect } from 'react-redux'
import { getUserUsingToken } from '../lib/apis/auth';
import * as languageActions from '../lib/store/intl/actions';
import { logout } from '../lib/apis/auth';
import queryString from 'query-string';
import { constantData, rpp,IMAGE_URL } from '../lib/config/constant';
import { getUnreadCountNotification, markReadNotificationCount, markReadNotification, getAllNotifications } from '../lib/apis/notification'
// import socketIOClient from "socket.io-client";
import { socketConnect } from 'socket.io-react';
import * as constant from '../lib/config/constant'
import { clearNotification } from '../lib/store/notification/action';
import { GetusersAllAppointments, CheckPreviousAppointmentApi } from '../lib/apis/bookappointments';
import VideoChat from '../Pages/VideoChat';
import SweetAlert from 'react-bootstrap-sweetalert';
import { videoRefSet } from '../lib/store/video/actions';
import { changeDoctorViewProfile } from '../lib/store/doctor/action';

let socket: any = {};

class Header extends Component<any, any>{
   videoChatRef: any;
   constructor(props: any) {
      super(props);
      this.state = {
         param: cmsRequestParams.header_footer,
         content: '',
         // language:localStorage.getItem('language'),
         language: (localStorage.getItem('language') != undefined && localStorage.getItem('language') != '') ? localStorage.getItem('language') : 'en',
         isLoadingDisplay: false,
         isLoading: false,
         unReadCount: 0,
         activePage: 1,
         socket: '',
         dialing: false,
         refreshPage: false,
         mandatoryAlert: false,
         patientmandatoryAlert: false,
         AppointmentRoom: '',
         AppointmentId : null,
         appointment: '',
         joinPreviousAppointmentalert: false,
         isReceivedCall: false,
         isJoinCall: false,
         isCheckedPreviousApp: false,
         scrolled: false,
         thisLocation:'',
      }
      this.videoChatRef = React.createRef();
   }
   handleClickOutside = (evt: any) => {
      // ..handling code goes here...
      //////
   };

   scrollDetect() {
      var lastScroll = 0;

      window.onscroll = function () {
         let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value

         if (currentScroll > 0 && lastScroll <= currentScroll) {
            lastScroll = currentScroll;
            $('.download_app_section').css('height', 0);
            $('.download_app_section').css('opacity', 0);
            $('.section-container').css('display', 'none');
         } else {
            lastScroll = currentScroll;
            $('.download_app_section').css('height', 'auto');
            $('.download_app_section').css('opacity', 1);
            $('.section-container').css('display', 'block');
         }
      };
   }

   async callVideoScreen() {
      
      // let PartnerName = this.props.intl['titleData'].filter((prefix: any) => prefix.id === this.state.PartnerUser.title).map((prefixtitle: any, i: any) => { return (prefixtitle.name + ' ') }) + (this.props.language == 'ar'?this.state.PartnerUser.arabicName:this.state.PartnerUser.englishName);
      let PartnerName = this.videoChatRef.getNameAsPerLanguage(this.state.PartnerUser.title,this.state.PartnerUser.englishName,this.state.PartnerUser.arabicName)
      let Data:any = {
         PartnerUser: this.state.PartnerUser,
         PartnerName:PartnerName,
         IsDirectCall: false,
         isModalOpen: true,
         CurrentUser: this.props.currentUser,
         AppointmentRoom: this.state.AppointmentRoom,
         AppointmentId: this.state.AppointmentId,
         isJoinCall:this.state.isJoinCall,
         isReceivedCall:this.state.isReceivedCall,
         checkPreviousSession: false,
         AppointmentQueueId: 0
      }
      await this.videoChatRef.OpenDirectCall(Data)
   }

   componentDidMount() {
      ////
   //    $( window ).on('unload', function( event:any ) {
   //       console.log('window unload');
   //   });

     

      this.setState({
         scrolled:false,
      })
      if(this.props && this.props != undefined)
         this.setState({
            thisLocation:this.props.location.pathname
         }, () => {
         })
      //
      socket = this.props.socket;
      let _this = this;

      socket.on('disconnect', async function () {
         console.log('disconnected');
         
         // socket.reconnect();
         // _this.setState({refreshPage:true})
      });

      socket.on('connect', async function () {
         // _this.setState({refreshPage:false})
         console.log('connected');
      });
      socket.on('user_disconnect_state_refresh', function(data: any) {
         ////
         // console.log('user_disconnect_state_refresh');
         
         
         _this.getUnreadCountNotification();
      });
      $('.navbar-nav').on('click', function(){
         console.log('navbar-nav click');
         
      })
      $(document).click(function(){
         console.log('KKK $(document).click');
         console.log('KKK notification_dropdown class',$("#notification_dropdown").hasClass('show'));
         
         if($("#notification_dropdown").hasClass('show') == true){
            //////
            $('.notificationBellBtn').trigger('click');
         };
       });
      // const script = document.createElement("script");
      // script.src = "../../public/doctoray_custom.js";
      // script.async = true;
      // // script.onload = () => this.scriptLoaded();

      // document.body.appendChild(script);
      // this.props.dispatch(getUnreadCountNotification()).then((response: any) => {
      //    //////
      //    this.setState({
      //       unReadCount:response.data.data.unread
      //    })
      // })
      // .catch((error:any, statusCode:any, message:any, e:any) => {
      // })
      $('.download_close-icon').click(function () {
         if ($('.doc-header').hasClass('sticky')) {
            $('.download_app_section').css('height', 0);
            $('.download_app_section').css('opacity', 0);
            $('.section-container').css('display', 'none');
            let nav_height = $('#doc-navbar').height();
            $('#body').css('padding-top', nav_height + 'px');
            $('.download_app_section').remove();
         }
         else {
            $('.download_app_section').css('height', 0);
            $('.download_app_section').css('opacity', 0);
            $('.section-container').css('display', 'none');
            $('.download_app_section').remove();
         }
      });
      this.scrollDetect();
      window.scrollTo(0, 0);
      if (this.props.location.search != '' && (this.props.location.search == '?mobile=true&lang=en') || (this.props.location.search == '?mobile=true&lang=ar')) {
         var header = document.getElementById('header')
         var footer = document.getElementById('footer')
         // var loading:any = document.getElementById('loading')
         // $(document).find(loading).css('display','none')
         if (header != null) {
            header.classList.add('d-none');
         }
         if (footer != null) {
            footer.classList.add('d-none');
         }
         let search = window.location.search;
         let params = new URLSearchParams(search);
         let lang = params.get('lang');
         this.handleLanguageChange(lang)
      } else {
         this.props.dispatch(language(this.state.language));
      }
      this.props.dispatch(cmsPageRender(this.state.param)).then((response: any) => {
      })
         .catch((error: any, statusCode: any, message: any, e: any) => {
         })
      //////
      // if(this.props.currentUser != un)
      if (this.props.isAuthenticated != true && localStorage.getItem('access_token')) {
         /**
          * check user alredy logged-in or not
          */
         this.props.dispatch(getUserUsingToken({})).then((response: any) => {
            // this.setState({isLoadingDisplay:false})
         })
            .catch((error: any, statusCode: any, message: any, e: any) => {

            })
      }
      this.viewAllAction();
      var widthSize = window.innerWidth;
      if (widthSize < 992) {
         //////
         $('.navbar-nav-link').on('click', function () {
            console.log('navbar-nav-link click');
            
            $('.jb_front_nav_close').trigger('click');
         });
         $(document).on('click','.navbar-quick-links',function(){
            $('.jb_front_nav_close').trigger('click');
            console.log('navbar-quick-links click');
         })
      }
      this.socketConnect();
   }
   // componentWillUnmount() {
   //    console.log('componentWillUnmount hesde');
      
   //    socket.emit('componentWillUnmount',{});
   //  }
   viewAllAction = () => {
      $('.notification_dropdown_items').removeClass('show');
      $('.notificationBellBtn').addClass('collapsed');
   }
   socketConnect = () => {
      /**
      * socket start
      */
      //   let socket = socketIOClient(constant.API_URL);
      //   socket.on("ditConsumer", function (data:any) {
      //       //////

      //   });
      //   socket.emit("initial_data");
      //   socket.emit("message",{value:'test'});

      /**
       * socket end
       */
   }

   markAsReadCount = () => {
         this.props.dispatch(clearNotification());
      this.setState({
         isLoading: true,
         activePage:1,
      })
      if (this.state.unReadCount != 0) {
         this.props.dispatch(markReadNotificationCount()).then((response: any) => {
            if (response.data.status == 200) {
               this.setState({
                  unReadCount: 0,
               })
            }
         })
            .catch((error: any, statusCode: any, message: any, e: any) => {
               //////
            })
      }
      //////
      if ($('.notificationBellBtn').attr('aria-expanded') == "true"){    
         this.props.dispatch(clearNotification());
         let data = {
            page: 1,
            pagelimit: rpp,
         }
         this.props.dispatch(getAllNotifications(data)).then((response: any) => {
            this.setState({
               isLoading: false,
               totalPage: response.data.data.totalPages,
            }, () => {
               if (this.state.totalPage == 0) {

               }
            })
         })
            .catch((error: any) => {

            })
      }
   }

   async getUnreadCountNotification() {
      let self = this;
      this.props.dispatch(getUnreadCountNotification()).then((response: any) => {
         ////
         this.setState({
            unReadCount: response.data.data.unread
         })
         let newCurrentUser: any = { ...this.props.currentUser };

         newCurrentUser.total_wallet_balance = response.data.data.total_wallet_balance;
         this.props.dispatch(authActions.currentUserChange(newCurrentUser));

         let newDoctorViewProfileData: any = { ...this.props.doctorViewProfileData };
         if(newDoctorViewProfileData){
            ////
            newDoctorViewProfileData.result.total_wallet_balance = response.data.data.total_wallet_balance
            this.props.dispatch(changeDoctorViewProfile(newDoctorViewProfileData));
         }

         setTimeout(() => {
            this.forceUpdate();
         }, 500);
      })
         .catch((error: any, statusCode: any, message: any, e: any) => {
         })
   }

   async componentDidUpdate(prevProps: any) {
      
      if(prevProps.videoRefSetSuccess != this.props.videoRefSetSuccess){
         this.videoChatRef = this.props.videoRefSetSuccess;
      }

      if (prevProps.isLocalStorageSetSuccess !== this.props.isLocalStorageSetSuccess && this.props.isLocalStorageSetSuccess == true) {
         this.getUnreadCountNotification();

         //
         //
         //
         //
         //
         
         if (!this.state.isCheckedPreviousApp && this.props.isAuthenticated && this.props.currentUser && this.props.currentUser.is_profile_filled === 1 && this.props.currentUser.is_active === 1) {
            //
            
            this.CheckPreviousAppointment();
         }
         
      } else if (this.props.location.pathname !== prevProps.location.pathname && this.props.isLocalStorageSetSuccess == true) {
         this.getUnreadCountNotification();
         if (!this.state.isCheckedPreviousApp && this.props.isAuthenticated && this.props.currentUser && this.props.currentUser.is_profile_filled === 1 && this.props.currentUser.is_active === 1) {
            this.CheckPreviousAppointment();
         }
      }
      
      if (this.props.location.pathname !== prevProps.location.pathname) {
         this.setState({
            thisLocation:this.props.location.pathname
         }, () => {
         })
         window.scrollTo(0, 0);
         var width = window.innerWidth;
         if (width >= 767) {
            const pathname = this.props.location.pathname;
            if (pathname == '/' || pathname == '/verify' || pathname == '/patient-verify' || pathname == '/doctor-verify' || pathname == '/patient_payment_success' || pathname == '/patient_payment_error') {
               // //////
               document.body.style.paddingTop = '0px';
            } else {
               // //////
               if (pathname == '/login' || pathname == '/forgot') {
                  document.body.style.paddingTop = '0px';
               } else {
                  //
                  // document.body.style.paddingTop = '84px';
                  // document.body.style.backgroundColor = 'red';
               }
            }
         } else {
         }

         /**
          * get notification count
          */

      } else {
         //  window.scrollTo(0, 0);
       
      }

      if (prevProps.cmsContent == undefined && this.props.cmsContent != undefined && this.props.cmsContent != prevProps.cmsContent) {
         const content = this.props.cmsContent.result.contents
         this.setState({
            content: JSON.parse(content),
         })
         document.getElementById('loading')?.classList.add('d-none');
      }

      if ((prevProps.isAuthenticated != this.props.isAuthenticated)) {
         // let socket = socketIOClient(constant.API_URL);
         await this.setState({ socket: socket });

         const _this = this;
         socket.on("user_dial_state", function (data: any) {
            if (data && data.user && data.user.id != _this.props.currentUser.id) {
            //    _this.setState({ joinPreviousAppointmentalert: false, appointmentreceivemessage: '' });
            // _this.setState({ Appointmentroom: null, AppointmentId: null,  PartnerUser: null, isReceivedCall: false, isJoinCall: false })
               _this.setState({ dialing: true, Appointmentroom: data.room, AppointmentId: data.appointment_id, PartnerUser: data.user, isReceivedCall: true,isJoinCall: false }, () =>{
                  _this.callVideoScreen();
               });
            }
         });

         socket.on("approve_or_reject_appt", function (data: any) {
            //////
            if (data && data.user && data.user.id != _this.props.currentUser.id) {
               // let username = _this.props.language == 'en' ? data.user.englishName : data.user.arabicName;
               // let username = _this.props.language == 'ar' ? data.user.arabicName : data.user.englishName;
               let username = _this.videoChatRef.getNameAsPerLanguage(data.user.title,data.user.englishName,data.user.arabicName)
               _this.setState({ patientmandatoryAlert: true, appointmentreceivemessage: username + ' ' + (data.status == 1 ? _this.props.intl["approve"] : _this.props.intl["reject"]) + _this.props.intl["your_appointment_request"] });
            }
         });

         socket.on("appt_request_doctor", function (data: any) {
            //////
            if (data.user) {
               // let username = _this.props.language == 'en' ? data.user.englishName : data.user.arabicName;
               // let username = _this.props.language == 'ar' ? data.user.arabicName : data.user.englishName;
               let username = _this.videoChatRef.getNameAsPerLanguage(data.user.title,data.user.englishName,data.user.arabicName)
               _this.setState({ mandatoryAlert: true, appointmentreceivemessage: username + _this.props.intl["sent_you_appointment_request"] });
            }
         });

         socket.on("user_check_call_Status", function (data: any) {
            console.log('KKK data: Header',data);
            
            if (data && data.user && data.user.id != _this.props.currentUser.id) {
               if (data.VideoSession && data.room == _this.state.AppointmentRoom) {
                  if (!_this.state.isJoinCall) {
                     _this.setState({ joinPreviousAppointmentalert: true, appointmentreceivemessage: _this.props.intl['joinAppointment'] });
                     _this.setState({ Appointmentroom: data.room, AppointmentId: data.appointment_id,  PartnerUser: data.user, isReceivedCall: false, isJoinCall: true })
                  }
               }
            }
         });

         socket.on('user_unavailable', function(data: any) {
            if(_this.state.VideoSession == null){
               _this.setState({ joinPreviousAppointmentalert: false, appointmentreceivemessage: '' });
               _this.setState({ Appointmentroom: '', AppointmentId: null, PartnerUser: null, isReceivedCall: false, isJoinCall: false });
            }
          });
          socket.on('user_receive_state', function(data: any) {
            _this.setState({ joinPreviousAppointmentalert: false, appointmentreceivemessage: '' });
         });
         socket.on('user_dial_state', function(data: any) {
            _this.setState({ joinPreviousAppointmentalert: false, appointmentreceivemessage: '' });
         });

         socket.on('user_disconnect_state', function(data: any) {
            _this.setState({ 
               joinPreviousAppointmentalert: false, 
               appointmentreceivemessage: '',
               AppointmentRoom: '',
               AppointmentId : null,
               appointment: '',
             });
         });

         if (this.props.isAuthenticated == true) {
            // this.GetAllusersAllAppointments();
            if (this.props.currentUser) {
               socket.emit("joinuserroom", {
                  user_id: this.props.currentUser.id,
                  event: 'user_status',
               });
               
               // if (this.props.currentUser.type == 3) {
                  /**
                   * joinroom event
                   */
                  socket.emit("joinroom", {
                     user_id: this.props.currentUser.id,
                     event: 'doctore_status',
                  });

                  /**
                   * login event
                   */
                  socket.emit("login", {
                     user_id: this.props.currentUser.id,
                     deviceType: 3,
                     deviceId: 'web',
                     deviceName: 'web',
                  });

                  socket.emit('doctoronline', {
                     user: this.props.currentUser,
                     event: 'doctor_status',
                  });
                  socket.on('connect', async function () {
                     socket.emit("joinuserroom", {
                        user_id: _this.props.currentUser.id,
                        event: 'user_status',
                     });
                     /**
                      * joinroom event
                      */
                     socket.emit("joinroom", {
                        user_id: _this.props.currentUser.id,
                        event: 'doctore_status',
                     });

                     /**
                      * login event
                      */
                     socket.emit("login", {
                        user_id: _this.props.currentUser.id,
                        deviceType: 3,
                        deviceId: 'web',
                        deviceName: 'web',
                     });

                     socket.emit('doctoronline', {
                        user: _this.props.currentUser,
                        event: 'doctor_status',
                     });
                  });
               // }
            }
         } else if (this.props.isAuthenticated == false) {
            if (prevProps.currentUser != undefined) {
               if (prevProps.currentUser) {
                  socket.emit("leaveuserroom", {
                     user_id: this.props.currentUser.id,
                     event: 'user_status',
                  });
                  // if (prevProps.currentUser.type == 3) {

                     /**
                      * leave event
                      */
                     socket.emit("leaveroom", {
                        user_id: this.props.currentUser.id,
                        event: 'doctore_status',
                     });

                     /**
                      * logout event
                      */
                     socket.emit("logout", { user_id: prevProps.currentUser.id });
                  // }
               }
            }
         }
      }

      if (prevProps.isAuthenticated == true && (prevProps.isAuthenticated != this.props.isAuthenticated)) {
         this.props.history.push('/');
      }
      //////
      // if($('.notification_dropdown_items').hasClass('show') == true){
      //    //////
      //    this.markAsReadCount();
      // }
   }

   CheckPreviousSession(appointmentroom: any, appointmentId:any, partnerId: any) {
      
      const _this = this;
      socket.emit('checksessioncall', {
         room: appointmentroom,
         appointment_id: appointmentId,
         user: this.props.currentUser,
         Partneruserid: partnerId,
         VideoSession: false,
         event: 'check_state',
      });
   }

   JoinPreivousAppointment() {
      this.setState({ dialing: true, joinPreviousAppointmentalert: false });
      this.callVideoScreen();
      setTimeout(() => {
         // this.videoChatRef.JoinCall();
      }, 2000);
   }

   async disconectCall() {
      this.setState({ dialing: false, joinPreviousAppointmentalert: false });
      this.setState({ isJoinCall: false })
      let Data:any = {
         Partneruserid: this.state.PartnerUser.id,
         PartnerUser: this.state.PartnerUser,
         AppointmentRoom: this.state.AppointmentRoom,
         Appointment: this.state.appointment,
      }
      await this.videoChatRef.DisconnectCallFromHomePage(Data)
   }

   CheckPreviousAppointment() {
      let socket = this.state.socket;
      this.props
         .dispatch(CheckPreviousAppointmentApi())
         .then((response: any) => {
            //this.setState({isLoadingDisplay: false});
            //////

            if (response.status == 200) {
               if (response.data && response.data.startPreviousApp && response.data.doctorAppointment) {
                  let partnerId = 0;
                  if (this.props.currentUser && this.props.currentUser.type == 3) {
                     partnerId = response.data.doctorAppointment.patient_id;
                  } else {
                     partnerId = response.data.doctorAppointment.doctor_id;
                  }

                  if (partnerId != 0) {
                     this.setState({appointment: response.data.doctorAppointment, AppointmentRoom: response.data.doctorAppointment.appointment_room, isCheckedPreviousApp: true });
                     this.CheckPreviousSession(response.data.doctorAppointment.appointment_room, response.data.doctorAppointment.id, partnerId);
                  }
               }
            } else {
            }
         })
         .catch((error: any, statusCode: any, message: any, e: any) => {
            //////
            this.setState({ isLoadingDisplay: false });
            // this.setState({
            //    isAlertShow: true,
            //    responseMessage: error.error.message,
            // });
         });
   };

   markAsReadNotification = (event: any, id: any, key: any, isRead: any, jsonData: any = null) => {
      if (isRead == 0) {
         //  this.setState({isLoadingDisplay:true});
         let data = {
            id: id,
         }
         this.props.dispatch(markReadNotification(data)).then((reponse: any) => {
            $('.notification-' + id).removeClass('unread');
            //   this.setState({isLoadingDisplay:false});
         })
            .catch((error: any, statusCode: any, message: any, e: any) => {
               this.setState({ isLoadingDisplay: false });
            });
      }
      this.notificationUrl(key, jsonData)
      this.viewAllAction();

   }

   onScrollNotifications = (e: any) => {
      let element = e.target
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
         // do something at end of scroll
         //////
         this.setState({
            activePage: this.state.activePage + 1,
         }, () => {
            //////
            if (isNaN(this.state.totalPage) || this.state.activePage <= this.state.totalPage) {
               $('.loading_notification').addClass('d-block')
               //////
               let data = {
                  page: this.state.activePage,
                  pagelimit: rpp,
               }
               this.props.dispatch(getAllNotifications(data)).then((response: any) => {
                  $('.loading_notification').removeClass('d-block')
               })
                  .catch((error: any) => {

                  })
            }
         })
      }
   }

   notificationUrl = (key: any, jsonData: any = null) => {
      let data = (jsonData != null) ? JSON.parse(jsonData) : null;
      let url = '';
      switch (key) {
         case "Admin_send_notification":
            url = '#';
            break;
        case "Admin_approved_consultant":
            url = 'doctor-profile';
            break;
        case "Admin_rejected_consultant":
            url = 'doctor-profile-form';
            break;
        case "doctor_come_online":
            let userSlug = (data.slug && data.slug != undefined && data.slug != '')?data.slug:data.doctor_id
            url = 'view-doctor-profile/'+userSlug;
            break;
        case "book_appointment_patient":
            url = '/my-appointments/booked_slots';
            break;
        case "book_appointment_doctor":
            url = '/my-patients';
            break;
        case "book_appointment_doctor_wallet_credit":
            url = '/doctor-wallet-logs';
            break;
        case "book_appointment_doctor_wallet_credit":
            url = '/doctor-wallet-logs';
            break;
        case "cancel_appointment_doctor_wallet_debited":
            url = '/doctor-wallet-logs';
            break;
        case "patient_sent_appointment_request":
            url = '/doctor-wallet-logs';
            break;
        case 'doctor_available':
        case 'instantApointmentCall':
        case 'book_appointment_patient_wallet_deduction':
        case 'cancel_appointment_patient_wallet_credit':
            url = '#';
            break;
        default:
            url = '#';
            break;
      }
      this.props.history.push(url);
   }

   handleLanguageChange = (lang: any) => {
      this.props.dispatch(languageActions.updateLanguage(lang));
      if (lang == 'ar') {
         document.body.classList.add('text-right');
         document.body.dir = 'rtl';
      } else {
         document.body.classList.remove('text-right');
         document.body.dir = 'ltr';
      }
      localStorage.setItem('language', lang);
      this.setState({ language: lang });
      this.props.dispatch(language(lang));
   }

   logoutUser = (e: any) => {
      e.preventDefault();
      // //////

      this.props
         .dispatch(logout())
         .then((response: any) => {
            this.props.history.push('/');
            localStorage.removeItem('access_token');
            window.location.reload();
         })
         .catch((error: any, statusCode: any, message: any, e: any) => {
            //////
            this.props.history.push('/');
         });
   }

   viewProfileLink = () => {
      let redirect = this.props.currentUser.type == 2 ? '/patient-profile' : 'doctor-profile';
      this.props.history.push(redirect);
   }

   redirecttoMypatient() {
      this.setState({ mandatoryAlert: false });
      this.props.history.push('/my-patients');
   }

   render() {
      const { intl } = this.props;
      return (
         <div>
            <SweetAlert title={this.props.intl['refresh_page_title']}
               warning
               show={this.state.refreshPage}
               confirmBtnText={this.props.intl['refresh']}
               confirmBtnBsStyle="primary"
               onConfirm={() => {
                  window.location.reload()
               }}
               btnSize="sm"
            >
               {this.props.intl['refresh_page_message']}
            </SweetAlert>

            <SweetAlert title={this.props.intl['appointment']}
               warning
               show={this.state.mandatoryAlert}
               showCancel
               confirmBtnText={this.props.intl['redirect_to_appointment']}
               cancelBtnText={this.props.intl['later']}
               cancelBtnBsStyle="default"
               confirmBtnBsStyle="primary"
               onConfirm={() => {
                  this.redirecttoMypatient()
               }}
               btnSize="sm"
            >
               {this.state.appointmentreceivemessage}
            </SweetAlert>
            <SweetAlert title={this.props.intl['appointment']}
               warning
               show={this.state.joinPreviousAppointmentalert}
               showCancel
               confirmBtnText={this.props.intl['join_call']}
               cancelBtnText={this.props.intl['cancel']}
               cancelBtnBsStyle="default"
               confirmBtnBsStyle="primary"
               onConfirm={() => {
                  this.JoinPreivousAppointment()
               }}
               onCancel={() => {
                  this.disconectCall()
               }}
               btnSize="sm"
            >
               {this.state.appointmentreceivemessage}
            </SweetAlert>
            <SweetAlert title={this.props.intl['appointment']}
               warning
               show={this.state.patientmandatoryAlert}
               confirmBtnText={this.props.intl['okay']}
               confirmBtnBsStyle="primary"
               onConfirm={() => {
                  this.setState({ patientmandatoryAlert: false })
               }}
               btnSize="sm"
            >
               {this.state.appointmentreceivemessage}
            </SweetAlert>
            <header id="header" className="doc-header">
               <div className="download_app_section position-relative d-block d-lg-none">
                  <div className="container">
                     <div className="section-container py-2">

                     {/* iOS App Links:
                     Patient App: https://apps.apple.com/us/app/doctoray/id1535807265
                     Doctor App: https://apps.apple.com/us/app/doctoray-for-doctors/id1535781155

                     Android App Links: 
                     Patient App : https://play.google.com/store/apps/details?id=com.doctoray.patient
                     Doctor App: https://play.google.com/store/apps/details?id=com.doctoray.doctor */}

                        <a style={{ 'cursor': 'pointer' }} className="download_close-icon">
                           <i className="flaticon-close-1 mx-1"></i>
                        </a>
                        <div className="section-box text-center">
                           <div className="section-title">
                              <h5 className="text-white">
                                 {this.props.intl['download_mobile_app']}
                              </h5>
                           </div>
                           <div className="section-data">
                              <div className="d-flex align-items-center justify-content-center">
                                 <a style={{ 'cursor': 'pointer' }} className="d-inline-block">
                                    <img src={IMAGE_URL + 'static_images/logo-white.png'} />
                                 </a>
                                 
                                 <a target="_blank" href="https://play.google.com/store/apps/details?id=com.doctoray.doctor" style={{ 'cursor': 'pointer' }} className="font-semi-bold p-2 btn-white">{this.props.intl['download_now']}</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="navbar navbar-expand-lg navbar-light navbar-sticky" id="doc-navbar">
                  <div className="container">
                     <div className="navbar-brand">
                        <Link to="/" className="d-inline-block">
                           <img src={constantData.saticimages + "logo_header_trans.png"} id="header_logo_original" alt="logo" className="logo-m" />
                           <img src={IMAGE_URL + 'static_images/logo-white.png'} id="header_logo_white" alt="logo" className="d-none logo-white pt-2 p-0" />
                        </Link>
                     </div>
                     <div className="d-lg-none">
                        <button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbar-navigation" aria-expanded="false">
                           <img src={IMAGE_URL + 'static_images/Menu.png'} alt="logo" />
                        </button>
                     </div>
                     <div className="navbar-collapse collapse" id="navbar-navigation">
                        <div className="d-lg-none d-lg-block jb_front_nav_close">
                           <button type="button" className="navbar-toggler collapsed jb_front_nav_close" data-toggle="collapse" data-target="#navbar-navigation" aria-expanded="false">
                              <i className="flaticon-close-1"></i>
                           </button>
                        </div>

                        <ul className="center-menu d-inline-block mx-auto ml-auto non-tranparent nav navbar-nav ">
                        {this.props.isAuthenticated && this.props.currentUser.type == 2 ? (
                           <>
                              <li className={"nav-item "+(this.props.language == 'en' ? 'float-left' : 'float-right')}>
                                 <Link to="/search-doctor" className="navbar-nav-link jb_underline nav_post_job">{this.props.language == 'en' ? this.state.content.menu_title14_english : this.state.content.menu_title14_arabic}</Link></li>
                              <li className={"nav-item "+(this.props.language == 'en' ? 'float-left' : 'float-right')}>
                                 <Link to="/howitworks" className="navbar-nav-link jb_underline nav_post_job">{this.props.language == 'en' ? this.state.content.menu_title2_english : this.state.content.menu_title2_arabic}</Link></li>
                              <li className={"nav-item "+(this.props.language == 'en' ? 'float-left' : 'float-right')}>
                                 {/* <Link to="/photo-gallery" className="navbar-nav-link jb_underline nav_post_job">{this.props.language == 'en' ? this.state.content.menu_title3_english : this.state.content.menu_title3_arabic}</Link></li> */}
                                 <Link to="/about-us" className="navbar-nav-link jb_underline nav_post_job">{this.props.language  == 'en' ? this.state.content.menu_title5_english : this.state.content.menu_title5_arabic}</Link></li>
                              <li className={"nav-item "+(this.props.language == 'en' ? 'float-left' : 'float-right')}>
                                 <Link to="/contact-us" className="navbar-nav-link jb_underline nav_post_job">{this.props.language == 'en' ? this.state.content.menu_title4_english : this.state.content.menu_title4_arabic}</Link></li>
                           </> 
                        ) :
                         (
                           <>
                              <li className={"nav-item "+(this.props.language == 'en' ? 'float-left' : 'float-right')}>
                                 <Link to={this.props.isAuthenticated && this.props.currentUser && this.props.currentUser.type && this.props.currentUser.type === 3 ? "/doctor-profile" : "/"} className="navbar-nav-link jb_underline nav_post_job">{this.props.language == 'en' ? this.state.content.menu_title1_english : this.state.content.menu_title1_arabic}</Link></li>
                              <li className={"nav-item "+(this.props.language == 'en' ? 'float-left' : 'float-right')}>
                                 <Link to="/howitworks" className="navbar-nav-link jb_underline nav_post_job">{this.props.language == 'en' ? this.state.content.menu_title2_english : this.state.content.menu_title2_arabic}</Link></li>
                              <li className={"nav-item "+(this.props.language == 'en' ? 'float-left' : 'float-right')}>
                                 <Link to="/photo-gallery" className="navbar-nav-link jb_underline nav_post_job">{this.props.language == 'en' ? this.state.content.menu_title3_english : this.state.content.menu_title3_arabic}</Link></li>
                                 
                              <li className={"nav-item "+(this.props.language == 'en' ? 'float-left' : 'float-right')}>
                                 <Link to="/contact-us" className="navbar-nav-link jb_underline nav_post_job">{this.props.language == 'en' ? this.state.content.menu_title4_english : this.state.content.menu_title4_arabic}</Link></li>
                           </>
                        ) }

                           

                        </ul>
                        <div className="float-right">
                           <div className="main-menuright d-flex align-items-center">

                              {this.props.isAuthenticated == true ?
                                 <>
                                    <ul className="navbar-nav notification">
                                       <li className="nav-item dropdown" onClick={this.markAsReadCount}>
                                          <a role="button" className="dropdown-toggle notificationBellBtn jb_company_dd_lang position-relative collapsed"
                                             style={{
                                                cursor: 'pointer'
                                             }} data-toggle="collapse" data-target="#notification_dropdown">
                                             {this.state.unReadCount != 0 ? (<div className="nitification-count bg-color-marun text-white position-absolute">{this.state.unReadCount}</div>) : null}
                                          </a>
                                          <span className="tooltip-arrow navigation-tooltip"></span>
                                          <div className="dropdown-menu dropdown-menu-right jb_company_dm_lang notification_dropdown_items" id="notification_dropdown">
                                             <h3>{this.props.intl['notification']}</h3>
                                             {this.state.isLoading == false && this.props.notificationList.length != 0 ? (
                                                <div className="notification-body" onScroll={this.onScrollNotifications}>
                                                   {this.props.notificationList != undefined && ((this.props.notificationList.map((data: any, i: any) => {
                                                      return (
                                                         <a key={i} onClick={(event: any) => this.markAsReadNotification(event, data.id, data.meta_key, data.is_read, data.data)} className={"dropdown-item notification-" + (data.id) + " d-block " + (data.is_read == 0 ? 'unread' : '')}>
                                                            {/* <strong className="d-block">{data.title}</strong> */}
                                                            <p>{data.message}</p>
                                                         </a>
                                                      )
                                                   }))
                                                   )}
                                                </div>
                                             ) : (
                                                   <h3 className="notify-loading">{this.state.isLoading == true ? this.props.intl['loading'] : this.props.intl['no_record']}</h3>
                                                )}
                                             <h3 className="loading_notification d-none">{this.props.intl['loading']}</h3>
                                             {this.props.notificationList.length != 0 ? (
                                                <h3 className="notification-footer"><Link to="/notifications" className="text-link navbar-quick-links">{this.props.intl['view_all']}<i className="flaticon-next"></i></Link></h3>
                                             ) : null}
                                          </div>
                                       </li>
                                    </ul>
                                 </>
                                 : ''}

                              <ul className={"navbar-nav language " + (this.props.isAuthenticated == true ? (this.props.language == 'en' ? ' ml-3 ' : ' mr-3 ') : ' m-3 ')}>
                                 <li className="nav-item">
                                    {this.props.isAuthenticated && this.props.currentUser.type == 3 ? '' :
                                       <>
                                          {this.state.language == 'en' ?
                                             <a className="navbar-nav-link ar p-0" style={{ 'cursor': 'pointer' }} onClick={this.handleLanguageChange.bind(null, 'ar')}>
                                                عربي
                                    </a>
                                             :
                                             <a className="navbar-nav-link en p-0" style={{ 'cursor': 'pointer' }} onClick={this.handleLanguageChange.bind(null, 'en')}>
                                                english
                                    </a>
                                          }
                                       </>
                                    }
                                 </li>
                              </ul>
                              {this.props.isAuthenticated == true ?
                                 <>
                                    <div className="profile-dropdown d-flex justify-content-end align-items-center px-md-4 px-lg-4">
                                       <div className="profile-icon-menu " style={{ 'cursor': 'pointer' }}>
                                          <img src={(this.props.currentUser.profile_pic == '' || this.props.currentUser.profile_pic == null) ? constantData.profileImageUrl + "default" + this.props.currentUser.type + ".png" : constantData.profileImageUrl + this.props.currentUser.profile_pic} onClick={this.viewProfileLink}></img>
                                       </div>
                                       {/* <h6 className="text-blue m-0 pl-1 pr-1 pt-2">{this.props.language == 'en'?this.props.currentUser.englishName:this.props.currentUser.arabicName}</h6> */}
                                       <ul className="navbar-nav">
                                          <li className="nav-item dropdown">
                                             <Link to="#" className={"dropdown-toggle jb_company_dd_lang d-inline-block position-relative m-0 p-0 collapsed " + (this.props.language == 'en' ? 'ml-2' : 'mr-2')} role="button" data-toggle="dropdown"></Link>
                                             <span className="tooltip-arrow navigation-tooltip"></span>
                                             <div className="dropdown-menu dropdown-menu-right jb_company_dm_lang" id="abc">
                                                <div className="card-body border-bottom-1">
                                                   <div className="media align-items-lg-center d-flex d-lg-flex">
                                                      <div className="profile-icon-menu pr-2">
                                                         <img onClick={() => this.props.history.push('/doctor-profile')} className="profile-dropdown-img" src={(this.props.currentUser.profile_pic == '' || this.props.currentUser.profile_pic == null) ? constantData.profileImageUrl + "default" + this.props.currentUser.type + ".png" : constantData.profileImageUrl + this.props.currentUser.profile_pic} />
                                                      </div>
                                                      <div className="dropdown-content">
                                                         <a href="javascript:;" className="d-inline-block" style={{textTransform:'unset'}}>
                                                            <h5 className="text-black username font-bold mb-0">
                                                            {this.props.currentUser.type == 3 ?this.props.intl['titleData'][this.props.currentUser.title - 1].name:''}{this.props.language == 'en'?this.props.currentUser.englishName:this.props.currentUser.arabicName}
                                                            </h5>
                                                         </a>
                                                         <div className="flex-menu-left d-block d-md-flex d-lg-flex">
                                                            <Link to={this.props.currentUser.type == 2 ? '/patient-profile-form' : '/doctor-profile-form'} className="text-blue navbar-quick-links">{this.props.intl['edit_profile']}</Link>
                                                            <Link to="/settings" className="text-blue navbar-quick-links">{this.props.intl['account_settings']}</Link>
                                                            <a style={{cursor:"pointer"}} className="text-blue navbar-quick-links" onClick={this.logoutUser}>{intl['logout']}</a>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                {this.props.currentUser.is_profile_filled == 1 ? (
                                                   <Link to={this.props.currentUser.type == 2 ? '/patient-wallet-logs' : 'doctor-wallet-logs'} className="dropdown-item "><span>{this.props.intl['my_wallet']} </span><span className={(this.props.language == 'en' ? 'pull-right ' : 'pull-left ') + 'wallet-balance text-white bg-blue'}>{this.props.currentUser.total_wallet_balance + ' KD'} </span></Link>
                                                ) : null}
                                                {this.props.currentUser.is_profile_filled == 1 ? (
                                                   this.props.currentUser.type == 3 ? (
                                                      <a>
                                                         <Link to="/appointment-management" className="text-black dropdown-item">{this.props.intl['add_appointment']}</Link>
                                                         <Link to="/my-patients#instant_calls" className="text-black dropdown-item">{this.props.intl['my_instant_calls']}</Link>
                                                         <Link to="/my-patients#booked_slots" className="text-black dropdown-item">{this.props.intl['my_booked_slots']}</Link>
                                                         <Link to="/my-patients#past_appointments" className="text-black dropdown-item">{this.props.intl['my_past_appointments']}</Link>        
                                                      </a>
                                                   ) : null
                                                ):null}
                                                {this.props.currentUser.type == 2 ?
                                                (
                                                      <a>
                                                         <Link to="/my-appointments/instant_calls" className="text-black dropdown-item">{this.props.intl['my_instant_calls']}</Link>
                                                         <Link to="/my-appointments/booked_slots" className="text-black dropdown-item">{this.props.intl['my_booked_slots']}</Link>
                                                         <Link to="/my-appointments/past_appointments" className="text-black dropdown-item">{this.props.intl['my_past_appointments']}</Link>        
                                                      </a> 
                                                   ):null}
                                                {this.props.currentUser.is_profile_filled == 1 ? (
                                                   this.props.currentUser.type == 2 ? (                                     
                                                      <Link to='/ticket-summary' className="dropdown-item ">{this.props.intl['ticket_summary']}</Link>     
                                                   ):null)
                                                :null }                                    
                                                <Link to={this.props.currentUser.type == 2 ? '/patient-profile' : '/doctor-profile'} className="dropdown-item ">{this.props.intl['view_profile']}</Link>    
                                             </div>
                                          </li>
                                       </ul>
                                    </div>
                                 </>
                                 :
                                 // <Link to="/login" className="btn btn-sub blue-btn d-flex align-items-center position-relative font-semi-bold text-white profile-submit mr-3">Sign in
                                 //             <i className="flaticon-next position-absolute"></i></Link>
                                 // {{pathname: "/login", state :{ from : this.state.thisLocation}}}
                                 <Link to={{pathname: "/login", state :{ from : this.state.thisLocation}}} className={"doc-btn menu-login-btn position-relative font-semi-bold text-white" + (this.props.language == 'en' ? 'mr-3' : 'ml-3')}>{intl['sign_in']}
                                    <i className="flaticon-next position-absolute"></i></Link>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </header>
            {this.props.isAuthenticated?
               <VideoChat onRef={(ref: any) => (this.props.dispatch(videoRefSet(ref)))}/>
            :''}
            

            {/* {this.state.dialing ? */}
               {/* // <VideoChat onRef={(ref: any) => (this.videoChatRef = ref)}
               //    PartnerUser={this.state.PartnerUser}
               //    PartnerName={this.props.intl['titleData'].filter((prefix: any) => prefix.id === this.state.PartnerUser.title).map((prefixtitle: any, i: any) => { return (prefixtitle.name + ' ') }) + this.state.language == 'ar' ? this.state.PartnerUser.arabicName : this.state.PartnerUser.englishName}
               //    IsDirectCall={false}
               //    AppointmentRoom={this.state.Appointmentroom}
               //    isJoinCall={this.state.isJoinCall}
               //    isReceivedCall={this.state.isReceivedCall}
               //    CurrentUser={this.props.currentUser}
               // />
               // <VideoChat appointmentroom={this.state.Appointmentroom} isReceivedCall={true} partneruser={this.state.PartnerUser} />
               // : ''} */}
         </div>
      )
   }
}

const mapStateToProps = (state: any) => {
   //////
   
   return {
      cmsContent: state.cms.headerfooter,
      loading: state.cms.loading,
      language: state.global.setLanguage,
      currentUser: state.auth.currentUser,
      token: state.auth.token,
      isAuthenticated: state.auth.isAuthenticated,
      intl: state.intl.messages,
      notificationList: state.notificationList,
      isLocalStorageSetSuccess: state.auth.isLocalStorageSetSuccess,
      videoRefSetSuccess: state.video.videoRefSetSuccess,
      doctorViewProfileData: state.doctor.doctorViewProfile,
   }
}
export default socketConnect(connect(mapStateToProps)(withRouter(Header)));
