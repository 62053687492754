/* ============
 * Actions for the language module
 * ============
 *
 * The actions that are available on the
 * language module.
 */
import {Dispatch} from 'react';
import {RootState} from '../reducers';
import {
  LANGUAGE_INITIAL_TYPES,
  UPDATE_LANGUAGE,
  UPDATE_LANGUAGE_INITIAL_ACTION,
} from './action-types';

export function updateLanguage(language: string): LANGUAGE_INITIAL_TYPES {
  return {
    type: UPDATE_LANGUAGE,
    language,
  };
}

export function updateLanguageInitial(language: string) {
  return (
    dispatch: Dispatch<LANGUAGE_INITIAL_TYPES>,
    getState: () => RootState,
  ) => {
    dispatch(updateLanguage(language));
  };
}
